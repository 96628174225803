import { useState, ComponentProps, Dispatch, useEffect, useCallback, SetStateAction, useContext } from "react";
import { Checkbox, useFeature, H6, Body1, Card } from "@coherehealth/common";
import { AuthStatus, ClinicalService, Patient, GetOutofNetworkCheckResponse } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { useTheme, styled } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  HUMANA_OON_PERFORMING_PROVIDER_EXCEPTION_REASONS,
  HUMANA_OON_FACILITY_EXCEPTION_REASONS,
  OonExceptionReasonOptions,
  sameProviderCheckboxClick,
} from "util/serviceRequest";

import { FlexGridItem, ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { useServiceRequestFormRedirectDispatch } from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import {
  FormConfiguration,
  ServiceRequestFieldName,
} from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import OONStatusDisplay from "components/ServiceRequest/ServiceRequestForm/components/OONStatusDisplay";
import OonExceptionReason from "components/ServiceRequest/ServiceRequestForm/components/OonExceptionReason";
import {
  OrderingProviderSelectManual,
  PerformingProviderSelectManual,
} from "components/ServiceRequest/ServiceRequestForm/components/ProviderSelectManual";
import FacilitySelectManual from "components/ServiceRequest/ServiceRequestForm/components/FacilitySelectManual";
import { ScrubTinsContext } from "components/ScrubTinsContext";
import { generateValidationMap } from "./PreApprovedCareProvidersCardUtil";
import { useAuthorized } from "authorization";
import { SRFormConfigFieldWrapper } from "common/FormConfigUtils";

interface Props {
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  formConfiguration: FormConfiguration;
  patient?: Patient;
  attemptedSubmit: boolean;
  setCanBeSubmitted: (b: boolean) => void;
  authStatus: AuthStatus;
  workflowId?: string;
  oonCheckLoading?: boolean;
  providerOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  performingProviderPracticeOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  facilityOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  orderingProviderOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  careParticipantOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  oonCheckFieldsFilled?: boolean;
  patientHealthPlanName: string;
}

export default function PreApprovedCareProvidersCard({
  formContent,
  setFormContent,
  formConfiguration,
  patient,
  attemptedSubmit,
  setCanBeSubmitted,
  authStatus: currAuthStatus,
  workflowId,
  oonCheckLoading,
  providerOonCheckData,
  facilityOonCheckData,
  orderingProviderOonCheckData,
  careParticipantOonCheckData,
  oonCheckFieldsFilled,
  patientHealthPlanName,
  performingProviderPracticeOonCheckData,
}: Props) {
  const { spacing } = useTheme();
  const theme = useTheme();

  // Contexts
  const { scrubTinsEnabled: hideTinField } = useContext(ScrubTinsContext);
  //Feature flags
  const canSkipOon = useAuthorized("CAN_SKIP_OON");
  const skipManualNpiTin = useFeature("skipManualNpiTin");
  const canSkipManualNpiTin = useAuthorized("CAN_SKIP_MANUAL_NPI_TIN");

  //Local State
  const [clinicalService] = useState<ClinicalService | undefined>(formContent?.clinicalService);
  const singleService = clinicalService?.isSingleService;

  const canSkipValidationForNpiTin = skipManualNpiTin && patientHealthPlanName === "Geisinger" && canSkipManualNpiTin;

  const VALIDATION_MAP = generateValidationMap({
    formContent,
    formConfiguration,
    patientHealthPlanName,
    providerOonCheckData,
    performingProviderPracticeOonCheckData,
    facilityOonCheckData,
    orderingProviderOonCheckData,
    careParticipantOonCheckData,
    canSkipValidationForNpiTin,
    canSkipOon,
    hideTinField,
  });

  const { shouldWaitForRuleRunResult, dispatchRedirectRuleCheck } = useServiceRequestFormRedirectDispatch({
    workflowId,
    formContent,
    healthPlanName: patientHealthPlanName,
    patient,
    clinicalService,
  });

  const validateFields = () => {
    if (shouldWaitForRuleRunResult) {
      return false;
    }

    const formConfig = formConfiguration;
    if (formConfig) {
      const formConfigKeys = Object.keys(formConfig) as ServiceRequestFieldName[];
      for (const key of formConfigKeys) {
        if (!VALIDATION_MAP[key]) {
          return false;
        }
      }
      return true;
    }
    //should never happen, but if it does we should block the user from trying to submit
    return false;
  };

  const isValid = validateFields();

  const handleFormValidation = useCallback(
    (isFormValid: boolean) => {
      setCanBeSubmitted(isFormValid);
    },
    [setCanBeSubmitted]
  );

  useEffect(() => {
    handleFormValidation(isValid);
  }, [isValid, handleFormValidation]);

  const [sameProviders, setSameProviders] = useState(false);

  useEffect(() => {
    if (
      formContent.performingProvider?.manuallyCreated &&
      formContent.orderingProvider?.manuallyCreated &&
      formContent.orderingProvider.id === formContent.performingProvider.id
    ) {
      setSameProviders(true);
    }
  }, [formContent, setSameProviders]);

  // checks if the performing provider has been cleared out to clear out the peforming provider outOfNetworkExceptionReason content
  useEffect(() => {
    if (!formContent.performingProvider && !!formContent?.performingProviderSelectedAddress) {
      setFormContent({
        ...formContent,
        performingProviderSelectedTin: null,
        performingProviderSelectedAddress: null,
      });
    }
  }, [formContent, setFormContent]);

  // checks if the facility has been cleared out to clear out the facility outOfNetworkExceptionReason content
  useEffect(() => {
    if (!formContent.facility && !!formContent?.facilitySelectedAddress) {
      setFormContent({ ...formContent, facilitySelectedTin: null, facilitySelectedAddress: null });
    }
  });

  // checks if the ordering provider has been cleared out to clear out the ordering provider outOfNetworkExceptionReason content
  useEffect(() => {
    if (!formContent.orderingProvider && !!formContent?.orderingProviderSelectedAddress) {
      setFormContent({
        ...formContent,
        orderingProviderSelectedTin: null,
        orderingProviderSelectedAddress: null,
        orderingProviderOONExceptionRequired: false,
      });
    }
  });

  // if the performing provider is changed while the sameProviders checkbox is checked, uncheck the checkbox
  useEffect(() => {
    const providersDiffNPI = formContent.orderingProvider?.npi !== formContent.performingProvider?.npi;
    const providersDiffTIN = formContent.orderingProviderSelectedTin !== formContent.performingProviderSelectedTin;
    const providersDiffAddress =
      formContent.orderingProviderSelectedAddress !== formContent.performingProviderSelectedAddress;
    if (sameProviders && (providersDiffNPI || providersDiffTIN || providersDiffAddress)) {
      setSameProviders(false);
    }
  }, [
    formContent.orderingProviderSelectedTin,
    formContent.performingProviderSelectedTin,
    formContent.orderingProviderSelectedAddress,
    formContent.performingProviderSelectedAddress,
    sameProviders,
    formContent.orderingProvider?.npi,
    formContent.performingProvider?.npi,
  ]);

  return (
    <Card style={{ padding: theme.spacing(3), overflow: "visible" }}>
      <Grid container spacing={2}>
        <SRFormConfigFieldWrapper {...formConfiguration.orderingProvider}>
          <FlexGridItem item xs={12}>
            <H6>Ordering provider</H6>
          </FlexGridItem>
          <FlexGridItem container spacing={2}>
            <OrderingProviderSelectManual
              error={attemptedSubmit && !VALIDATION_MAP["orderingProvider"]}
              npiError={attemptedSubmit && VALIDATION_MAP["orderingProvider"] && !VALIDATION_MAP["orderingProviderNPI"]}
              tinError={attemptedSubmit && !VALIDATION_MAP["orderingProviderTIN"]}
              addressError={attemptedSubmit && !VALIDATION_MAP["orderingProviderAddress"]}
              formContent={formContent}
              setFormContent={setFormContent}
              clinicalService={clinicalService || undefined}
              patient={patient || undefined}
              dispatchRedirectRuleCheck={dispatchRedirectRuleCheck("ordering provider")}
              sameProviders={sameProviders}
              setSameProviders={setSameProviders}
              showTinAndAddressField={
                patientHealthPlanName === "Geisinger"
                  ? formConfiguration.orderingProviderAddress.fieldSpec !== "OMIT"
                  : formConfiguration.orderingProviderAddress.fieldSpec === "REQUIRED"
              }
              formConfiguration={formConfiguration}
            />
            <SRFormConfigFieldWrapper {...formConfiguration.performingProvider}>
              <FlexGridItem item xs={12}>
                <SubHeading style={{ marginTop: spacing(0.5) }} data-public>
                  {"Performing or attending provider" +
                    (formConfiguration?.performingProvider.fieldSpec === "OPTIONAL" ? " (optional)" : "")}
                </SubHeading>
              </FlexGridItem>
              <FlexGridItem item xs={12} style={{ paddingTop: 0 }}>
                <Checkbox
                  style={{ marginTop: spacing(-1), marginBottom: spacing(-2) }}
                  checked={sameProviders}
                  onChange={(checked) => {
                    sameProviderCheckboxClick(setFormContent, setSameProviders, checked);
                  }}
                  label={<Body1>Performing is the same as the ordering</Body1>}
                />
              </FlexGridItem>
            </SRFormConfigFieldWrapper>
          </FlexGridItem>
        </SRFormConfigFieldWrapper>
        <SRFormConfigFieldWrapper {...formConfiguration.performingProvider}>
          <FlexGridItem container spacing={2}>
            <PerformingProviderSelectManual
              providerError={attemptedSubmit && !VALIDATION_MAP["performingProvider"]}
              npiError={
                attemptedSubmit && VALIDATION_MAP["performingProvider"] && !VALIDATION_MAP["performingProviderNPI"]
              }
              tinError={attemptedSubmit && !VALIDATION_MAP["performingProviderTIN"]}
              addressError={attemptedSubmit && !VALIDATION_MAP["performingProviderAddress"]}
              formContent={formContent}
              setFormContent={setFormContent}
              sameProviders={sameProviders}
              patient={patient || undefined}
              setSameProviders={setSameProviders}
              authStatus={currAuthStatus}
              isOptional={formConfiguration.performingProvider.fieldSpec === "OPTIONAL"}
              showAddressField={formConfiguration.performingProviderAddress.fieldSpec !== "OMIT"}
              formConfiguration={formConfiguration}
            />
          </FlexGridItem>
        </SRFormConfigFieldWrapper>
        <SRFormConfigFieldWrapper {...formConfiguration.facility}>
          <FlexGridItem container spacing={2}>
            <Grid item xs={12}>
              <SubHeading
                style={{
                  marginTop: spacing(-0.5),
                  marginBottom: spacing(-0.5),
                }}
                data-public
              >
                Performing facility or agency
              </SubHeading>
            </Grid>
            <FacilitySelectManual
              facilityError={attemptedSubmit && !VALIDATION_MAP["facilityNPI"]}
              tinError={attemptedSubmit && !VALIDATION_MAP["facilityTIN"]}
              addressError={attemptedSubmit && !VALIDATION_MAP["facilityAddress"]}
              formContent={formContent}
              setFormContent={setFormContent}
              isSingleService={singleService}
              patient={patient || undefined}
              isOptional={formConfiguration.facility.fieldSpec === "OPTIONAL"}
              showAddressField={formConfiguration.facilityAddress.fieldSpec === "REQUIRED"}
              formConfiguration={formConfiguration}
            />
          </FlexGridItem>
        </SRFormConfigFieldWrapper>
        {(formConfiguration.facilityOutOfNetworkStatusDisplay.fieldSpec !== "OMIT" ||
          formConfiguration.orderingProviderOutOfNetworkStatusDisplay.fieldSpec !== "OMIT" ||
          formConfiguration.performingProviderOutOfNetworkStatusDisplay.fieldSpec !== "OMIT") && (
          <OONStatusDisplay
            performingProviderOON={formContent.performingProviderSelectedAddress?.isOutOfNetwork || false}
            performingProviderPracticeOON={
              formContent.selectedPerformingProviderPracticeAddress?.isOutOfNetwork || false
            }
            facilityOON={formContent.facilitySelectedAddress?.isOutOfNetwork || false}
            orderingProviderOON={formContent.orderingProviderSelectedAddress?.isOutOfNetwork || false}
            oonLoading={oonCheckLoading || false}
            inNetwork={
              // "in network" if the OON checks have completed, and none are out of network
              (oonCheckFieldsFilled || false) &&
              !formContent.performingProviderSelectedAddress?.isOutOfNetwork &&
              !formContent.facilitySelectedAddress?.isOutOfNetwork &&
              !formContent.orderingProviderSelectedAddress?.isOutOfNetwork
            }
            healthPlanName={patientHealthPlanName}
            // We are phasing out "isOonExceptionRequired" in favor of just passing through the configs
            oonExceptionConfig={
              (providerOonCheckData?.isExceptionRequired && !canSkipOon) ||
              (performingProviderPracticeOonCheckData?.isExceptionRequired && !canSkipOon) ||
              (facilityOonCheckData?.isExceptionRequired && !canSkipOon) ||
              (orderingProviderOonCheckData?.isExceptionRequired && !canSkipOon) ||
              false
                ? "REQUIRED"
                : "OPTIONAL"
            }
          ></OONStatusDisplay>
        )}
        {formContent.performingProviderSelectedAddress?.isOutOfNetwork && (
          <SRFormConfigFieldWrapper {...formConfiguration.performingProviderOutOfNetworkExceptionReason}>
            <Row style={{ marginTop: spacing(1) }}>
              <OonExceptionReason
                exceptionPracticeCommon="Performing Provider"
                exceptionReasons={OonExceptionReasonOptions(HUMANA_OON_PERFORMING_PROVIDER_EXCEPTION_REASONS)}
                isExceptionRequired={
                  (providerOonCheckData?.isExceptionRequired && !canSkipOon) ||
                  (performingProviderPracticeOonCheckData?.isExceptionRequired && !canSkipOon) ||
                  (facilityOonCheckData?.isExceptionRequired && !canSkipOon) ||
                  false
                }
                data-tracking-id="performing-provider-exception-reason"
                error={attemptedSubmit && !VALIDATION_MAP["performingProviderOutOfNetworkExceptionReason"]}
                dropDownValue={formContent.performingProviderSelectedAddress?.outOfNetworkExceptionReason || ""}
                commentValue={formContent.performingProviderSelectedAddress?.outOfNetworkExceptionComment || ""}
                dropDownOnChange={(reason) =>
                  setFormContent({
                    ...formContent,
                    performingProviderSelectedAddress: {
                      ...formContent.performingProviderSelectedAddress,
                      outOfNetworkExceptionReason: reason,
                    },
                  })
                }
                commentOnChange={(comment) =>
                  setFormContent({
                    ...formContent,
                    performingProviderSelectedAddress: {
                      ...formContent.performingProviderSelectedAddress,
                      outOfNetworkExceptionComment: comment,
                    },
                  })
                }
              />
            </Row>
          </SRFormConfigFieldWrapper>
        )}
        {formContent.facilitySelectedAddress?.isOutOfNetwork && (
          <SRFormConfigFieldWrapper {...formConfiguration.facilityOutOfNetworkExceptionReason}>
            <Row style={{ marginTop: spacing(1) }}>
              <OonExceptionReason
                exceptionPracticeCommon="Facility"
                exceptionReasons={OonExceptionReasonOptions(HUMANA_OON_FACILITY_EXCEPTION_REASONS)}
                isExceptionRequired={(facilityOonCheckData?.isExceptionRequired && !canSkipOon) || false}
                data-tracking-id="facility-exception-reason"
                error={attemptedSubmit && !VALIDATION_MAP["facilityOutOfNetworkExceptionReason"]}
                dropDownValue={formContent.facilitySelectedAddress?.outOfNetworkExceptionReason || ""}
                commentValue={formContent.facilitySelectedAddress?.outOfNetworkExceptionComment || ""}
                dropDownOnChange={(reason) =>
                  setFormContent({
                    ...formContent,
                    facilitySelectedAddress: {
                      ...formContent.facilitySelectedAddress,
                      outOfNetworkExceptionReason: reason,
                    },
                  })
                }
                commentOnChange={(comment) =>
                  setFormContent({
                    ...formContent,
                    facilitySelectedAddress: {
                      ...formContent.facilitySelectedAddress,
                      outOfNetworkExceptionComment: comment,
                    },
                  })
                }
              />
            </Row>
          </SRFormConfigFieldWrapper>
        )}

        {formContent.orderingProviderSelectedAddress?.isOutOfNetwork && (
          <SRFormConfigFieldWrapper {...formConfiguration.orderingProviderOutOfNetworkExceptionReason}>
            <Row style={{ marginTop: spacing(1) }}>
              <OonExceptionReason
                exceptionPracticeCommon="Ordering Provider"
                exceptionReasons={OonExceptionReasonOptions(HUMANA_OON_PERFORMING_PROVIDER_EXCEPTION_REASONS)}
                isExceptionRequired={(orderingProviderOonCheckData?.isExceptionRequired && !canSkipOon) || false}
                data-tracking-id="ordering-provider-exception-reason"
                error={attemptedSubmit && !VALIDATION_MAP["orderingProviderOutOfNetworkExceptionReason"]}
                dropDownValue={formContent.orderingProviderSelectedAddress?.outOfNetworkExceptionReason || ""}
                commentValue={formContent.orderingProviderSelectedAddress?.outOfNetworkExceptionComment || ""}
                dropDownOnChange={(reason) =>
                  setFormContent({
                    ...formContent,
                    orderingProviderSelectedAddress: {
                      ...formContent.orderingProviderSelectedAddress,
                      outOfNetworkExceptionReason: reason,
                    },
                  })
                }
                commentOnChange={(comment) =>
                  setFormContent({
                    ...formContent,
                    orderingProviderSelectedAddress: {
                      ...formContent.orderingProviderSelectedAddress,
                      outOfNetworkExceptionComment: comment,
                    },
                  })
                }
              />
            </Row>
          </SRFormConfigFieldWrapper>
        )}
      </Grid>
    </Card>
  );
}

const Row = (props: ComponentProps<typeof Grid>) => <Grid item xs={12} {...props} />;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SubHeading = styled(H6)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));
