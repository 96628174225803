import { Container, useTheme } from "@material-ui/core";
import Footer from "components/AuthBuilder/Footer";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useMuiContainerStyles } from "@coherehealth/common";
import { ReferralSubmissionModal } from "./ReferralSubmissionModal";
import { ReferralRequestResponse, Requestor, useSubmitReferralRequest } from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { SubmissionStatus } from "components/ServiceRequest/Submission/SubmissionModal";
import { navigateToReferralsPatientSummary } from "../common";
import { useNavigate } from "react-router";

interface ReferralReviewFooterProps {
  setCancelModalOpen: (open: boolean) => void;
  setFooterHeight: Dispatch<SetStateAction<number>>;
  referralRequest: ReferralRequestResponse;
  requestor?: Requestor;
  attemptedSubmitServices: boolean;
  setAttemptedReferralRequestFormsSubmit: (b: boolean) => void;
}

export default function ReferralReviewFooter({
  referralRequest,
  requestor,
  setFooterHeight,
  attemptedSubmitServices,
  setAttemptedReferralRequestFormsSubmit,
  setCancelModalOpen,
}: ReferralReviewFooterProps) {
  const [submitError, setSubmitError] = useState(false);
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  const containerClasses = useMuiContainerStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  let submissionStatus: SubmissionStatus;
  if (submitError) {
    submissionStatus = SubmissionStatus.Failure;
  } else if (attemptedSubmitServices) {
    submissionStatus = SubmissionStatus.Loading;
  } else {
    submissionStatus = SubmissionStatus.Success;
  }

  const { mutate: submitReferralRequest, loading: submitReferralRequestLoading } = useSubmitReferralRequest({});

  const referralRequestSubmission = async () => {
    let submissionError = false;
    try {
      await submitReferralRequest({ ...referralRequest, requestor: requestor });
    } catch (e) {
      submissionError = true;
    }
    setSubmitError(submissionError);
  };

  /*  followed this pattern from auth submission; this function will be used to deal with a "save and exit" flow when implemented in the future */
  const onSubmitReferral = async () => {
    await referralRequestSubmission();
    setAttemptedReferralRequestFormsSubmit(true);

    setShowSubmissionModal(true);
    setAttemptedReferralRequestFormsSubmit(false);
  };

  const navigateUponCompletion = () => {
    navigateToReferralsPatientSummary(referralRequest.patient?.id || "", navigate, referralRequest.id);
  };

  useEffect(() => {
    if (submitError) {
      enqueueSnackbar("Failed to submit service requests", { variant: "error" });
    }
  }, [submitError, enqueueSnackbar]);

  return (
    <Container classes={containerClasses} maxWidth="lg" style={{ marginRight: theme.spacing(-3) }}>
      <Footer
        onPrimaryButtonClick={onSubmitReferral}
        primaryButtonText="Submit"
        primaryButtonDisabled={submitReferralRequestLoading}
        showSaveAndExitButton={false}
        setFooterHeight={setFooterHeight}
        showSecondaryButton={true}
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={() => {
          setCancelModalOpen(true);
        }}
      />
      <ReferralSubmissionModal
        open={showSubmissionModal}
        handleClose={() => setShowSubmissionModal(false)}
        onSuccessAction={navigateUponCompletion}
        onFailureAction={onSubmitReferral}
        status={submissionStatus}
      />
    </Container>
  );
}
