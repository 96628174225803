import React, { FunctionComponent } from "react";
import { FlexGridItem } from "common/SharedServiceRequestFormComponents";
import OutOfNetworkBanner from "components/ServiceRequest/ServiceRequestForm/components/OutOfNetworkBanner";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { Body2, colorsLight } from "@coherehealth/common";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CircularProgress } from "@material-ui/core";

interface Props {
  orderingProviderOON?: boolean;
  performingProviderOON?: boolean;
  performingProviderPracticeOON?: boolean;
  facilityOON?: boolean;
  oonLoading?: boolean;
  inNetwork?: boolean;
  healthPlanName: string;
  oonExceptionConfig: "REQUIRED" | "OPTIONAL" | "OMIT";
}

const OONStatusDisplay: FunctionComponent<Props> = ({
  oonLoading,
  inNetwork,
  orderingProviderOON,
  performingProviderOON,
  performingProviderPracticeOON,
  facilityOON,
  healthPlanName,
  oonExceptionConfig,
}) => {
  if (!oonLoading) {
    if (performingProviderOON || facilityOON || orderingProviderOON || performingProviderPracticeOON) {
      return (
        <FlexGridItem container>
          <OutOfNetworkBanner
            orderingProviderOON={orderingProviderOON || false}
            performingProviderOON={performingProviderOON || false}
            performingProviderPracticeOON={performingProviderPracticeOON || false}
            facilityOON={facilityOON || false}
            careParticipantOON={false}
            healthPlanName={healthPlanName}
            oonExceptionConfig={oonExceptionConfig}
          ></OutOfNetworkBanner>
        </FlexGridItem>
      );
    } else if (inNetwork) {
      return (
        <FlexGridItem>
          <OONStatusContainer>
            <IconContainer>
              <SuccessIcon />
            </IconContainer>
            <VerticallyCenteredContainer>
              <VerticallyCenteredItem>
                <OONStatusText>Network check complete</OONStatusText>
              </VerticallyCenteredItem>
            </VerticallyCenteredContainer>
          </OONStatusContainer>
        </FlexGridItem>
      );
    } else {
      return <></>;
    }
  } else {
    return (
      <FlexGridItem>
        <OONStatusContainer>
          <IconContainer>
            <CircularProgress size={24} />
          </IconContainer>
          <VerticallyCenteredContainer>
            <VerticallyCenteredItem>
              <OONStatusText>Running network check</OONStatusText>
            </VerticallyCenteredItem>
          </VerticallyCenteredContainer>
        </OONStatusContainer>
      </FlexGridItem>
    );
  }
};

export default OONStatusDisplay;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OONStatusContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(-1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const IconContainer = styled("div")(({ theme }) => ({
  justifyContent: "center",
  width: "24px",
  height: "24px",
  marginRight: "2px",
}));

// Centered vertically next to a 24px block
// eslint-disable-next-line cohere-react/no-mui-styled-import
const VerticallyCenteredContainer = styled("div")(({ theme }) => ({
  display: "table",
  height: "24px",
  overflow: "hidden",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const VerticallyCenteredItem = styled("div")(({ theme }) => ({
  display: "table-cell",
  verticalAlign: "middle",
  paddingLeft: "8px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OONStatusText = styled(Body2)(({ theme }) => ({
  fontFamily: "Gilroy-SemiBold",
  color: colorsLight.font.secondary,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SuccessIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  fontSize: "28px",
  marginTop: "-3px",
}));
