import {
  assertIsFile,
  Body1,
  Caption,
  colorsLight,
  fileSizeString,
  formatDateStrAsEastern,
  H6,
  InlineButton,
  isValidPdf,
  useStableUniqueId,
} from "@coherehealth/common";
import { AppealNoteFormAction } from "./AppealNote";
import { Box, Grid, IconButton, useTheme } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { error as logError } from "logger";
import { AddCircle, Delete } from "@material-ui/icons";
import {
  Attachment,
  useCreateServiceRequestAttachment,
  useDeleteServiceRequestAttachment,
} from "@coherehealth/core-platform-api";

const MAX_FILE_SIZE = 104857600;

interface AddAppealAttachmentProps {
  serviceRequestId: string;
  attachments: Attachment[];
  updateAppealNoteFormState: React.Dispatch<AppealNoteFormAction>;
  allowedFileExtensions?: string[];
}

export const AddAppealAttachment = ({
  serviceRequestId,
  attachments,
  updateAppealNoteFormState,
  allowedFileExtensions = ["pdf"],
}: AddAppealAttachmentProps) => {
  const inputId = useStableUniqueId();
  const theme = useTheme();
  const fileSizeLimit = MAX_FILE_SIZE;
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: uploadSRAttachment, loading: loadingSRAttachment } = useCreateServiceRequestAttachment({
    id: serviceRequestId,
    requestOptions: { headers: { Accept: "application/json" } },
  });

  const { mutate: deleteAttachment, loading: deleteAttachmentLoading } = useDeleteServiceRequestAttachment({
    id: serviceRequestId,
  });

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    if (input && input.files) {
      for (let i = 0; i < input.files.length; ++i) {
        let file = input.files[i];
        assertIsFile(file);
        const formData = new FormData();
        const fileSplit = file.name.split(".");
        if (!fileSplit.length) {
          continue;
        }
        const ext = fileSplit[fileSplit.length - 1];
        let trimmedFilename = fileSplit
          .filter((f) => f.length > 0 && !f.includes(ext))
          .map((f) => f.trim())
          .join("");

        if (!trimmedFilename.length) {
          trimmedFilename = `appeal_attachment_file_${serviceRequestId}`;
        }

        const fileName = `${trimmedFilename}.${ext}`;
        formData.set("file", file, fileName);
        file = new File([input.files[i]], fileName);

        if (file.size > fileSizeLimit) {
          enqueueSnackbar(
            `Unable to upload: ${file.name} is ${fileSizeString(file.size)}, but maximum allowed is ${fileSizeString(
              fileSizeLimit
            )}`,
            { variant: "warning" }
          );
        } else if (!allowedFileExtensions.some((e) => file.name.toLowerCase().endsWith(e))) {
          enqueueSnackbar(
            `Unable to upload ${
              file.name
            }: files must have one of the following extensions: ${allowedFileExtensions.join(", ")}`,
            { variant: "warning" }
          );
        } else if (!file.name) {
          enqueueSnackbar(`Unable to upload ${file.name}: files must have a name`, { variant: "warning" });
        } else if (file.name) {
          // make sure file name is not null
          // Assign our candidate a unique ID, so we can retrieve it later to update its status
          try {
            if (file.name.toLowerCase().endsWith(".pdf")) {
              // If file type is pdf, validate pdf
              try {
                await isValidPdf(file);
              } catch (error) {
                const errorMessage = `Invalid PDF structure for ${file.name}`;
                // This is content file issue that should not be sent to Sentry
                console.error(errorMessage);
                enqueueSnackbar(errorMessage, { variant: "error" });
                return;
              }
            }
          } catch (e) {
            logError(e);
            enqueueSnackbar(`Unable to upload file ${file.name}`, { variant: "error" });
          }

          try {
            const attachment = await uploadSRAttachment(formData as unknown as void, {
              pathParams: { id: serviceRequestId },
            });
            updateAppealNoteFormState({ type: "addFile", value: attachment });
            enqueueSnackbar(`Successfully uploaded ${file.name}`, { variant: "success" });
          } catch (error) {
            enqueueSnackbar(`Error while uploading the attachment`, { variant: "error" });
          }
        }
      }
    }
    // Clear out the input: otherwise, if the user selects the same file
    // a second time, we don't trigger an onChange event
    input.value = "";
  };

  const handleDeleteAttachment = async (attachment: Attachment) => {
    try {
      await deleteAttachment(attachment.id);
      updateAppealNoteFormState({ type: "removeFile", attachmentId: attachment.id });
      enqueueSnackbar(`Successfully deleted ${attachment.name}`, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(`Error while deleting the attachment`, { variant: "error" });
    }
  };

  return (
    <Box data-testid="add-appeal-attachment-container">
      <H6 style={{ marginBlock: "8px" }}>Files</H6>
      <Body1 style={{ color: colorsLight.font.secondary }}>
        Attach relevant documents to support the post-appeal documentation
      </Body1>
      <Grid container direction="column">
        {attachments.map((attachment) => (
          <Grid
            key={`appeal_temp_file_${attachment.id}`}
            container
            direction="row"
            alignItems="center"
            alignContent="space-between"
            style={{ marginTop: theme.spacing(3) }}
          >
            <Grid item xs={11}>
              <Body1>{attachment.name}</Body1>
              <Caption>{`Uploaded on ${formatDateStrAsEastern(attachment.dateCreated)} by ${
                attachment.createdByName
              }`}</Caption>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="Delete"
                disabled={deleteAttachmentLoading}
                onClick={() => handleDeleteAttachment(attachment)}
              >
                <Delete color={"action"} />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <input
        hidden
        accept={allowedFileExtensions
          .map((fileExt) => (fileExt.match(/^[^/.].+/g) ? ".".concat(fileExt) : fileExt))
          //add dot to filenames that do not have it
          //ex docx -> .docx
          .join(", ")}
        name={inputId}
        id={inputId}
        data-testid="appeal-upload-attachment"
        multiple={true}
        type="file"
        onChange={onFileChange}
      />
      <label htmlFor={inputId}>
        <InlineButton
          style={{ marginTop: theme.spacing(3) }}
          component="span"
          loading={loadingSRAttachment}
          disabled={loadingSRAttachment}
          startIcon={!loadingSRAttachment && <AddCircle />}
        >
          Upload files
        </InlineButton>
      </label>
    </Box>
  );
};
