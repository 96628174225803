import React, { FunctionComponent } from "react";
import { FlexGridItem } from "common/SharedServiceRequestFormComponents";
import OutOfNetworkBanner from "components/ServiceRequest/ServiceRequestForm/components/OutOfNetworkBanner";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { Body2 } from "@coherehealth/common";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CircularProgress } from "@material-ui/core";

interface Props {
  isCareParticipantOON: boolean;
  careParticipantType: string;
  oonExceptionConfig: "REQUIRED" | "OPTIONAL" | "OMIT";
  oonLoading?: boolean;
  inNetwork?: boolean;
  healthPlanName: string;
}

const OONCareParticipantStatusDisplay: FunctionComponent<Props> = ({
  oonLoading,
  inNetwork,
  isCareParticipantOON,
  careParticipantType,
  oonExceptionConfig,
  healthPlanName,
}) => {
  if (!oonLoading) {
    if (isCareParticipantOON) {
      return (
        <FlexGridItem container>
          <OutOfNetworkBanner
            performingProviderOON={false}
            performingProviderPracticeOON={false}
            facilityOON={false}
            careParticipantOON={isCareParticipantOON}
            careParticipantType={careParticipantType}
            healthPlanName={healthPlanName}
            orderingProviderOON={false}
            oonExceptionConfig={oonExceptionConfig}
          ></OutOfNetworkBanner>
        </FlexGridItem>
      );
    } else if (inNetwork) {
      return (
        <FlexGridItem>
          <OONStatusContainer>
            <IconContainer>
              <SuccessIcon />
            </IconContainer>
            <VerticallyCenteredContainer>
              <VerticallyCenteredItem>
                <OONStatusText>Network check complete</OONStatusText>
              </VerticallyCenteredItem>
            </VerticallyCenteredContainer>
          </OONStatusContainer>
        </FlexGridItem>
      );
    } else {
      return <></>;
    }
  } else {
    return (
      <FlexGridItem>
        <OONStatusContainer>
          <IconContainer>
            <CircularProgress size={24} />
          </IconContainer>
          <VerticallyCenteredContainer>
            <VerticallyCenteredItem>
              <OONStatusText>Running network check</OONStatusText>
            </VerticallyCenteredItem>
          </VerticallyCenteredContainer>
        </OONStatusContainer>
      </FlexGridItem>
    );
  }
};

export default OONCareParticipantStatusDisplay;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OONStatusContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(-1),
  marginTop: theme.spacing(-0.25),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const IconContainer = styled("div")(({ theme }) => ({
  justifyContent: "center",
  width: "24px",
  height: "24px",
  marginRight: "2px",
}));

// Centered vertically next to a 24px block
// eslint-disable-next-line cohere-react/no-mui-styled-import
const VerticallyCenteredContainer = styled("div")(({ theme }) => ({
  display: "table",
  height: "24px",
  overflow: "hidden",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const VerticallyCenteredItem = styled("div")(({ theme }) => ({
  display: "table-cell",
  verticalAlign: "middle",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OONStatusText = styled(Body2)(({ theme }) => ({
  fontFamily: "Gilroy-SemiBold",
  color: theme.palette.success.dark,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SuccessIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  fontSize: "22px",
}));
