import { useConfiguration } from "@coherehealth/common";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { useGetContinuationConfigurationByPayer } from "hooks/useGetFeatureConfigurations";

export const useClinicalReviewPageConfigurations = (serviceRequest: ServiceRequestResponse | null) => {
  const facilityBasedRequestConfiguration = useConfiguration(
    "facilityBasedRequestConfiguration",
    serviceRequest?.healthPlanName,
    serviceRequest?.delegatedVendor
  );
  const { data: continuationConfiguration } = useGetContinuationConfigurationByPayer(
    serviceRequest?.healthPlanName ?? ""
  );

  return {
    facilityBasedRequestConfiguration,
    continuationConfiguration,
  };
};
