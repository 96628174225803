import { ReferralRequestResponse, ReferralRequestWorflowStep, Requestor } from "@coherehealth/core-platform-api";
import { useCallback, useEffect, useState } from "react";
import { PathMatch, useLocation, useMatch, Location, useNavigate } from "react-router";
import routes from "routes";
import { useGetReferralRequestFormConfiguration } from "../FormContentSpecification/ReferralRequestFormContentSpecifications";
import ReferralRequestDetailsContainer from "./RequestDetails";
import { ReferralRequestFormContent, Row } from "./ReferralRequestForm";
import {
  defaultFormContent,
  rrFormContentFromResponse,
  useGetPatientWithReferralEligibility,
} from "util/referralRequest";
import ReferralHeader from "./ReferralHeader";
import { AppBar, Box, CircularProgress, Container, Grid, makeStyles, useTheme } from "@material-ui/core";
import { InformativeModal, useMuiContainerStyles } from "@coherehealth/common";
import ReferralRequestDetailsFooter from "./RequestDetails/Footer";
import { Helmet } from "react-helmet-async";
import { getCoverageBasedOnDate } from "util/patientUtils";
import { useSearchParams } from "react-router-dom";
import ReviewContainer from "./Review";
import ReferralReviewFooter from "./Review/ReferralReviewFooter";
import { useSnackbar } from "notistack";
import { useAuthorized } from "authorization";
import RequestorCard from "components/Requestor/RequestorCard";
import { useDeleteReferralRequest } from "@coherehealth/core-platform-api";
import ReferralCancelIcon from "components/images/ReferralCancelIcon";

export default function ReferralRequestPage() {
  const match = useMatch(routes.REFERRAL_BUILDER);
  const location = useLocation();
  return (
    <>
      <Helmet>
        <title>Cohere | New Referral</title>
      </Helmet>
      <ReferralBuilderContent match={match} location={location} />;
    </>
  );
}

interface Props {
  match: PathMatch<string> | null;
  location: Location;
}

const useFooterStyles = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    top: "auto",
    bottom: 0,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
}));

function ReferralBuilderContent({ match, location }: Props) {
  const theme = useTheme();
  const containerClasses = useMuiContainerStyles();
  const footerClasses = useFooterStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [footerHeight, setFooterHeight] = useState(0);
  const patientId = match?.params.patientId;
  const patientData = useGetPatientWithReferralEligibility(patientId || "");
  const isAdministrative =
    patientData?.referralEligibility?.delegated === true &&
    patientData?.referralEligibility?.referralRequired === false;
  const coverage = getCoverageBasedOnDate(new Date(), patientData ?? undefined);
  const healthPlanName = coverage?.healthPlanName;
  const formConfigBasedOnPatientCoverage = useGetReferralRequestFormConfiguration(patientData, isAdministrative);
  const [workflowStep, setWorkflowStepInternal] = useState<ReferralRequestWorflowStep>("REFERRAL_REQUEST_DETAILS");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const usePathWithReferralBuilderStep = (workflowStep: ReferralRequestWorflowStep) => {
    const path = useCallback(() => {
      const params = new URLSearchParams(searchParams);
      params.set("referralBuilderStep", workflowStep);
      const searchPart = params.toString();
      return `?${searchPart}`;
    }, [workflowStep]);
    return path();
  };
  const curStep = usePathWithReferralBuilderStep(workflowStep);
  const [rrFormsCanBeSubmitted, setRRFormsCanBeSubmitted] = useState<boolean>(false);
  const [rrFormContent, setRRFormContent] = useState<ReferralRequestFormContent>(defaultFormContent);
  const [referralRequest, setReferralRequest] = useState<ReferralRequestResponse>();
  const [attemptedReferralRequestFormsSubmit, setAttemptedReferralRequestFormsSubmit] = useState(false);
  const [hasPerformingSpecialistAttestation, setHasPerformingSpecialistAttestation] = useState(false);
  const [hasFacilityAttestation, setHasFacilityAttestation] = useState(false);
  const { mutate: deleteReferralRequest, error: deleteReferralError } = useDeleteReferralRequest({});
  const footerOffset = footerHeight + theme.spacing(60.25);

  const requestorFormAuthorized = useAuthorized("REQUESTOR_FORM");

  const [requestor, setRequestor] = useState<Requestor | undefined>(referralRequest?.requestor);
  const [userUpdatedTat] = useState<boolean>(false);

  const setWorkflowStep = useCallback((step: ReferralRequestWorflowStep) => {
    setWorkflowStepInternal(step);
  }, []);
  const addReferralRequest = (rr: ReferralRequestResponse, newDraftRRId = "") => {
    if (newDraftRRId) {
      setReferralRequest((prevReferral) => ({ ...prevReferral, ...rr, id: newDraftRRId }));
    }
  };

  const handleCancel = async () => {
    if (workflowStep === "REFERRAL_REQUEST_REVIEW") {
      await deleteReferralRequest(rrFormContent.id || "");
    }
    window.location.href = routes.REFERRAL_DASHBOARD;
  };

  /* 
    editReferralRequest functionality handles editions made to current request in the backward workflow
    Testable after implementing INTER-760
  */

  const editReferralRequest = (rr: ReferralRequestResponse) => {
    setReferralRequest((prevReferral) => {
      if (prevReferral?.id === rr.id) {
        return rr;
      }
      return prevReferral;
    });

    setRRFormContent((currContent) => {
      if (currContent.id === rr.id) {
        let request = rrFormContentFromResponse(rr);
        if (request.primarySemanticDiagnosisCode !== currContent.primarySemanticDiagnosisCode) {
          request = {
            ...request,
            primarySemanticDiagnosisCode: currContent.primarySemanticDiagnosisCode || null,
          };
        }
        if (
          (request.secondarySemanticDiagnosisCodes || [])?.length <
          (currContent.secondarySemanticDiagnosisCodes || [])?.length
        ) {
          request = {
            ...request,
            secondarySemanticDiagnosisCodes: currContent.secondarySemanticDiagnosisCodes || [],
          };
        }
        if (request.selectedFacility?.npi !== currContent.selectedFacility?.npi) {
          request = {
            ...request,
            selectedFacility: currContent.selectedFacility || null,
            // Todo: INTER-760 - Add OON check refresh logic here
          };
        } else {
          if (request.facilitySelectedTin !== currContent.facilitySelectedTin) {
            request = {
              ...request,
              facilitySelectedTin: currContent.facilitySelectedTin,
            };
          }
          if (request.facilitySelectedAddress !== currContent.facilitySelectedAddress) {
            request = {
              ...request,
              facilitySelectedAddress: currContent.facilitySelectedAddress,
            };
          }
        }
        if (request.selectedPerformingSpecialist?.npi !== currContent.selectedPerformingSpecialist?.npi) {
          request = {
            ...request,
            selectedPerformingSpecialist: currContent.selectedPerformingSpecialist || null,
            // Todo: INTER-760 - Add OON check refresh logic here
          };
        } else {
          if (request.performingSpecialistSelectedTin !== currContent.performingSpecialistSelectedTin) {
            request = {
              ...request,
              performingSpecialistSelectedTin: currContent.performingSpecialistSelectedTin,
            };
          }
          if (request.performingSpecialistSelectedAddress !== currContent.performingSpecialistSelectedAddress) {
            request = {
              ...request,
              performingSpecialistSelectedAddress: currContent.performingSpecialistSelectedAddress,
            };
          }
        }
        if (request.selectedReferringProvider?.npi !== currContent.selectedReferringProvider?.npi) {
          request = {
            ...request,
            selectedReferringProvider: currContent.selectedReferringProvider || null,
            // Todo: INTER-760 - Add OON check refresh logic here
          };
        } else {
          if (request.referringProviderSelectedTin !== currContent.referringProviderSelectedTin) {
            request = {
              ...request,
              referringProviderSelectedTin: currContent.referringProviderSelectedTin,
            };
          }
          if (request.referringProviderSelectedAddress !== currContent.referringProviderSelectedAddress) {
            request = {
              ...request,
              referringProviderSelectedAddress: currContent.referringProviderSelectedAddress,
            };
          }
        }
        if (request.specialty !== currContent.specialty) {
          request = {
            ...request,
            specialty: currContent.specialty,
          };
        }
        if (request.startDate !== currContent.startDate) {
          request = {
            ...request,
            startDate: currContent.startDate,
          };
        }
        return request;
      }
      return currContent;
    });
  };

  const navigateBack = async () => {
    if (workflowStep === "REFERRAL_REQUEST_REVIEW") {
      setWorkflowStep("REFERRAL_REQUEST_DETAILS");
      setAttemptedReferralRequestFormsSubmit(false);
    }
  };

  useEffect(() => {
    if (deleteReferralError) {
      enqueueSnackbar(`Error canceling referral request: ${deleteReferralError?.message || ""}`, { variant: "error" });
    }
  }, [enqueueSnackbar, deleteReferralError]);

  useEffect(() => {
    if (curStep) {
      navigate(curStep, { replace: true, state: location.state });
    }
  }, [curStep, location.state, navigate]);

  let pageContents: JSX.Element;
  let footerContents: JSX.Element;

  switch (workflowStep) {
    case "REFERRAL_REQUEST_DETAILS":
      pageContents = formConfigBasedOnPatientCoverage ? (
        <div style={{ marginBottom: footerOffset }}>
          <ReferralRequestDetailsContainer
            attemptSubmit={attemptedReferralRequestFormsSubmit}
            rrformContent={rrFormContent}
            setRRFormContent={setRRFormContent}
            rrFormConfiguration={formConfigBasedOnPatientCoverage}
            setReferralRequestCanBeSubmitted={setRRFormsCanBeSubmitted}
            patientData={patientData ?? undefined}
            coverage={coverage}
            isAdministrative={isAdministrative}
            hasPerformingSpecialistAttestation={hasPerformingSpecialistAttestation}
            setHasPerformingSpecialistAttestation={setHasPerformingSpecialistAttestation}
            hasFacilityAttestation={hasFacilityAttestation}
            setHasFacilityAttestation={setHasFacilityAttestation}
          />
        </div>
      ) : (
        <></>
      );
      footerContents = (
        <ReferralRequestDetailsFooter
          setCancelModalOpen={setCancelModalOpen}
          patientId={patientId || ""}
          healthPlanName={healthPlanName || ""}
          isAdministrative={isAdministrative}
          rrFormContent={rrFormContent}
          setRRFormContent={setRRFormContent}
          workflowStep={workflowStep}
          setWorkflowStep={setWorkflowStep}
          addReferralRequest={addReferralRequest}
          editReferralRequest={editReferralRequest}
          setAttemptedReferralRequestFormsSubmit={setAttemptedReferralRequestFormsSubmit}
          referralRequestFormCanBeSubmitted={rrFormsCanBeSubmitted}
          setFooterHeight={setFooterHeight}
          userUpdatedTat={userUpdatedTat}
          requestor={requestor}
        />
      );
      break;
    case "REFERRAL_REQUEST_REVIEW":
      if (!referralRequest) {
        enqueueSnackbar(`No referral request found`, { variant: "error", preventDuplicate: true });
        pageContents = <></>;
        footerContents = <></>;
      } else if (!formConfigBasedOnPatientCoverage) {
        enqueueSnackbar(`No referral request configuration found`, { variant: "error", preventDuplicate: true });
        pageContents = <></>;
        footerContents = <></>;
      } else {
        pageContents = <ReviewContainer referralRequest={referralRequest} isAdministrative={isAdministrative} />;
        footerContents = (
          <ReferralReviewFooter
            referralRequest={referralRequest}
            requestor={requestor}
            setFooterHeight={setFooterHeight}
            attemptedSubmitServices={attemptedReferralRequestFormsSubmit}
            setAttemptedReferralRequestFormsSubmit={setAttemptedReferralRequestFormsSubmit}
            setCancelModalOpen={setCancelModalOpen}
          />
        );
      }
      break;
    default:
      pageContents = <></>;
      footerContents = <></>;
  }

  return (
    <>
      <Container classes={containerClasses} maxWidth="lg">
        <Grid container>
          <Row style={{ marginBottom: !requestorFormAuthorized ? theme.spacing(2) : 0 }}>
            <ReferralHeader
              headerHeightWithNavButtons={theme.spacing(12)}
              workflowStep={workflowStep}
              navigateBack={navigateBack}
              patientId={patientId}
            />
          </Row>
          <Row>
            {requestorFormAuthorized && (
              <Box paddingTop={theme.spacing(2)} marginBottom={theme.spacing(-1.65)}>
                <RequestorCard requestor={requestor} setRequestor={setRequestor} isContinuationWorkflow={false} />
              </Box>
            )}
          </Row>
          <Row>
            {!patientData ? (
              <Box display="flex" justifyContent="center" width="100%" marginTop={theme.spacing(2)}>
                <CircularProgress size={40} />
              </Box>
            ) : (
              pageContents
            )}
          </Row>
          <Row>
            <AppBar classes={footerClasses} component="footer" elevation={0}>
              <Container classes={containerClasses}>
                <div>{footerContents}</div>
              </Container>
            </AppBar>
          </Row>
        </Grid>
      </Container>
      <InformativeModal
        open={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        icon={<ReferralCancelIcon />}
        headerText="Are you sure you want to cancel this referral?"
        subHeaderText="Your progress will not be saved."
        primaryButtonText="Yes, cancel"
        primaryButtonAction={handleCancel}
        tertiaryButtonText="No, return to referral"
        tertiaryButtonAction={() => setCancelModalOpen(false)}
      />
    </>
  );
}
