import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Divider, Grid, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { styled, useTheme } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import addDays from "date-fns/addDays";
import isAfter from "date-fns/isAfter";
import isEqual from "date-fns/isEqual";
import last from "lodash/last";

import {
  DateSelect,
  InlineButton,
  RadioGroup,
  RadioGroupOption,
  SingleSelectDropdown,
  TimeTextField,
  formatDateStr,
  parseDateFromISOStringWithoutFallback,
  parseDateFromISOString,
  plusDays,
  formatDateToISODate,
  Tooltip,
  colorsLight,
  MiddleTruncateLabelWithEndornment,
  H6,
  useConfiguration,
} from "@coherehealth/common";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import useSRFormRadioGroupHeaderStyles from "components/ServiceRequest/ServiceRequestForm/hooks/useSRFormRadioGroupHeaderStyles";
import {
  AdmissionSourceResponse,
  AuthorizationResponse,
  LevelOfCareResponse,
  PatientDischargeStatusResponse,
  PatientStatus,
  PatientStayDate,
  ServiceRequestResponse,
  useGetAdmissionSource,
  useGetPatientDischargeStatus,
  AuthStatus,
  Patient,
  AuthCategoryResponse,
  FacilityCategory,
} from "@coherehealth/core-platform-api";
import { H4, Body1 } from "@coherehealth/design-system";
import { ServiceRequestFormContent } from "components/ServiceRequest";
import PatientStayModal from "components/PatientStayModal";
import { PatientStayDateRange } from "common/SharedServiceRequestFormComponents";
import { useFeature } from "@coherehealth/common";
import {
  ContinuationConfiguration,
  FormConfiguration,
} from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import { RequestExpedited } from "components/ServiceRequest/ServiceRequestForm/Expedited";
import PatientStays from "components/ServiceRequest/PatientStay/PatientStays";
import { usePatientStayValidation } from "components/ServiceRequest/PatientStay/usePatientStayValidation";
import listRemove from "util/listRemove";
import { CoverageCheck } from "util/patientUtils";
import { isTerminalStatus } from "util/serviceRequest";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";
import { SRFormConfigFieldWrapper } from "common/FormConfigUtils";

const useStyles = makeStyles((theme) => ({
  radioGroupSmall: {
    "& .MuiFormGroup-root": {
      paddingLeft: "5px",
      paddingTop: "5px",
    },
    "& .MuiFormControlLabel-root": {
      marginRight: theme.spacing(3),
    },
    "& .MuiButtonBase-root": {
      padding: 5,
    },
  },
}));

const ViewPrevDaysButton = styled(InlineButton)(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
  padding: "0 0 2px 0",
}));
const AdmissionSourceDropdown = styled(SingleSelectDropdown)(({ theme }) => ({
  "& .cancelButton": {
    opacity: 0,
    transition: theme.transitions.create("opacity"),
  },
  "&:hover .cancelButton": {
    opacity: 1,
  },
  "& .MuiFilledInput-adornedEnd": {
    paddingRight: 0,
  },
}));

interface Props {
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  onUserEdit?: Dispatch<ServiceRequestFormContent>;
  healthPlanName: string;
  /**
   * If admission source data is not provided here it will be fetched via the /admissionSource API
   */
  admissionSources?: AdmissionSourceResponse[];
  /**
   * If levelsOfCare data is not provided here it will be fetched via the /levelOfCare?healthPlanName API
   */
  levelsOfCare?: LevelOfCareResponse[];
  /**
   * If patientDischargeStatuses data is not provided here it will be fetched via the /patientDischargeStatus API
   */
  patientDischargeStatuses?: PatientDischargeStatusResponse[];
  /**
   * Boolean to control continuation specific behavior
   **/
  isContinuation?: boolean;

  /**
   * Authorization data needed for continuations, undefined if initial
   */
  authorization?: AuthorizationResponse;

  /**
   * serviceRequest to help determine if date is invalid
   */
  serviceRequest?: Partial<ServiceRequestResponse>;
  attemptedSubmit?: boolean;
  timePattern?: RegExp;
  startDateCoverage?: CoverageCheck;
  onPatientSummary?: boolean;
  formConfiguration: FormConfiguration | ContinuationConfiguration;
  authStatus: AuthStatus;
  patient: Patient | null;
  dispatchUrgencyRuleCheck: (isExpedited: boolean) => Promise<void>;
  hideExpeditedRequestCheckbox?: boolean;
  shouldNotWaitForAuth?: boolean;
  disablePatientStatusChange?: boolean;
  isReview?: boolean;
  encounterType?: FacilityCategory;
}

export default function PatientStayCard({
  formContent: {
    patientStatus,
    expectedAdmissionDate,
    admissionDate,
    admissionTime,
    admissionSource,
    patientStayDateRanges,
    dischargeDate,
    dischargeTime,
    dischargedTo,
    isExpedited,
    id,
    startDate,
    clinicalService,
    procedureCodes,
    authCategory,
  },
  setFormContent,
  healthPlanName,
  admissionSources,
  levelsOfCare,
  patientDischargeStatuses,
  isContinuation,
  authorization,
  attemptedSubmit,
  timePattern,
  startDateCoverage,
  onPatientSummary,
  formConfiguration,
  authStatus: currAuthStatus,
  patient,
  dispatchUrgencyRuleCheck,
  hideExpeditedRequestCheckbox,
  shouldNotWaitForAuth,
  disablePatientStatusChange,
  isReview,
  encounterType,
}: Props) {
  const styleClasses = useStyles();
  const sideBySideInitialSrFaxIntakeWorkflowFeature = useFeature("sideBySideInitialSrFaxIntakeWorkflow");
  const { spacing } = useTheme();
  const [patientStayModalOpen, setPatientStayModalOpen] = useState<boolean>(false);
  const [admissionTimeFormatValidation, setAdmissionTimeFormatValidation] = useState("");
  const [dischargeTimeFormatValidation, setDischargeTimeFormatValidation] = useState("");
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  const { data: admissionSourceResponse } = useGetAdmissionSource({
    queryParams: {
      max: 20,
      isNewbornAdmission: false, // todo this should be based on admissionCategory value
    },
    mock: !!admissionSources
      ? {
          data: admissionSources,
        }
      : undefined,
  });
  const admissionSourceOptions = (admissionSourceResponse || []).map(({ code, name }) => ({
    id: code || "",
    label: name,
  }));

  const { data: patientDischargeStatusResponse } = useGetPatientDischargeStatus({
    queryParams: {
      max: 30,
    },
    mock: !!patientDischargeStatuses ? { data: patientDischargeStatuses } : undefined,
  });
  const dischargedToOptions = (patientDischargeStatusResponse || [])
    .map(({ code, dischargedTo }) => ({
      id: code || "",
      label: dischargedTo,
    }))
    .sort((a, b) => {
      if (a.label && b.label) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
      }
      return 0;
    });

  const decisionedServiceRequests = useMemo(
    () =>
      authorization?.serviceRequestsOnAuth?.filter((serviceRequest) => {
        return (
          isTerminalStatus(serviceRequest) &&
          serviceRequest.patientStayDates &&
          serviceRequest.patientStayDates.length > 0
        );
      }),
    [authorization]
  );
  const previousStayDatesSorted: PatientStayDate[] | undefined = useMemo(
    () =>
      decisionedServiceRequests
        ?.map((serviceRequest) => serviceRequest.patientStayDates)
        ?.filter((elem): elem is PatientStayDate[] => !!elem)
        ?.flat()
        ?.filter((elem): elem is PatientStayDate => elem.reviewStatus !== "VOID")
        ?.sort((a, b) => {
          if (typeof a?.date === "string" && typeof b?.date === "string") {
            return Date.parse(a.date) - Date.parse(b.date);
          }
          return 0;
        }),
    [decisionedServiceRequests]
  );

  const showPreviousDates =
    (isContinuation || onPatientSummary) && !!previousStayDatesSorted && previousStayDatesSorted.length > 0;

  const numPreviousDates = showPreviousDates ? previousStayDatesSorted?.length : "#";
  const dischargeCurrentDate = new Date();
  dischargeCurrentDate.setDate(dischargeCurrentDate.getDate() + 1);
  const admissionTimeValidation = admissionTime && timePattern?.test(admissionTime);
  const dischargeTimeValidation = dischargeTime && timePattern?.test(dischargeTime);

  const previouslyDecisionedDates = useMemo(
    () =>
      previousStayDatesSorted
        ?.map((e) => parseDateFromISOStringWithoutFallback(e.date))
        ?.filter((e?: Date): e is Date => !!e),
    [previousStayDatesSorted]
  );

  const facilityBasedConfig = useConfiguration("facilityBasedRequestConfiguration", healthPlanName); //how do I get delegated vendor here

  useEffect(() => {
    if (
      (shouldNotWaitForAuth === true || authorization !== null) &&
      admissionDate &&
      (patientStatus === "CURRENTLY_ADMITTED" || patientStatus === "DISCHARGED")
    ) {
      setFormContent((prev) => ({
        ...prev,
        patientStayDateRanges: getUpdatedStayDateRanges({
          admissionDate,
          dischargeDate,
          previouslyDecisionedDates,
          currentRequestedStayDateRanges: prev.patientStayDateRanges || [],
        }),
      }));
    }
  }, [
    admissionDate,
    dischargeDate,
    previouslyDecisionedDates,
    setFormContent,
    patientStatus,
    authorization,
    shouldNotWaitForAuth,
  ]);

  useEffect(() => {
    if (
      (shouldNotWaitForAuth === true || authorization !== null) &&
      expectedAdmissionDate &&
      patientStatus === "NOT_YET_ADMITTED" &&
      facilityBasedConfig?.includePatientStayDatesOnPlannedAdmission
    ) {
      setFormContent((prev) => ({
        ...prev,
        admissionDate: expectedAdmissionDate,
        patientStayDateRanges: getUpdatedStayDateRanges({
          admissionDate: expectedAdmissionDate,
          previouslyDecisionedDates,
          currentRequestedStayDateRanges: prev.patientStayDateRanges || [],
        }),
      }));
    }
  }, [
    dischargeDate,
    previouslyDecisionedDates,
    setFormContent,
    patientStatus,
    authorization,
    shouldNotWaitForAuth,
    expectedAdmissionDate,
    facilityBasedConfig?.includePatientStayDatesOnPlannedAdmission,
  ]);

  const addDaysToLastDecisionedDate = previouslyDecisionedDates?.length
    ? plusDays(1, last(previouslyDecisionedDates))
    : undefined;

  useEffect(() => {
    if (
      admissionDate &&
      patientStayDateRanges?.length === 0 &&
      ["CURRENTLY_ADMITTED"].includes(patientStatus || "") &&
      addDaysToLastDecisionedDate
    ) {
      setFormContent((prev) => ({
        ...prev,
        patientStayDateRanges: [createNewPatientStayDateRange(addDaysToLastDecisionedDate, null)],
      }));
    }
  }, [
    admissionDate,
    dischargeDate,
    setFormContent,
    patientStatus,
    startDate,
    patientStayDateRanges?.length,
    addDaysToLastDecisionedDate,
  ]);

  const { patientStaysErrorStates } = usePatientStayValidation({
    version: "RequestedStay",
    patientStayDateRanges: patientStayDateRanges || [],
    previouslyDecisionedDays: authorization?.patientStayDates?.filter(({ reviewStatus = "" }) =>
      ["APPROVED", "DENIED"].includes(reviewStatus)
    ),
    admissionDate: formatDateToISODate(admissionDate),
  });

  const { tooltipIcon: tooltipIconClass, ...labelClasses } = useSRFormRadioGroupHeaderStyles();
  const showResponsivePatientCard = sideBySideInitialSrFaxIntakeWorkflowFeature && isFaxAuthBuilderFlow;

  const responsiveDateStyle = showResponsivePatientCard
    ? { flex: "1 0 220px" }
    : { flexGrow: 0, maxWidth: "25%", flexBasis: "25%" };
  const responsiveDropdownStyle = showResponsivePatientCard
    ? { flexGrow: "4", minWidth: "220px", maxWidth: "700px" }
    : { flexGrow: 0, maxWidth: "50%", flexBasis: "50%" };

  const handlePatientStatusChange = (newPatientStatus: PatientStatus) => {
    if (newPatientStatus === "CURRENTLY_ADMITTED") {
      setFormContent((currentFormContent) => ({
        ...currentFormContent,
        admissionDate: currentFormContent.expectedAdmissionDate ?? currentFormContent.admissionDate,
        patientStayDateRanges: currentFormContent.patientStayDateRanges,
      }));
    } else if (isReview && newPatientStatus === "DISCHARGED") {
      setFormContent((currentFormContent) => ({
        ...currentFormContent,
        admissionDate: currentFormContent.expectedAdmissionDate ?? currentFormContent.admissionDate,
        patientStayDateRanges: currentFormContent.patientStayDateRanges,
      }));
    } else if (newPatientStatus === "NOT_YET_ADMITTED") {
      setFormContent((currentFormContent) => ({
        ...currentFormContent,
        patientStayDateRanges: [],
        expectedAdmissionDate: currentFormContent.admissionDate ?? currentFormContent.expectedAdmissionDate,
      }));
    }
    setFormContent((current) => ({ ...current, patientStatus: newPatientStatus }));
  };

  return (
    <Grid container data-testid="patient-stay-card" spacing={2}>
      <Grid item xs={12}>
        <RadioGroup<PatientStatus>
          disabled={disablePatientStatusChange ? false : isContinuation ? false : true}
          row
          options={getPatientStatusOptions(authCategory, disablePatientStatusChange)}
          value={patientStatus}
          onChange={handlePatientStatusChange}
          className={showResponsivePatientCard ? styleClasses.radioGroupSmall : undefined}
          labelClasses={labelClasses}
          label={
            <>
              {isReview ? <H6>Patient stay</H6> : <H4>Patient stay</H4>}
              {!(disablePatientStatusChange || isContinuation) && (
                <Tooltip
                  interactive
                  title={`Patient status cannot be edited on an initial request. If details are inaccurate, withdraw the request and re-submit.`}
                  style={{
                    marginTop: spacing(-1.5),
                    marginBottom: spacing(-2),
                    width: spacing(2.5),
                    height: spacing(2.5),
                    color: colorsLight.font.main,
                  }}
                  data-public
                >
                  <InfoOutlinedIcon className={tooltipIconClass} />
                </Tooltip>
              )}
            </>
          }
        />
      </Grid>
      {patientStatus === "NOT_YET_ADMITTED" && (
        <Grid item>
          <DateSelect
            TextFieldProps={{ fullWidth: true }}
            label="Expected admission date"
            value={expectedAdmissionDate || null}
            minDate={new Date()}
            attemptedSubmit={attemptedSubmit}
            minDateMessage={"Date must be today or later"}
            error={attemptedSubmit && patientStatus === "NOT_YET_ADMITTED" && !expectedAdmissionDate}
            helperText={attemptedSubmit && !expectedAdmissionDate ? "Required" : ""}
            onDateChange={(newExpectedAdmissionDate) => {
              setFormContent((current) => ({
                ...current,
                expectedAdmissionDate: newExpectedAdmissionDate,
                startDate: newExpectedAdmissionDate,
              }));
            }}
          />
        </Grid>
      )}
      {(patientStatus === "CURRENTLY_ADMITTED" || patientStatus === "DISCHARGED") && (
        <>
          <Grid item style={responsiveDateStyle}>
            <DateSelect
              attemptedSubmit={attemptedSubmit}
              TextFieldProps={{ fullWidth: true }}
              maxDate={new Date()}
              maxDateMessage={"Date must be today or earlier"}
              label="Admission date"
              value={admissionDate || null}
              error={
                ((patientStatus === "CURRENTLY_ADMITTED" || patientStatus === "DISCHARGED") &&
                  attemptedSubmit &&
                  !startDateCoverage?.inRange) ||
                (attemptedSubmit && !admissionDate)
              }
              showInternalErrorsOnBlur
              helperText={
                attemptedSubmit && !admissionDate
                  ? "Required"
                  : attemptedSubmit && startDateCoverage && !startDateCoverage?.inRange
                  ? `${startDateCoverage?.messageToDisplay}`
                  : ""
              }
              onDateChange={(newAdmissionDate) => {
                setFormContent((current) => ({ ...current, admissionDate: newAdmissionDate }));
              }}
            />
          </Grid>
          <Grid item style={responsiveDateStyle}>
            <TimeTextField
              fullWidth
              label="Admission time (24-hour format)"
              timeFormat="24"
              placeholder="hh:mm"
              value={admissionTime || null}
              error={
                (attemptedSubmit &&
                  (patientStatus === "CURRENTLY_ADMITTED" || patientStatus === "DISCHARGED") &&
                  !admissionTime) ||
                (attemptedSubmit ? !admissionTimeValidation : !!admissionTimeFormatValidation)
              }
              helperText={
                attemptedSubmit && !admissionTime
                  ? "Required"
                  : attemptedSubmit && !admissionTimeValidation
                  ? "Invalid time"
                  : admissionTimeFormatValidation
              }
              onBlur={() => {
                if (!admissionTimeValidation) {
                  setAdmissionTimeFormatValidation("Invalid time");
                }
              }}
              onChangeValue={(newAdmissionTime) => {
                setFormContent((current) => ({ ...current, admissionTime: newAdmissionTime }));
                if (admissionTimeValidation) {
                  setAdmissionTimeFormatValidation("");
                } else {
                  return "Invalid time";
                }
              }}
              dataPublic={true}
            />
          </Grid>
          <Grid item style={responsiveDropdownStyle}>
            <AdmissionSourceDropdown
              label={<MiddleTruncateLabelWithEndornment leftLabel="Admission source " rightLabel={"(optional)"} />}
              options={admissionSourceOptions}
              value={admissionSource?.code ?? "--"}
              onChange={(newAdmissionSourceId) => {
                const newAdmissionSource = admissionSourceResponse?.find(({ code }) => code === newAdmissionSourceId);
                setFormContent((current) => ({ ...current, admissionSource: newAdmissionSource }));
              }}
              InputProps={{
                endAdornment: !!admissionSource?.code && (
                  <IconButton
                    onMouseDown={(event) => event.stopPropagation()}
                    onClick={() => setFormContent((current) => ({ ...current, admissionSource: {} }))}
                    sx={{ position: "absolute", right: 40, color: "neutral.dark" }}
                    disableRipple
                    disableFocusRipple
                    size="small"
                    className="cancelButton"
                  >
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              dataPublic={true}
            />
          </Grid>
        </>
      )}
      {(patientStatus === "CURRENTLY_ADMITTED" ||
        patientStatus === "DISCHARGED" ||
        (facilityBasedConfig?.includePatientStayDatesOnPlannedAdmission && patientStatus === "NOT_YET_ADMITTED")) &&
        showPreviousDates && (
          <Grid item xs={patientStatus === "NOT_YET_ADMITTED" ? 12 : 6} container alignContent="center">
            <Body1 data-public>
              {`${numPreviousDates} ${numPreviousDates === 1 ? "day" : "days"} previously decisioned`}
              <ViewPrevDaysButton onClick={() => setPatientStayModalOpen(true)}>{`View details`}</ViewPrevDaysButton>
            </Body1>
            <PatientStayModal
              authorization={authorization}
              open={patientStayModalOpen}
              setOpen={setPatientStayModalOpen}
            />
          </Grid>
        )}
      {(facilityBasedConfig?.includePatientStayDatesOnPlannedAdmission || patientStatus !== "NOT_YET_ADMITTED") &&
        !isReview && (
          <>
            <Grid item xs={12}>
              <SectionDivider />
            </Grid>

            <PatientStays
              version={showResponsivePatientCard ? "PatientStayResponsive" : "PatientStay"}
              minimumFromDate={
                facilityBasedConfig?.includePatientStayDatesOnPlannedAdmission && patientStatus === "NOT_YET_ADMITTED"
                  ? expectedAdmissionDate
                  : admissionDate
              }
              admissionDate={
                (facilityBasedConfig?.includePatientStayDatesOnPlannedAdmission && patientStatus === "NOT_YET_ADMITTED"
                  ? expectedAdmissionDate
                  : admissionDate) || null
              }
              healthPlanName={healthPlanName}
              patientStayDateRanges={patientStayDateRanges?.length ? patientStayDateRanges : []}
              setPatientStayDateRanges={(newStayDateRanges: PatientStayDateRange[]) => {
                setFormContent?.((currentFormContent) => {
                  return { ...currentFormContent, patientStayDateRanges: newStayDateRanges };
                });
              }}
              isMock={!!admissionSources}
              levelsOfCare={levelsOfCare}
              attemptedSubmit={attemptedSubmit}
              isDischarged={patientStatus === "DISCHARGED"}
              authorization={authorization}
              patientStaysErrorStates={patientStaysErrorStates}
              patientStatus={"CURRENTLY_ADMITTED"}
              resetDischargedFields={() => {
                setFormContent?.((currentFormContent) => {
                  return {
                    ...currentFormContent,
                    dischargeDate: undefined,
                    dischargeTime: "-",
                    dischargedTo: undefined,
                  };
                });
              }}
              onAddOrRemoveDateRangeDecorator={(updatedDateRanges) => {
                if (admissionDate) {
                  return getUpdatedStayDateRanges({
                    admissionDate,
                    dischargeDate,
                    previouslyDecisionedDates,
                    currentRequestedStayDateRanges: updatedDateRanges,
                  });
                } else {
                  return updatedDateRanges;
                }
              }}
              lastDecisionedDay={previouslyDecisionedDates?.length ? last(previouslyDecisionedDates) : undefined}
              includePatientStayDatesOnPlannedAdmission={facilityBasedConfig?.includePatientStayDatesOnPlannedAdmission}
              encounterType={encounterType}
            />
          </>
        )}
      {patientStatus === "DISCHARGED" && (
        <>
          {!isReview ? (
            <Grid item xs={12}>
              <H4 pt={3}>Discharge information</H4>
            </Grid>
          ) : null}
          <Grid item style={responsiveDateStyle}>
            <DateSelect
              attemptedSubmit={attemptedSubmit}
              TextFieldProps={{ fullWidth: true }}
              maxDate={new Date()}
              minDate={addDays(admissionDate || new Date(), 0)}
              maxDateMessage="Date must be today or earlier"
              minDateMessage={`Date must be after admission (${formatDateStr(
                addDays(admissionDate || new Date(), 1)
              )} or later)`}
              label="Discharge date"
              value={dischargeDate || null}
              error={attemptedSubmit && patientStatus === "DISCHARGED" && !dischargeDate}
              showInternalErrorsOnBlur
              helperText={attemptedSubmit && !dischargeDate ? "Required" : ""}
              onDateChange={(newDischargeDate) => {
                newDischargeDate?.setHours(0, 0, 0, 0);
                setFormContent((current) => ({
                  ...current,
                  dischargeDate: newDischargeDate,
                }));
              }}
            />
          </Grid>
          <Grid item style={responsiveDateStyle}>
            <TimeTextField
              fullWidth
              label="Discharge time (24-hour format)"
              timeFormat="24"
              placeholder="hh:mm"
              value={dischargeTime || null}
              onBlur={() => {
                if (!dischargeTimeValidation && !onPatientSummary) {
                  setDischargeTimeFormatValidation("Invalid time");
                }
              }}
              error={
                (attemptedSubmit && patientStatus === "DISCHARGED" && !dischargeTime) ||
                (attemptedSubmit ? !dischargeTimeValidation : !!dischargeTimeFormatValidation)
              }
              helperText={
                attemptedSubmit && !dischargeTime
                  ? "Required"
                  : attemptedSubmit && !dischargeTimeValidation
                  ? "Invalid time"
                  : dischargeTimeFormatValidation
              }
              onChangeValue={(newDischargeTime) => {
                setFormContent((current) => ({ ...current, dischargeTime: newDischargeTime }));
                if (dischargeTimeValidation) {
                  setDischargeTimeFormatValidation("");
                } else {
                  return "Invalid time";
                }
              }}
            />
          </Grid>
          <Grid item style={responsiveDropdownStyle}>
            <SingleSelectDropdown
              label="Discharged to"
              options={dischargedToOptions}
              value={dischargedTo?.code || ""}
              error={attemptedSubmit && patientStatus === "DISCHARGED" && !dischargedTo}
              helperText={attemptedSubmit && !dischargedTo ? "Required" : ""}
              onChange={(newPatientDischargeStatusId) => {
                const newDischargedTo = patientDischargeStatusResponse?.find(
                  ({ code }) => code === newPatientDischargeStatusId
                );
                setFormContent((current) => ({ ...current, dischargedTo: newDischargedTo }));
              }}
            />
          </Grid>
        </>
      )}
      {procedureCodes.length === 0 && !hideExpeditedRequestCheckbox && (
        <>
          <Grid item xs={12}>
            <Divider style={{ marginTop: spacing(3) }} />
          </Grid>
          <SRFormConfigFieldWrapper {...formConfiguration.urgency}>
            <Grid item xs={8}>
              <div style={{ marginTop: spacing(-0.5), marginBottom: spacing(-0.5) }}>
                {currAuthStatus === "DRAFT" && (
                  <RequestExpedited
                    isExpedited={isExpedited}
                    setIsExpedited={(isExpedited: boolean, reason: string) => {
                      dispatchUrgencyRuleCheck(isExpedited);
                      setFormContent((current) => ({
                        ...current,
                        isExpedited: isExpedited,
                        expeditedReason: reason,
                      }));
                    }}
                    authStatus={currAuthStatus}
                    startDate={startDate}
                    patientId={patient?.id}
                    clinicalServiceId={clinicalService?.id}
                    patientHealthPlanName={healthPlanName || undefined}
                  />
                )}
              </div>
            </Grid>
          </SRFormConfigFieldWrapper>
        </>
      )}
    </Grid>
  );
}

const SectionDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const getPatientStatusOptions = (
  authCategory?: AuthCategoryResponse,
  disablePatientStatusChange?: boolean
): RadioGroupOption<PatientStatus>[] => {
  if (authCategory?.enumName.toString() === "OBSERVATION" && disablePatientStatusChange) {
    return [
      { id: "CURRENTLY_ADMITTED", label: "Currently admitted" },
      { id: "DISCHARGED", label: "Discharged" },
    ];
  } else {
    return [
      { id: "CURRENTLY_ADMITTED", label: "Currently admitted" },
      { id: "DISCHARGED", label: "Discharged" },
      { id: "NOT_YET_ADMITTED", label: "Not yet admitted" },
    ];
  }
};

export function getNextStayDate(previousStayDates: PatientStayDate[], currentAdmissionDate?: Date): Date | null {
  const sortedStayDates = previousStayDates
    ?.map(({ date }) => parseDateFromISOString(date))
    ?.sort((dateA, dateB) => (isAfter(dateA, dateB) ? 1 : -1));
  const firstStayDate = sortedStayDates[0];
  if (currentAdmissionDate && !isEqual(firstStayDate, currentAdmissionDate)) {
    return currentAdmissionDate;
  }
  const lastStayDate = last(sortedStayDates);
  return !!lastStayDate ? addDays(lastStayDate, 1) : null;
}

/**
 * Should be called after updating admissionDate or dischargeDate. Will do all of the auto-setting of patientStayDates.
 * @param admissionDate
 * @param dischargeDate
 * @param previouslyDecisionedDates
 * @param currentRequestedStayDateRanges
 */
export function getUpdatedStayDateRanges({
  admissionDate,
  dischargeDate,
  previouslyDecisionedDates,
  currentRequestedStayDateRanges,
}: {
  admissionDate: Date;
  dischargeDate?: Date;
  previouslyDecisionedDates?: Date[];
  currentRequestedStayDateRanges: PatientStayDateRange[];
}): PatientStayDateRange[] {
  let updatedDateRanges: PatientStayDateRange[] = [...currentRequestedStayDateRanges];

  // Assumptions are that previouslyDecisionedDates are sorted first -> last and consecutive (TODO verify this)
  if (previouslyDecisionedDates && previouslyDecisionedDates.length > 0) {
    // Logic when there are previous decisioned dates, for example a continuation
    const firstDecisionedDate = previouslyDecisionedDates[0];
    const lastDecisionedDate = previouslyDecisionedDates[previouslyDecisionedDates.length - 1];
    let i = 0;
    while (i < updatedDateRanges.length) {
      const rangeIStartDate = updatedDateRanges[i].rangeStartDate;
      const rangeIEndDate = updatedDateRanges[i].rangeEndDate;
      if (
        rangeIStartDate &&
        rangeIStartDate >= firstDecisionedDate &&
        rangeIStartDate <= lastDecisionedDate &&
        (!rangeIEndDate || (rangeIEndDate >= firstDecisionedDate && rangeIEndDate <= lastDecisionedDate))
      ) {
        updatedDateRanges = listRemove(updatedDateRanges, i);
      } else {
        ++i;
      }
    }

    if (admissionDate < firstDecisionedDate) {
      // Admission date was moved to before first decisioned date
      // The first date range needs to be before the last date range
      if (
        updatedDateRanges.length === 0 ||
        (updatedDateRanges[0].rangeStartDate && updatedDateRanges[0].rangeStartDate > firstDecisionedDate)
      ) {
        // No ranges yet, or there's a range after the decisioned dates, so add one to the beginning
        updatedDateRanges = [
          createNewPatientStayDateRange(admissionDate, plusDays(-1, firstDecisionedDate)),
          ...updatedDateRanges,
        ];
      } else {
        // There is a date range that needs to be updated
        updatedDateRanges[0].rangeStartDate = admissionDate;
        updatedDateRanges[0].rangeEndDate =
          updatedDateRanges[1]?.rangeStartDate && updatedDateRanges[1].rangeStartDate < firstDecisionedDate
            ? plusDays(-1, updatedDateRanges[1].rangeStartDate)
            : plusDays(-1, firstDecisionedDate);
      }
    } else if (updatedDateRanges[0]?.rangeStartDate && updatedDateRanges[0]?.rangeStartDate < admissionDate) {
      // There's a range that starts before the admission date
      if (updatedDateRanges[0].rangeEndDate && updatedDateRanges[0].rangeEndDate <= admissionDate) {
        updatedDateRanges = updatedDateRanges.slice(1); // remove the first date range
      } else {
        updatedDateRanges[0].rangeStartDate = admissionDate;
      }
    }

    const lastUpdatedRange = updatedDateRanges[updatedDateRanges.length - 1];
    if (dischargeDate && lastDecisionedDate && dischargeDate > plusDays(1, lastDecisionedDate)) {
      // Discharge date was moved to after the last decisioned date
      // Need to add or edit a range from last decisioned date to new discharge date
      if (
        updatedDateRanges.length === 0 ||
        (lastUpdatedRange.rangeEndDate && lastUpdatedRange.rangeEndDate < firstDecisionedDate)
      ) {
        // No ranges yet, or there's a range before the decisioned dates, so add one to the end
        updatedDateRanges.push(
          createNewPatientStayDateRange(plusDays(1, lastDecisionedDate), plusDays(-1, dischargeDate))
        );
      } else {
        // there is a date range that needs to be updated
        const start = plusDays(1, lastDecisionedDate);
        const end = plusDays(-1, dischargeDate);
        if (start <= end) {
          updatedDateRanges[updatedDateRanges.length - 1].rangeStartDate = start;
          updatedDateRanges[updatedDateRanges.length - 1].rangeEndDate = end;
        } else {
          updatedDateRanges.pop();
        }
      }
    } else if (dischargeDate && lastUpdatedRange?.rangeEndDate && lastUpdatedRange?.rangeEndDate >= dischargeDate) {
      if (lastUpdatedRange.rangeStartDate && lastUpdatedRange.rangeStartDate >= dischargeDate) {
        updatedDateRanges.pop();
      } else {
        lastUpdatedRange.rangeEndDate = plusDays(-1, dischargeDate);
      }
    } else if (dischargeDate && !lastUpdatedRange?.rangeEndDate && updatedDateRanges?.length) {
      updatedDateRanges.pop();
    }
    if (admissionDate > plusDays(-1, firstDecisionedDate) && updatedDateRanges.length > 0) {
      // Admission and discharge date are within previously decisioned dates
      // and there is a current date range
      updatedDateRanges[0].rangeStartDate = plusDays(1, lastDecisionedDate);
    }
    if (dischargeDate && dischargeDate < plusDays(1, lastDecisionedDate) && updatedDateRanges.length > 0) {
      if (admissionDate < firstDecisionedDate) {
        lastUpdatedRange.rangeEndDate = plusDays(-1, firstDecisionedDate);
      } else {
        lastUpdatedRange.rangeEndDate = plusDays(-1, dischargeDate);
      }
    }
  } else {
    // No previously decisioned dates
    if (updatedDateRanges.length > 0) {
      // patient stay date array has elements
      updatedDateRanges[0].rangeStartDate = admissionDate;
      if (dischargeDate) {
        updatedDateRanges[updatedDateRanges.length - 1].rangeEndDate = plusDays(-1, dischargeDate);
      }
    } else {
      updatedDateRanges = [
        createNewPatientStayDateRange(admissionDate, dischargeDate ? plusDays(-1, dischargeDate) : null),
      ];
    }
  }
  return updatedDateRanges;
}

export const createNewPatientStayDateRange = (startDate: Date, endDate: Date | null): PatientStayDateRange => ({
  rangeStartDate: startDate,
  rangeEndDate: endDate,
  approvedLevelOfCareCode: null,
  requestedLevelOfCareCode: null,
  reviewStatus: null,
});
