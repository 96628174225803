import { Fragment, useState } from "react";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { StyledBody1 } from "../ServiceRequestInformation";
import ClinicalReviewCard from "common/ClinicalReviewCard";
import {
  H5,
  FiltersPopover,
  FilterConfig,
  initialFilterValues,
  FilterValues,
  MultiSelectFilterConfig,
  DropdownOption,
  DateRangeFilterConfig,
  DateRange,
  useFeature,
} from "@coherehealth/common";
import ReviewComponent from "./ReviewHistoryReviewComponent";
import ContactComponent from "./ReviewHistoryContactComponent";
import Divider from "@material-ui/core/Divider";
import { sortedReviewTimelineData } from "../infoPanelUtils";
import { IndividualNote } from "../NotesCard/NotesDisplay/IndividualNote";
import { HistoryOutreach, ReviewTimelineNotesProps, HistoryData } from "../NotesCard/notesCardTypes";
import { AppealViewOnly } from "components/AppealNote/AppealViewOnly";
import { AuthorizationResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";

export default function ReviewHistoryInformation({
  allHistoryData,
  loading,
  noteConfig,
  hasContinuations,
  authorization,
  serviceRequest,
  setTabOpen,
}: ReviewTimelineNotesProps) {
  const sortedHistoryData = sortedReviewTimelineData(allHistoryData);
  const [timelineData, setTimelineData] = useState<HistoryData[]>(sortedHistoryData);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [expansionByOutreachId, setExpansionByOutreachId] = useState<Record<string, boolean>>({});
  const [shouldDisplayErrorText, setShouldDisplayErrorText] = useState<boolean>(false);
  const [errorDisplayText, setErrorDisplayText] = useState<string>(
    "No reviews, outreach attempts, notes or appeals for this Authorization."
  );
  const [isInFilteredState, setIsInFilteredState] = useState<boolean>(false);
  const length = sortedHistoryData?.length;

  const logrocketUnmasking = useFeature("logrocketUnmasking");

  const toggleExpandedOutreach = (outreach: HistoryOutreach) => {
    if (!((window.getSelection()?.toString().length || 0) > 0)) {
      const id = outreach?.id as string;
      setExpansionByOutreachId((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    }
  };

  let reviewTypeCounts = {
    numReviews: 0,
    numOutreachAttempts: 0,
    numNotes: 0,
    numAppeals: 0,
  };

  sortedHistoryData?.forEach((item) => {
    switch (item.objType) {
      case "REVIEW":
        reviewTypeCounts.numReviews++;
        break;
      case "NOTES":
        reviewTypeCounts.numNotes++;
        break;
      case "CONTACT":
        reviewTypeCounts.numOutreachAttempts++;
        break;
      case "APPEAL":
        reviewTypeCounts.numAppeals++;
        break;
      default:
        break;
    }
  });

  const dateRangeFilterConfig: DateRangeFilterConfig = {
    key: "dateRangeFilter",
    label: "Added",
    filterType: "DateRange",
    startApiParamName: "",
    endApiParamName: "",
    initialFilterValue: null,
  };

  const multiOptions: DropdownOption[] = [
    { id: "reviews", label: "Reviews" },
    { id: "contacts", label: "Contacts" },
    { id: "notes", label: "Notes" },
    { id: "appeals", label: "Appeals" },
  ];

  const singleSelectFilter: MultiSelectFilterConfig = {
    filterType: "MultiSelect",
    options: multiOptions,
    key: "objectTypePicker",
    label: "Filter by type",
  };

  const filterConfigs: FilterConfig[] = [dateRangeFilterConfig, singleSelectFilter];

  const initialValues = initialFilterValues(filterConfigs, true);

  const handleFilterValueChanges = (filterValues: FilterValues) => {
    const dateAfter: Date | null | undefined = (filterValues?.dateRangeFilter as DateRange)?.start;
    const dateBefore: Date | null | undefined = (filterValues?.dateRangeFilter as DateRange)?.end;
    const selectedObjectTypes: any[] = filterValues.objectTypePicker as any[];

    if (
      (dateAfter === null || dateAfter === undefined) &&
      (dateBefore === null || dateBefore === undefined) &&
      selectedObjectTypes.length === 0
    ) {
      setTimelineData(sortedHistoryData);
      setShouldDisplayErrorText(false);
      setIsInFilteredState(false);
      setErrorDisplayText("No reviews, outreach attempts, notes or appeals for this Authorization.");
      return;
    }

    const filteredData: HistoryData[] = [];

    selectedObjectTypes.forEach((each) => {
      const id = each.id.toLowerCase();
      const filteredObjects: HistoryData[] =
        sortedHistoryData?.filter((data) => {
          if (determineValidDateRange(dateBefore, dateAfter, data.lastUpdated)) {
            if (id === "notes" && data.objType.toUpperCase() === "NOTES") {
              return true;
            } else if (id === "contacts" && data.objType.toUpperCase() === "CONTACT") {
              return true;
            } else if (id === "reviews" && data.objType.toUpperCase() === "REVIEW") {
              return true;
            } else if (id === "appeals" && data.objType.toUpperCase() === "APPEAL") {
              return true;
            }
          }
          return false;
        }) || [];
      filteredData.push(...filteredObjects);
    });
    if (filteredData.length === 0) {
      setShouldDisplayErrorText(true);
      setErrorDisplayText("There are no reviews, contacts or notes to show with the applied filters.");
    } else {
      setShouldDisplayErrorText(false);
    }
    setTimelineData(filteredData);
    setIsInFilteredState(true);
  };

  const determineValidDateRange = (
    beforeDate: Date | null | undefined,
    afterDate: Date | null | undefined,
    objectLastUpdated: string | number | Date | undefined
  ) => {
    if (objectLastUpdated === undefined) {
      return false;
    }
    const parsedDate: Date = new Date(objectLastUpdated);
    parsedDate.setHours(0, 0, 0, 0);
    const beforeDateAdjusted = beforeDate ? new Date(beforeDate).setHours(0, 0, 0, 0) : null;
    const afterDateAdjusted = afterDate ? new Date(afterDate).setHours(0, 0, 0, 0) : null;

    return (
      (beforeDateAdjusted === null && afterDateAdjusted === null) ||
      (beforeDateAdjusted === null && afterDateAdjusted !== null && parsedDate.getTime() >= afterDateAdjusted) ||
      (beforeDateAdjusted !== null && afterDateAdjusted === null && parsedDate.getTime() <= beforeDateAdjusted) ||
      (beforeDateAdjusted !== null &&
        afterDateAdjusted !== null &&
        parsedDate.getTime() >= afterDateAdjusted &&
        parsedDate.getTime() <= beforeDateAdjusted)
    );
  };

  const getServiceRequest = (authorization: AuthorizationResponse | undefined, id: string | undefined) => {
    if (authorization?.serviceRequestsOnAuth) {
      return authorization?.serviceRequestsOnAuth?.find((sr) => sr.id === id) as ServiceRequestResponse;
    }
    return serviceRequest;
  };

  return (
    <>
      <ClinicalReviewCard
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        header={
          <>
            <H5 data-public={!!logrocketUnmasking ? true : undefined}>Review Timeline</H5>
            {loading ? (
              <CircularProgress size={15} />
            ) : (
              <Grid
                container
                style={{ marginTop: 6, ...(isExpanded ? {} : { marginBottom: 4 }) }}
                data-testid="review-timeline-section-header"
              >
                <span style={{ marginRight: "20px" }}>
                  <StyledBody1>
                    {reviewTypeCounts.numReviews}{" "}
                    {reviewTypeCounts.numReviews === 1 ? "Previous Review" : "Previous Reviews"}
                  </StyledBody1>
                </span>
                <span style={{ marginRight: "20px" }}>
                  <StyledBody1>
                    {reviewTypeCounts.numOutreachAttempts}{" "}
                    {reviewTypeCounts.numOutreachAttempts === 1 ? "Contact" : "Contacts"}
                  </StyledBody1>
                </span>
                <span style={{ marginRight: "20px" }}>
                  <StyledBody1>
                    {reviewTypeCounts.numNotes} {reviewTypeCounts.numNotes === 1 ? "Note" : "Notes"}
                  </StyledBody1>
                </span>
                <span style={{ marginRight: "20px" }}>
                  <StyledBody1>
                    {reviewTypeCounts.numAppeals}{" "}
                    {reviewTypeCounts.numAppeals === 1 ? "Post appeal note" : "Post appeal notes"}
                  </StyledBody1>
                </span>
              </Grid>
            )}
          </>
        }
      >
        <Box display="flex" flexDirection="row">
          <FiltersPopover
            filterConfigs={filterConfigs}
            initialValues={initialValues}
            onFiltersChange={(filterValues: FilterValues) => {
              handleFilterValueChanges(filterValues);
            }}
            id="reviewHistoryCard"
          />
        </Box>
        {length === 0 || shouldDisplayErrorText ? (
          <Grid item xs={12} style={{ display: "flex", justifyContent: "center", marginTop: 32, marginBottom: 20 }}>
            <StyledBody1>{errorDisplayText}</StyledBody1>
          </Grid>
        ) : (
          <>
            <Box display="flex" flexDirection="column">
              {timelineData?.map((object, idx) =>
                object.objType === "NOTES" ? (
                  <Fragment key={object.id}>
                    <IndividualNote
                      note={object}
                      index={idx}
                      serviceRequest={getServiceRequest(authorization, object?.serviceRequest?.id)}
                      view={"REVIEW"}
                      key={`${object.id}_${idx}_${object.dateCreated}`}
                      noteConfig={noteConfig}
                      hasContinuations={hasContinuations}
                      isNewServiceRequest={object.isNewServiceRequest}
                      isInFilteredState={isInFilteredState}
                    />
                    {length !== undefined && idx !== length - 1 && <Divider />}
                  </Fragment>
                ) : object.objType === "REVIEW" && object.reviewStatus !== "DRAFT" ? (
                  <Fragment key={object.id}>
                    <ReviewComponent
                      key={`${object.id}_${idx}`}
                      review={object}
                      index={idx}
                      hasContinuations={hasContinuations}
                      isNewServiceRequest={object?.isNewServiceRequest}
                      isInFilteredState={isInFilteredState}
                      serviceRequest={getServiceRequest(authorization, object?.serviceRequest?.id)}
                    />
                    {length !== undefined && idx !== length - 1 && <Divider />}
                  </Fragment>
                ) : object.objType === "CONTACT" ? (
                  <Fragment key={object.id}>
                    <ContactComponent
                      expanded={expansionByOutreachId[object?.id ?? ""]}
                      toggleExpanded={() => toggleExpandedOutreach(object)}
                      key={`${object.id}_${idx}`}
                      outreach={object}
                      buttonPadding={"15px 0px"}
                      index={idx}
                      hasContinuations={hasContinuations}
                      isNewServiceRequest={object.isNewServiceRequest}
                      isInFilteredState={isInFilteredState}
                      serviceRequest={getServiceRequest(authorization, object?.serviceRequestId)}
                    />{" "}
                    {length !== undefined && idx !== length - 1 && <Divider />}
                  </Fragment>
                ) : object.objType === "APPEAL" ? (
                  <Fragment key={object.id}>
                    <AppealViewOnly
                      key={`${object.id}_${idx}`}
                      appeal={object}
                      setTabOpen={setTabOpen}
                      serviceRequest={getServiceRequest(authorization, object?.serviceRequest?.id)}
                    />
                  </Fragment>
                ) : (
                  <Fragment key={idx}></Fragment>
                )
              )}
            </Box>
          </>
        )}
      </ClinicalReviewCard>
    </>
  );
}
