import { Caption, colorsLight, formatDateStrWithTz, InlineButton, Sanitized } from "@coherehealth/common";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { Body2 } from "@coherehealth/design-system";
import { Grid, makeStyles } from "@material-ui/core";
import { HistoryAppeal } from "components/ClinicalReview/ClinicalReviewInfoPanel/NotesCard/notesCardTypes";
import { ClinicalReviewContext } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { LeftPanelTabs } from "components/ClinicalReview/reviewUtils/utils";
import { useContext } from "react";

interface AppealViewOnlyProps {
  appeal: HistoryAppeal;
  setTabOpen?: React.Dispatch<React.SetStateAction<LeftPanelTabs>>;
  serviceRequest: ServiceRequestResponse;
}

const appealViewOnlyStyle = makeStyles(() => ({
  postAppealNote: {
    margin: "-7px 0px",
  },
}));

const AppealOutcomesToDisplayValue: Record<string, string> = {
  APPROVED: "approval",
  PARTIALLY_APPROVED: "partial approval",
  DENIED: "denial",
  NOT_COMPLETED: "Not completed",
  WITHDRAWN: "Withdrawn",
};

const upholdOrOverturn = (currentAuthStatus?: string, optionAuthStatus?: string) => {
  return currentAuthStatus === optionAuthStatus ? "Uphold initial" : "Overturned to";
};

export const AppealViewOnly = ({ appeal, setTabOpen, serviceRequest }: AppealViewOnlyProps) => {
  const { allowedReviewOutcomes } = useContext(ClinicalReviewContext);
  const appealOutcome = allowedReviewOutcomes?.find((outcome) => outcome.id === appeal.outcome);
  const { initialDecisionDisposition } = serviceRequest ?? {};
  const outcomeText = ["DENIED", "APPROVED", "PARTIALLY_APPROVED"].includes(appeal.outcome)
    ? `${upholdOrOverturn(appeal.outcome, initialDecisionDisposition)} ${AppealOutcomesToDisplayValue[appeal.outcome]}`
    : `${AppealOutcomesToDisplayValue[appeal.outcome]}`;
  const style = appealViewOnlyStyle();
  return (
    <Grid
      data-testid="appeal-view-only-container"
      container
      style={{ marginTop: 16 }}
      direction="column"
      alignItems="flex-start"
    >
      <Grid item container direction="row" xs={11} alignItems="center">
        <Body2 style={{ paddingRight: "16px" }}>Post-appeal note</Body2>
        <Caption style={{ color: colorsLight.font.secondary }}>{`${
          appealOutcome?.label ?? outcomeText
        } on ${formatDateStrWithTz(appeal?.dateCreated)} by ${appeal?.createdByName}`}</Caption>
      </Grid>
      <Grid>
        <Caption>Post-appeal note</Caption>
        <Sanitized __html={appeal?.postAppealNote} className={style.postAppealNote} />
      </Grid>
      <InlineButton data-testid="view-post-appeal-attachments" onClick={() => setTabOpen?.("ATTACHMENTS")}>
        View post-appeal attachments
      </InlineButton>
    </Grid>
  );
};
