import {
  SingleSelectForProviderFacilitySelect,
  OptionsDecorator,
  SelectOptionsHook,
  useFeature,
  useLazyLoadingQueryOptionsHook,
} from "@coherehealth/common";
import {
  Facility,
  Provider,
  Patient,
  useGetProvidersOrFacilities,
  UseGetProvidersOrFacilitiesProps,
  Location,
  PracticeCommon,
  useUpdateFacility,
  useUpsertManuallyEnteredProviderOrFacility,
  useCreateFacility,
} from "@coherehealth/core-platform-api";
import { Grid } from "@material-ui/core";
import {
  providerFacilityDropdownRender,
  providerFacilityTinRenderer,
  providerFacilityAddressRenderer,
} from "components/ServiceRequest/ProviderFacilityDropdownRender";
import React, { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { FacilityOption } from "./FacilitySelectManual";
import {
  getLocationsForTin,
  TinOption,
  getTinListWithOptions,
  getTinWithOptions,
  LocationOption,
  getLocationsWithOptions,
} from "./ProviderSelectManual";
import listInsert from "util/listInsert";
import { getCurrentCoverage, getPatientHealthPlanName } from "util/patientUtils";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { FormConfiguration } from "components/ServiceRequest/ConfigurableServiceRequestForm";
import { RecentProvidersContext } from "common/RecentProviders/context";
import Recents from "common/RecentProviders";
import ProviderFacilityModal from "../../ProviderFacilityModalManual";
import { ModalStatus } from "util/providerUtils";
import { useSnackbar } from "notistack";

interface PerformingProviderPracticeSelectManualProps {
  patient?: Patient | undefined;
  performingProviderPracticeError: boolean;
  npiError?: boolean;
  tinError?: boolean;
  addressError?: boolean;
  formContent: ServiceRequestFormContent;
  showAddressField?: boolean;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  hideTinField?: boolean;
  formConfiguration?: FormConfiguration;
  setPerformingProviderPracticeExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  healthPlanName?: string;
}

type PracticeUpsertPayload = PracticeCommon & {
  recordType?: "PROVIDER" | "FACILITY";
};

const getPerformingProviderPracticeForUpdate = (
  currProvider: Facility,
  currentTin: string | null | undefined,
  currentAddress: Location | null | undefined,
  modalStatus: ModalStatus,
  payload: PracticeUpsertPayload,
  ignoreTin: boolean
): Facility & { recordType: "PROVIDER" | "FACILITY" } => {
  const selLoc = payload.selectedLocation as LocationOption;
  selLoc.manuallyEditedAddress = true;
  if (!currProvider.tinList) {
    currProvider.tinList = [];
  }
  if (!currProvider.locations) {
    currProvider.locations = [];
  }
  currProvider.lastUpdated = new Date().toISOString();
  switch (modalStatus) {
    case ModalStatus.AddTin:
      if (selLoc.tin) {
        currProvider.tinList?.push(selLoc.tin);
        currProvider.locations?.push(selLoc);
        currProvider.selectedLocation = selLoc;
      }
      break;
    case ModalStatus.EditTin:
      // remove existing tin from list
      if (currProvider && selLoc.tin && selLoc && currentTin) {
        let index = currProvider.tinList.indexOf(currentTin, 0);
        if (index > -1) {
          currProvider.tinList.splice(index, 1);
        }

        // change location associated with tin
        currProvider.locations.forEach(function (x) {
          if (x.tin === currentTin) {
            x.tin = selLoc.tin;
          }
        });

        currProvider.tinList.push(selLoc.tin);
        currProvider.selectedLocation = selLoc;
      }

      break;
    case ModalStatus.AddAddress:
      if (selLoc.tin || (ignoreTin && selLoc)) {
        currProvider.locations.push(selLoc);
        currProvider.selectedLocation = selLoc;
      }
      break;

    case ModalStatus.EditAddress:
      if (currProvider && (selLoc.tin || ignoreTin) && selLoc) {
        // remove location associated with tin
        const index = currProvider.locations.findIndex(
          (x) =>
            x.tin === currentTin &&
            x.address?.line1 === currentAddress?.address?.line1 &&
            x.address?.line2 === currentAddress?.address?.line2 &&
            x.address?.state === currentAddress?.address?.state &&
            x.address?.zipCode === currentAddress?.address?.zipCode &&
            x.phoneNumbers?.length === currentAddress?.phoneNumbers?.length &&
            JSON.stringify(x.phoneNumbers?.sort()) === JSON.stringify(currentAddress?.phoneNumbers?.sort()) &&
            x.faxNumbers?.length === currentAddress?.faxNumbers?.length &&
            JSON.stringify(x.faxNumbers?.sort()) === JSON.stringify(currentAddress?.faxNumbers?.sort())
        );
        if (index > -1) {
          currProvider.locations.splice(index, 1);
        }
        currProvider.locations.push(selLoc);
        currProvider.selectedLocation = selLoc;
      }
      break;
  }
  return { ...currProvider, recordType: payload.recordType || (payload.type as "PROVIDER" | "FACILITY") };
};

function getUseFacilities(
  patient: Patient | undefined,
  useCMSProviders: string,
  ignoreTin: boolean,
  selectedLocation: Location | undefined | null
): SelectOptionsHook<FacilityOption> {
  return function useFacilities(selectOptionsParams) {
    const today = new Date();
    const healthPlanName = patient ? getPatientHealthPlanName(patient, today) : undefined;

    const patientCoverage = getCurrentCoverage(patient);
    const rpn = patientCoverage?.payerCustomFields?.find((field) => field.fieldName === "RPN")?.valueString;

    const affiliatedGroup: string | undefined = selectedLocation?.payerCustomFields?.find(
      (pcf) => pcf.fieldName?.toLocaleLowerCase() === "affiliatedgroup"
    )?.valueString;

    const preventUserCreatedPerformingProviderPractice = useFeature("preventUserCreatedPerformingProviderPractice");
    const addManualCreationOption: OptionsDecorator<FacilityOption> | undefined =
      healthPlanName === "Oscar"
        ? (loadedOptions, hasMoreOptions) => {
            if (!hasMoreOptions) {
              const manualOption: FacilityOption = {
                name: "",
                id: "",
                dateCreated: "",
                lastUpdated: "",
              };
              if (!loadedOptions || loadedOptions.length === 0) {
                manualOption.optionType = "noResultsOscar";
                return listInsert(loadedOptions || [], 0, manualOption);
              }
            }
            return loadedOptions;
          }
        : !preventUserCreatedPerformingProviderPractice
        ? (loadedOptions, hasMoreOptions) => {
            if (!hasMoreOptions) {
              const manualOption: FacilityOption = {
                name: "",
                id: "",
                dateCreated: "",
                lastUpdated: "",
              };
              if (!loadedOptions || loadedOptions.length === 0) {
                manualOption.optionType = "noResults";
                return listInsert(loadedOptions || [], 0, manualOption);
              } else {
                manualOption.optionType = "someResults";
                return listInsert(loadedOptions, loadedOptions.length, manualOption);
              }
            }
            return loadedOptions;
          }
        : undefined;

    const deDuplicateManualProviders = useFeature("deDuplicateManualProvidersInSearch");
    const queryParams = {
      healthPlanName: healthPlanName || undefined,
      useCMSProviders: useCMSProviders,
      ignoreTin: ignoreTin,
      deDuplicateManualProviders: deDuplicateManualProviders,
      affiliatedGroup,
      rpn,
    };

    return useLazyLoadingQueryOptionsHook({
      useGetHook: (args: UseGetProvidersOrFacilitiesProps) => useGetProvidersOrFacilities({ ...args, debounce: 600 }),
      optionsDecorator: addManualCreationOption,
      additionalQueryParams: { ...queryParams, recordType: "FACILITY" },
      ...selectOptionsParams,
    });
  };
}

const PerformingProviderPracticeSelectManual: React.FC<PerformingProviderPracticeSelectManualProps> = ({
  patient,
  formContent,
  hideTinField = false,
  showAddressField,
  setFormContent,
  formConfiguration,
  setPerformingProviderPracticeExceptionRequest,
  healthPlanName,
  performingProviderPracticeError,
  npiError,
  tinError,
  addressError,
}) => {
  type modalStatus = ModalStatus;

  const { trackingStateClickChangeCheck } = useContext(RecentProvidersContext);

  const enableManualProviderInfo: boolean = useFeature("manualProviderInfo");

  const { mutate: updatePerformingProviderPractice, error: performingProviderPracticeUpdateError } = useUpdateFacility({
    id: formContent?.facility ? formContent?.facility.id : "",
  });

  const { mutate: upsertManuallyEnteredProviderOrFacility, error: upsertManuallyEnteredProviderOrFacilityError } =
    useUpsertManuallyEnteredProviderOrFacility({});

  const { mutate: createPerformingProviderPractice, error: performingProviderPracticeCreationError } =
    useCreateFacility({});

  let helperMessages: ReactNode[] = [];

  if (performingProviderPracticeError) {
    helperMessages.push("Required");
  }

  const [performingProviderPracticeModalOpen, setPerformingProviderPracticeModalOpen] = useState(false);

  const [modalOp, setModalOp] = useState<modalStatus>(ModalStatus.AddAddress);

  const { enqueueSnackbar } = useSnackbar();

  const getAddress = (address: any): string => {
    let finalAddress: string = "";
    if (address) {
      if (address?.line1) {
        finalAddress = `${finalAddress}${address.line1}, `;
      }
      if (address?.line2) {
        finalAddress = `${finalAddress}${address.line2}, `;
      }
      if (address?.city) {
        finalAddress = `${finalAddress}${address.city}, `;
      }
      if (address?.state) {
        finalAddress = `${finalAddress}${address.state} - `;
      }
      if (address?.zipCode) {
        finalAddress = `${finalAddress}${address.zipCode}`;
      }
    }
    return finalAddress;
  };

  useEffect(() => {
    if (performingProviderPracticeCreationError) {
      enqueueSnackbar(performingProviderPracticeCreationError.message, { variant: "error" });
    }
  }, [performingProviderPracticeCreationError, enqueueSnackbar]);

  useEffect(() => {
    if (performingProviderPracticeUpdateError || upsertManuallyEnteredProviderOrFacilityError) {
      enqueueSnackbar(
        performingProviderPracticeUpdateError?.message || upsertManuallyEnteredProviderOrFacilityError?.message,
        {
          variant: "error",
        }
      );
    }
  }, [upsertManuallyEnteredProviderOrFacilityError, performingProviderPracticeUpdateError, enqueueSnackbar]);
  return (
    <>
      <Grid
        item
        style={{
          flex: `1 1 560px`,
          minWidth: "200px",
        }}
      >
        <SingleSelectForProviderFacilitySelect<FacilityOption>
          disablePortal
          clearable={formConfiguration?.performingProviderPracticeSelectedNPI.fieldSpec === "OPTIONAL"}
          error={performingProviderPracticeError || npiError}
          markSelectedOptions={false}
          helperText={performingProviderPracticeError ? "Required" : npiError ? "NPI Required" : ""}
          label={`Performing provider practice`}
          emptyLabel={"Performing provider practice NPI or name"}
          useOptions={getUseFacilities(patient, "true", hideTinField, formContent.performingProviderSelectedAddress)}
          getOptionLabel={({ name, npi }) => `${name} / NPI - ${npi}`}
          renderOption={({ name, tinList, npi, optionType }) => {
            return providerFacilityDropdownRender(
              name,
              tinList,
              npi,
              optionType,
              (tin: string | null) =>
                setFormContent({ ...formContent, selectedPerformingProviderPracticeSelectedTIN: tin }),
              hideTinField,
              false
            );
          }}
          editAction={() => {
            setModalOp(ModalStatus.Edit);
            setPerformingProviderPracticeModalOpen(true);
          }}
          selectedValue={formContent?.selectedPerformingProviderPractice || null}
          setSelectedValue={async (performingProviderPractice) => {
            setPerformingProviderPracticeExceptionRequest?.(false);
            const selectedTin =
              performingProviderPractice?.tinList?.length === 1 ? performingProviderPractice.tinList[0] : undefined;
            let locationsForTin = getLocationsForTin(selectedTin, performingProviderPractice, hideTinField);
            let selectedLocation: Location = {};
            if (locationsForTin && locationsForTin?.length === 1) {
              selectedLocation = locationsForTin[0];
            }
            if (
              hideTinField ||
              (performingProviderPractice?.source === "CMS" &&
                !performingProviderPractice?.manuallyCreated &&
                !selectedLocation.address)
            ) {
              if (performingProviderPractice?.locations && performingProviderPractice.locations.length > 0) {
                selectedLocation = performingProviderPractice.locations[0];
              }
            }

            if (["noResults", "someResults"].includes(performingProviderPractice?.optionType || "")) {
              setFormContent((prevContent) => ({
                ...prevContent,
                selectedPerformingProviderPracticeSelectedTIN: undefined,
                selectedPerformingProviderPracticeAddress: undefined,
                selectedPerformingProviderPractice: performingProviderPractice,
              }));
              setModalOp(ModalStatus.Add);
              setPerformingProviderPracticeModalOpen(true);
            } else if (performingProviderPractice === null) {
              setFormContent((prevContent) => {
                return {
                  ...prevContent,
                  selectedPerformingProviderPracticeAddress: null,
                  selectedPerformingProviderPractice: null,
                  selectedPerformingProviderPracticeSelectedTIN: null,
                  selectedPerformingProviderPracticeOONExceptionRequired: false,
                };
              });
            } else {
              const selectedsPerformingProviderPractice: Facility = {
                ...performingProviderPractice,
                selectedLocation: selectedLocation.address ? selectedLocation : undefined,
                type: "PERFORMING PROVIDER PRACTICE",
              } as Facility;
              setFormContent((prevContent) => ({
                ...prevContent,
                selectedPerformingProviderPracticeSelectedTIN:
                  performingProviderPractice?.tinList?.length === 1 ? performingProviderPractice.tinList[0] : null,
                selectedPerformingProviderPracticeAddress: selectedLocation,
                selectedPerformingProviderPractice: selectedsPerformingProviderPractice,
              }));
            }
            // * Code tag RECENT_PROVIDERS_8B12CDB1
            if (trackingStateClickChangeCheck) {
              trackingStateClickChangeCheck("PerformingProviderPractice");
            }
          }}
          dataPublic={true}
        />
      </Grid>
      {(showAddressField || !hideTinField) && (
        <Grid
          item
          container
          spacing={2}
          style={{
            flex: `1 1 50%`,
          }}
        >
          {!hideTinField && (
            <Grid item style={{ flex: `1 1 auto`, minWidth: "167px" }}>
              <SingleSelectForProviderFacilitySelect<TinOption>
                disablePortal
                label={"TIN"}
                clearable={formConfiguration?.performingProviderPracticeSelectedTIN.fieldSpec === "OPTIONAL"}
                disabled={!Boolean(formContent?.selectedPerformingProviderPractice)}
                markSelectedOptions={false}
                error={tinError}
                helperText={tinError && "Required"}
                withEditAdornment={
                  Boolean(formContent?.selectedPerformingProviderPractice?.manuallyCreated) &&
                  Boolean(formContent?.selectedPerformingProviderPracticeSelectedTIN) &&
                  Boolean(formContent?.selectedPerformingProviderPracticeSelectedTIN !== "N/A")
                }
                editAction={() => {
                  setModalOp(ModalStatus.EditTin);
                  setPerformingProviderPracticeModalOpen(true);
                }}
                setSelectedValue={async (tin) => {
                  if (tin && tin.id !== "") {
                    setFormContent((prev) => ({
                      ...prev,
                      selectedPerformingProviderPracticeSelectedTIN: tin.id,
                    }));

                    let locationsForTin = getLocationsForTin(tin.id, formContent?.selectedPerformingProviderPractice);

                    let selectedLocation: Location = {};
                    if (locationsForTin && locationsForTin?.length === 1) {
                      selectedLocation = locationsForTin[0];
                    }
                    const selectedPerformingProviderPractice: Facility = {
                      ...formContent?.selectedPerformingProviderPractice,
                      selectedLocation: selectedLocation.address ? selectedLocation : undefined,
                    } as Facility;

                    setFormContent((prev) => ({
                      ...prev,
                      selectedPerformingProviderPracticeAddress: selectedLocation,
                      selectedPerformingProviderPractice: selectedPerformingProviderPractice,
                    }));
                  } else if (["noResults", "someResults"].includes(tin?.optionType || "")) {
                    const performingProviderPractice: Facility = {
                      ...formContent?.selectedPerformingProviderPractice,
                      selectedLocation: undefined,
                    } as Facility;
                    setFormContent((prev) => ({
                      ...prev,
                      selectedPerformingProviderPracticeSelectedTIN: undefined,
                      selectedPerformingProviderPracticeAddress: undefined,
                      selectedPerformingProviderPractice: performingProviderPractice,
                    }));
                    setModalOp(ModalStatus.AddTin);
                    setPerformingProviderPracticeModalOpen(true);
                  }

                  // * Code tag RECENT_PROVIDERS_8B12CDB1
                  if (trackingStateClickChangeCheck) {
                    trackingStateClickChangeCheck("PerformingProviderPractice");
                  }
                }}
                useOptions={() => ({
                  options: getTinListWithOptions(formContent?.selectedPerformingProviderPractice?.tinList),
                  optionsLoading: false,
                  filterOptions: (options, state) =>
                    options.filter((option) => option.val?.toLowerCase().includes(state.inputValue.toLowerCase())),
                })}
                selectedValue={getTinWithOptions(
                  formContent?.selectedPerformingProviderPracticeSelectedTIN ||
                    formContent?.selectedPerformingProviderPractice?.selectedLocation?.tin
                )}
                getOptionLabel={({ val }) => val}
                renderOption={({ id, val, optionType }) => providerFacilityTinRenderer(id, val, optionType)}
                dataPublic={false}
              />
            </Grid>
          )}
          {showAddressField && (
            <Grid item style={{ flex: `1 1 60%`, minWidth: "200px" }}>
              <SingleSelectForProviderFacilitySelect<LocationOption>
                disablePortal
                label={"Address"}
                disabled={
                  !(
                    Boolean(formContent?.selectedPerformingProviderPractice) &&
                    (hideTinField ||
                      Boolean(
                        formContent?.selectedPerformingProviderPracticeSelectedTIN ??
                          formContent?.selectedPerformingProviderPractice?.selectedLocation?.tin
                      ) ||
                      (formContent?.selectedPerformingProviderPractice?.source === "CMS" &&
                        !formContent?.selectedPerformingProviderPractice?.manuallyCreated))
                  )
                }
                clearable={formConfiguration?.performingProviderPracticeSelectedAddress.fieldSpec === "OPTIONAL"}
                error={addressError}
                helperText={addressError && "Required"}
                markSelectedOptions={false}
                withEditAdornment={
                  Boolean(formContent?.selectedPerformingProviderPractice?.manuallyCreated) &&
                  Boolean(formContent?.selectedPerformingProviderPracticeAddress) &&
                  Boolean(formContent?.selectedPerformingProviderPracticeAddress?.manuallyEditedAddress)
                }
                useOptions={() => ({
                  options: getLocationsWithOptions(
                    getLocationsForTin(
                      formContent?.selectedPerformingProviderPracticeSelectedTIN,
                      formContent?.selectedPerformingProviderPractice,
                      hideTinField
                    ),
                    enableManualProviderInfo,
                    formContent?.selectedPerformingProviderPracticeSelectedTIN,
                    hideTinField
                  ),
                  optionsLoading: false,
                  filterOptions: (options, state) =>
                    options.filter(
                      (option) =>
                        option?.address?.city?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.address?.line1?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.address?.line2?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.address?.state?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.address?.zipCode?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.optionType === "noResults" ||
                        option?.optionType === "someResults"
                    ),
                })}
                selectedValue={
                  formContent?.selectedPerformingProviderPracticeAddress ||
                  formContent?.selectedPerformingProviderPractice?.selectedLocation ||
                  null
                }
                getOptionLabel={({ address }) => getAddress(address)}
                editAction={() => {
                  setModalOp(ModalStatus.EditAddress);
                  setPerformingProviderPracticeModalOpen(true);
                }}
                setSelectedValue={(location) => {
                  if (["noResults", "someResults"].includes(location?.optionType || "")) {
                    const performingProviderPractice = {
                      ...formContent?.selectedPerformingProviderPractice,
                      selectedLocation: undefined,
                      type: "FACILITY",
                    } as Facility;
                    setFormContent((prev) => ({
                      ...prev,
                      selectedPerformingProviderPracticeAddress: undefined,
                      selectedPerformingProviderPractice: performingProviderPractice,
                    }));
                    setModalOp(ModalStatus.AddAddress);
                    setPerformingProviderPracticeModalOpen(true);
                  } else {
                    const performingProviderPractice = {
                      ...formContent?.selectedPerformingProviderPractice,
                      selectedLocation: location,
                      type: "FACILITY",
                    } as Facility;
                    setFormContent((prev) => ({
                      ...prev,
                      selectedPerformingProviderPracticeAddress: location,
                      selectedPerformingProviderPractice: performingProviderPractice,
                    }));
                  }

                  // * Code tag RECENT_PROVIDERS_8B12CDB1
                  if (trackingStateClickChangeCheck) {
                    trackingStateClickChangeCheck("PerformingProviderPractice");
                  }
                }}
                renderOption={({ address, optionType }) => providerFacilityAddressRenderer(address, optionType)}
                dataPublic={true}
              />
            </Grid>
          )}
        </Grid>
      )}
      <Grid item xs={12} style={{ padding: 0 }}>
        <Recents
          bucketName="PerformingProviderPractice"
          setFormContent={setFormContent}
          performingProviderPracticeId={
            formContent?.selectedPerformingProviderPractice?.id || formContent?.selectedPerformingProviderPractice?.npi
          }
          healthPlan={healthPlanName}
        />
      </Grid>
      <ProviderFacilityModal
        open={performingProviderPracticeModalOpen}
        modalType="performingProviderPractice"
        patientHealthPlan={healthPlanName ? healthPlanName : undefined}
        modalStatus={modalOp}
        setSelection={async (params: PracticeUpsertPayload, practice: Provider | Facility) => {
          if (modalOp === ModalStatus.Edit) {
            let retVal: Facility = { id: "", dateCreated: "", lastUpdated: "" };
            if (params.type) {
              retVal = await updatePerformingProviderPractice({
                id: practice?.id,
                dateCreated: practice?.dateCreated,
                lastUpdated: practice?.lastUpdated,
                ...params,
              });
            } else if (params.recordType) {
              // params is a ProviderFacilityCatalogue payload
              retVal = await upsertManuallyEnteredProviderOrFacility({
                id: practice?.id,
                npi: params.npi || "",
                tinList: params.tinList || [],
                locations: params.locations || [],
                recordType: params.recordType,
              });
            }
            retVal.type = params.type || params.recordType;
            retVal.selectedLocation = params.selectedLocation;
            setFormContent((prev) => ({
              ...prev,
              selectedPerformingProviderPractice: retVal,
              selectedPerformingProviderPracticeSelectedTIN: retVal.tinList
                ? retVal.tinList[retVal.tinList.length - 1]
                : null,
              selectedPerformingProviderPracticeAddress: retVal.locations
                ? retVal.locations[retVal.locations.length - 1]
                : null,
            }));
          }
        }}
        savedPractice={formContent?.selectedPerformingProviderPractice}
        setSelectedTin={undefined}
        onClose={() => {
          setPerformingProviderPracticeModalOpen(false);
        }}
        onSave={async (facility: PracticeUpsertPayload, setEntry: (practice: Provider | Facility) => void) => {
          if (
            modalOp === ModalStatus.AddAddress ||
            modalOp === ModalStatus.EditAddress ||
            modalOp === ModalStatus.AddTin ||
            modalOp === ModalStatus.EditTin
          ) {
            try {
              if (formContent?.selectedPerformingProviderPractice) {
                const performingProviderPracticeForUpdate = getPerformingProviderPracticeForUpdate(
                  formContent?.selectedPerformingProviderPractice,
                  formContent?.selectedPerformingProviderPracticeSelectedTIN,
                  formContent?.selectedPerformingProviderPracticeAddress,
                  modalOp,
                  facility,
                  hideTinField
                );
                //manual record add/update a tin/address
                if (formContent?.selectedPerformingProviderPractice.manuallyCreated) {
                  if (facility.type) {
                    performingProviderPracticeForUpdate.lastUpdated = new Date().toISOString();
                    await updatePerformingProviderPractice({ ...performingProviderPracticeForUpdate });
                  } else if (facility.recordType) {
                    await upsertManuallyEnteredProviderOrFacility({
                      npi: performingProviderPracticeForUpdate.npi || "",
                      tinList: performingProviderPracticeForUpdate.tinList || [],
                      locations: performingProviderPracticeForUpdate.locations || [],
                      recordType: performingProviderPracticeForUpdate.recordType,
                    });
                  }
                } else {
                  //create a manual record and then switch
                  performingProviderPracticeForUpdate.manuallyCreated = true;
                  performingProviderPracticeForUpdate.createdByUser = false;
                  if (facility.type) {
                    performingProviderPracticeForUpdate.dateCreated = new Date().toISOString();
                    const newfacility = await createPerformingProviderPractice({
                      ...performingProviderPracticeForUpdate,
                    });
                    performingProviderPracticeForUpdate.id = newfacility.id;
                    performingProviderPracticeForUpdate.source = newfacility.source;
                  } else if (facility.recordType) {
                    const newFacility = await upsertManuallyEnteredProviderOrFacility({
                      npi: performingProviderPracticeForUpdate.npi || "",
                      tinList: performingProviderPracticeForUpdate.tinList || [],
                      locations: performingProviderPracticeForUpdate.locations || [],
                      recordType: performingProviderPracticeForUpdate.recordType,
                    });
                    performingProviderPracticeForUpdate.id = newFacility.id;
                    performingProviderPracticeForUpdate.source = newFacility.source;
                  }
                  //switch
                  setFormContent((prev) => ({
                    ...prev,
                    selectedPerformingProviderPractice: performingProviderPracticeForUpdate,
                  }));
                }
                setFormContent((prev) => ({
                  ...prev,
                  selectedPerformingProviderPracticeSelectedTIN:
                    performingProviderPracticeForUpdate.selectedLocation?.tin,
                  selectedPerformingProviderPracticeAddress: performingProviderPracticeForUpdate.selectedLocation,
                  selectedPerformingProviderPractice: performingProviderPracticeForUpdate,
                }));
                setEntry(performingProviderPracticeForUpdate);
              }
            } catch (error) {
              enqueueSnackbar("error occured while updating facility entity", { variant: "error" });
            }
          } else if (modalOp === ModalStatus.Add) {
            let retVal: Provider = { id: "", dateCreated: "", lastUpdated: "" };
            if (facility.type) {
              retVal = await createPerformingProviderPractice({
                ...facility,
              });
            } else if (facility.recordType) {
              retVal = await upsertManuallyEnteredProviderOrFacility({
                npi: facility.npi || "",
                tinList: facility.tinList || [],
                locations: facility.locations || [],
                recordType: facility.recordType,
              });
            }
            retVal.type = facility.type;
            retVal.selectedLocation = facility.selectedLocation;
            setFormContent((prev) => ({
              ...prev,
              selectedPerformingProviderPracticeSelectedTIN: retVal.tinList ? retVal.tinList[0] : null,
              selectedPerformingProviderPracticeAddress: !!facility.locations ? facility.locations[0] : null,
              selectedPerformingProviderPractice: retVal,
            }));
            setEntry(retVal);
          }
        }}
        selectedTin={
          formContent?.selectedPerformingProviderPracticeSelectedTIN
            ? formContent?.selectedPerformingProviderPracticeSelectedTIN
            : undefined
        }
        selectedLocation={
          formContent?.selectedPerformingProviderPracticeAddress ||
          formContent?.selectedPerformingProviderPractice?.selectedLocation ||
          null
        }
        hideTinField={hideTinField && formConfiguration?.performingProviderPracticeSelectedTIN?.fieldSpec !== "OMIT"}
      />
    </>
  );
};

export default PerformingProviderPracticeSelectManual;
