import PatientStayCard from "../FillFormsGeneralAuthSubmission/PatientStayCard";
import { Box, Button, Container, Grid, useTheme, Divider } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Alert,
  Chip,
  InformativeModal,
  InlineButton,
  MAX_DATE_SELECT_DATE,
  colorsLight,
  today,
  useMuiContainerStyles,
} from "@coherehealth/common";
import { validatePatientStayRange } from "util/authorization";
import { CohereCard } from "@coherehealth/design-system";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { PriorAuthRequirements, formContentFromAuthBuilderContext, ServiceRequestFormStateSetters } from "../common";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ServicesAndProceduresCard, {
  ProcedureCodeBucketsState,
} from "../FillFormsGeneralAuthSubmission/ServicesAndProceduresCard";
import {
  Coverage,
  AuthStatus,
  Patient,
  ProcedureCode,
  CardExtensionProcedureCode,
} from "@coherehealth/core-platform-api";
import { ServiceRequestFormContent } from "components/ServiceRequest";
import RequestorCard, { Props as RequestorProps } from "components/Requestor/RequestorCard";
import { useServiceRequestConfigSpec } from "components/ServiceRequest/ConfigurableServiceRequestForm";
import { useServiceRequestFormRedirectDispatch } from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import { checkOnetimeRequestCoverage } from "util/patientUtils";
import ErrorIcon from "@material-ui/icons/Error";
import PriorAuthResultCard from "../GatherRequirements/PriorAuthResultCard";
import { useGetReturnToUrl } from "util/queryParams";
import { Error, OpenInNew } from "@material-ui/icons";
import { ServiceRequestFieldName } from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import listReplace from "util/listReplace";
import * as datefns from "date-fns";
import { MIN_START_DATE, timePattern } from "util/dateUtils";
import { useAuthorized } from "authorization";
import { RequestExpedited } from "components/ServiceRequest/ServiceRequestForm/Expedited";
import DateRangeSelect from "../FillFormsGeneralAuthSubmission/DateRangeSelect";
import { useCalculateDateSelectionMessageDisplay } from "components/AuthBuilder/FillFormsGeneralAuthSubmission/utils";
import NonPalProcedureCodeTableWrapper from "./NonPalProcedureCodeTableWrapper";
import PriorAuthCheckBanner from "./PriorAuthCheckBanner";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";
import { fieldIsValid, SRFormConfigFieldWrapper } from "common/FormConfigUtils";

interface Props extends ProcedureCodeBucketsState, RequestorProps, ServiceRequestFormStateSetters {
  setServiceRequestFormContents: Dispatch<SetStateAction<ServiceRequestFormContent[]>>;
  openNonPalConfirmationModal?: boolean;
  setOpenNonPalConfirmationModal?: Dispatch<SetStateAction<boolean>>;
  setUserSelectedNonPalCode?: (value: boolean) => void;
  priorAuthRequirements: PriorAuthRequirements;
  activeCoverage: Coverage | undefined;
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  attemptedSubmit: boolean;
  patientHealthPlanName: string;
  patient: Patient | null;
  workflowId?: string;
  authStatus: AuthStatus;
  requestorProps?: RequestorProps;
  showRequestorCard?: boolean;
  allowMutablePxCodesWithNonPal?: boolean;
  setServiceDetailsFormsCanBeSubmitted: Dispatch<SetStateAction<boolean[]>>;
  setPriorAuthRequirements: Dispatch<SetStateAction<PriorAuthRequirements>>;
  hideExpeditedRequestCheckbox?: boolean;
  crdProcedureCodeResults: CardExtensionProcedureCode[] | undefined;
  allowedPxCodePerPayer?: number;
}
export default function PatientStayAndServicesCard({
  openNonPalConfirmationModal,
  setOpenNonPalConfirmationModal,
  setUserSelectedNonPalCode,
  priorAuthRequirements,
  activeCoverage,
  formContent,
  setFormContent,
  attemptedSubmit,
  patientHealthPlanName,
  patient,
  workflowId,
  authStatus,
  /** Procedure Code Buckets */
  palProcedureCodes,
  nonPalProcedureCodes,
  nonCohereProcedureCodes,
  setPalProcedureCodes,
  setNonCohereProcedureCodes,
  setNonPalProcedureCodes,
  setServiceRequestFormContents,
  setServiceDetailsFormsCanBeSubmitted,
  requestorProps,
  showRequestorCard,
  setPriorAuthRequirements,
  allowMutablePxCodesWithNonPal,
  hideExpeditedRequestCheckbox,
  crdProcedureCodeResults,
  allowedPxCodePerPayer,
}: Props) {
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName: patientHealthPlanName,
    encounterType: formContent.isInpatient ? "INPATIENT" : "OUTPATIENT",
    skipRequestTimingCheck: true,
  });

  const { formFieldConfigurations } = useServiceRequestConfigSpec({
    ...formContent,
    patientId: patient?.id || "",
    healthPlanName: patientHealthPlanName,
  });

  const hasUnits = Boolean(formContent.units);
  const allPxsHaveUnits = Boolean(formContent.procedureCodes.every((px) => !!px.units));
  const hasPxs = formContent.procedureCodes.length > 0;
  const setCanBeSubmitted = useCallback(
    (canBeSubmitted: boolean) => setServiceDetailsFormsCanBeSubmitted((prev) => listReplace(prev, 0, canBeSubmitted)),
    [setServiceDetailsFormsCanBeSubmitted]
  );

  const showEndDate = Number(formContent.units) !== 1;

  const endDateCoverage = useMemo(
    () =>
      patient?.coverages && formContent?.endDate
        ? checkOnetimeRequestCoverage(patient.coverages, formContent?.endDate || "")
        : undefined,
    [patient?.coverages, formContent?.endDate]
  );

  const startDateCoverage = useMemo(() => {
    if (patient?.coverages) {
      if (formContent?.admissionDate) {
        return checkOnetimeRequestCoverage(patient?.coverages, formContent?.admissionDate);
      } else if (formContent?.startDate) {
        return checkOnetimeRequestCoverage(patient?.coverages, formContent?.startDate);
      }
    }
    return undefined;
  }, [patient?.coverages, formContent?.startDate, formContent?.admissionDate]);
  // StartEndDate restrictions
  const startDateExceedsMinStartDate = formContent.startDate >= MIN_START_DATE;
  const hasStartDate = Boolean(formContent.startDate);
  const hasEndDateIfApplicable = Boolean(formContent.endDate);
  const hasAllowedProcedureCodePerPayer = Boolean(
    allowedPxCodePerPayer && formContent?.procedureCodes.filter((code) => code.groupId).length <= allowedPxCodePerPayer
  );

  const hasValidStartDate = (formContent.startDate ?? MAX_DATE_SELECT_DATE) <= MAX_DATE_SELECT_DATE;
  const hasValidEndDate = (formContent.endDate ?? MAX_DATE_SELECT_DATE) <= MAX_DATE_SELECT_DATE;
  const endDateExceedsStartDate = formContent.endDate ? formContent.endDate >= formContent.startDate : true;
  const validatePatientStayDateRangesEndDate = validatePatientStayRange(formContent.patientStayDateRanges ?? []);
  const hasMinStayDays =
    formContent.patientStayDateRanges?.length && formContent.patientStayDateRanges.length > 0
      ? formContent.patientStayDateRanges[0].rangeEndDate !== null
      : false;
  const hasValidStayDateRange = validatePatientStayDateRangesEndDate.filter((error) => error.isRowInValid)?.length;

  const isAdmissionTimeValid = timePattern.test(formContent?.admissionTime || "");
  const isDischargeTimeValid = timePattern.test(formContent?.dischargeTime || "");

  const hasAdmissionDate =
    Boolean(formContent?.admissionDate) &&
    !datefns.isAfter(new Date(formContent?.admissionDate ?? ""), new Date()) &&
    startDateCoverage?.inRange;

  const hasAdmissionTime = Boolean(formContent?.admissionTime) && isAdmissionTimeValid;
  const hasDischargeTime = Boolean(formContent?.dischargeTime) && isDischargeTimeValid;
  const hasDischargedTo = Boolean(formContent?.dischargedTo);

  const hasDischargeDate =
    formContent?.dischargeDate !== undefined &&
    datefns.isValid(formContent?.dischargeDate) &&
    !datefns.isBefore(new Date(formContent?.dischargeDate ?? ""), new Date(formContent?.admissionDate ?? "")) &&
    !datefns.isAfter(new Date(formContent?.dischargeDate ?? ""), new Date());

  const hasExpectedAdmissionDate =
    Boolean(formContent?.expectedAdmissionDate) &&
    datefns.isValid(formContent?.expectedAdmissionDate) &&
    datefns.isAfter(formContent?.expectedAdmissionDate ?? new Date(), datefns.subDays(new Date(), 1));

  const hasExpectedAdmissionDateForServiceBasedSR = facilityBasedFeatureEnabled
    ? Boolean(formContent?.expectedAdmissionDate) &&
      datefns.isValid(formContent?.expectedAdmissionDate) &&
      startDateCoverage?.inRange
    : Boolean(formContent?.startDate) && datefns.isValid(formContent?.startDate) && startDateCoverage?.inRange;

  const expectedAdmissionDateValueForPlannedAdmission =
    formContent?.admissionDate || formContent?.expectedAdmissionDate;

  const dischargeDate = new Date(formContent?.expectedDischargeDate ?? "");
  const admissionDate = new Date(expectedAdmissionDateValueForPlannedAdmission ?? "");

  const hasExpectedDischargeDate =
    (formContent?.expectedDischargeDate !== undefined &&
      datefns.isValid(formContent?.expectedDischargeDate) &&
      !datefns.isBefore(dischargeDate, admissionDate)) ||
    datefns.isSameDay(dischargeDate, admissionDate);

  const inPatientValidatePx =
    formContent.isInpatient &&
    facilityBasedFeatureEnabled &&
    (!formContent.procedureCodes || formContent.procedureCodes.length === 0);

  const { showExceededDurationLimitMessageErrorState } = useCalculateDateSelectionMessageDisplay(
    formContent,
    patient?.coverages || [],
    patient,
    formFieldConfigurations
  );
  const VALIDATION_MAP: Record<ServiceRequestFieldName, boolean> = {
    expeditedTatUpdateTimestamp: true,
    placeOfService: true,
    orderingProvider: true,
    orderingProviderTIN: true,
    orderingProviderNPI: true,
    orderingProviderAddress: true,
    performingProvider: true,
    performingProviderNPI: true,
    performingProviderTIN: true,
    performingProviderAddress: true,
    facility: true,
    facilityNPI: true,
    facilityTIN: true,
    facilityAddress: true,
    facilityOutOfNetworkExceptionReason: true,
    facilityOutOfNetworkExceptionComment: true,
    performingProviderOutOfNetworkExceptionComment: true,
    performingProviderOutOfNetworkExceptionReason: true,
    orderingProviderOutOfNetworkExceptionReason: true,
    orderingProviderOutOfNetworkExceptionComment: true,
    careParticipantOutOfNetworkExceptionReason: true,
    careParticipantOutOfNetworkExceptionComment: true,
    singleClinicalService: true,
    encounterType: true,
    primaryDiagnosis: true,
    secondaryDiagnoses: true,
    performingProviderPracticeSelectedTIN: true,
    performingProviderPracticeSelectedAddress: true,
    performingProviderPractice: true,
    performingProviderPracticeSelectedNPI: true,
    procedureCodes:
      allowedPxCodePerPayer && allowedPxCodePerPayer > 0
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasAllowedProcedureCodePerPayer)
        : true,
    recurring: true,
    startEndDate: fieldIsValid(
      formContent.isInpatient ? { fieldSpec: "OMIT" } : formFieldConfigurations.startEndDate,
      hasStartDate && hasEndDateIfApplicable,
      startDateExceedsMinStartDate &&
        endDateExceedsStartDate &&
        hasValidStartDate &&
        hasValidEndDate &&
        endDateCoverage?.inRange
    ),
    units: fieldIsValid(formFieldConfigurations?.units, hasUnits && allPxsHaveUnits),
    urgency: true,
    behavioralHealthReviewType: true,
    behavioralHealthAdmissionType: true,
    admissionDischargeDate: true,
    faxInputField: true,
    procedureCodeWithUnits: fieldIsValid(
      inPatientValidatePx ? { fieldSpec: "OMIT" } : formFieldConfigurations.procedureCodes,
      allPxsHaveUnits && hasPxs
    ),
    patientStayDateRanges:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus !== "NOT_YET_ADMITTED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasMinStayDays && !hasValidStayDateRange)
        : fieldIsValid(formFieldConfigurations.patientStayDateRanges, hasMinStayDays && !hasValidStayDateRange),
    authCategory: true,
    authSubCategory: true,
    prescribedDrug: true,
    outOfNetworkCheck: true,
    facilityOutOfNetworkStatusDisplay: true,
    performingProviderOutOfNetworkStatusDisplay: true,
    orderingProviderOutOfNetworkStatusDisplay: true,
    careParticipantOutOfNetworkStatusDisplay: true,
    outOfNetworkOrderingProvider: true,
    userDeclaredOONException: true,
    admissionDate:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus !== "NOT_YET_ADMITTED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasAdmissionDate)
        : fieldIsValid(formFieldConfigurations.admissionDate, hasAdmissionDate),
    admissionTime:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus !== "NOT_YET_ADMITTED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasAdmissionTime)
        : fieldIsValid(formFieldConfigurations.admissionDate, hasAdmissionTime),
    dischargeDate:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus === "DISCHARGED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasDischargeDate)
        : fieldIsValid(formFieldConfigurations.dischargeDate, hasDischargeDate),
    dischargeTime:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus === "DISCHARGED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasDischargeTime)
        : fieldIsValid(formFieldConfigurations.dischargeTime, hasDischargeTime),
    dischargedTo:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus === "DISCHARGED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasDischargedTo)
        : fieldIsValid(formFieldConfigurations.dischargedTo, hasDischargedTo),
    expectedAdmissionDate: facilityBasedFeatureEnabled
      ? formContent?.isInpatient && formContent?.patientStatus === "NOT_YET_ADMITTED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasExpectedAdmissionDate)
        : fieldIsValid(formFieldConfigurations.expectedAdmissionDate, hasExpectedAdmissionDate)
      : formContent?.isInpatient
      ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasExpectedAdmissionDateForServiceBasedSR)
      : fieldIsValid(formFieldConfigurations.expectedAdmissionDate, hasExpectedAdmissionDateForServiceBasedSR),
    expectedDischargeDate:
      !facilityBasedFeatureEnabled && formContent?.isInpatient
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasExpectedDischargeDate)
        : fieldIsValid(formFieldConfigurations.expectedDischargeDate, hasExpectedDischargeDate),
    additionalCareParticipants: true,
    nonPalCheckbox: true,
    blockUserIfExceedsRecommendedEndDate: fieldIsValid(
      formFieldConfigurations.blockUserIfExceedsRecommendedEndDate,
      !showExceededDurationLimitMessageErrorState
    ),
    userSelectedOONException: true,
    performingProviderPracticeOutOfNetworkStatusDisplay: true, // TODO
    performingProviderPracticeOutOfNetworkExceptionReason: true, // TODO
    performingProviderPracticeOutOfNetworkExceptionComment: true, // TODO
  };

  const validateFields = () => {
    const formConfig = formFieldConfigurations;
    if (formConfig) {
      const formConfigKeys = Object.keys(formConfig) as ServiceRequestFieldName[];
      for (const key of formConfigKeys) {
        if (!VALIDATION_MAP[key]) {
          return false;
        }
      }
      return true;
    }
    //should never happen, but if it does we should block the user from trying to submit
    return false;
  };

  const isValid = validateFields();

  const handleFormValidation = useCallback(
    (isFormValid: boolean) => {
      setCanBeSubmitted(isFormValid);
    },
    [setCanBeSubmitted]
  );

  useEffect(() => {
    handleFormValidation(isValid);
  }, [isValid, handleFormValidation]);

  const [showNonCohereFullList, setShowNonCohereFullList] = useState(false);
  const classes = useStyles({ isRotated: showNonCohereFullList });
  const { dispatchRedirectRuleCheck, dispatchUrgencyRuleCheck } = useServiceRequestFormRedirectDispatch({
    workflowId,
    formContent,
    clinicalService: formContent.clinicalServices?.[0],
    healthPlanName: patientHealthPlanName,
    patient,
  });
  const { palette, spacing } = useTheme();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  useEffect(() => {
    setFormContent((prev) => ({
      ...prev,
      userSelectedNonPalCode: priorAuthRequirements?.userSelectedNonPalCode || false,
    }));
  }, [priorAuthRequirements?.userSelectedNonPalCode, setFormContent]);

  const returnToUrl = useGetReturnToUrl();
  const continueGeneralAuthSubmissionFlow = () => {
    if (patient) {
      setServiceRequestFormContents((prev) => [
        formContentFromAuthBuilderContext({
          priorAuthRequirements,
          patient,
          procedureCodes: ProcedureCodesFromPalAndNonPalCodes(
            prev[0].procedureCodes,
            palProcedureCodes,
            nonPalProcedureCodes
          ),
          previousFormContent: prev[0],
          facilityBasedFeatureEnabled,
          clinicalServices: prev[0].clinicalServices,
        }),
      ]);
    }
  };

  const ProcedureCodesFromPalAndNonPalCodes = (
    existingProcedureCodes: ProcedureCode[],
    palProcedureCodes: ProcedureCode[],
    nonPalProcedureCodes: ProcedureCode[]
  ): ProcedureCode[] => {
    const allPxCodes = existingProcedureCodes.filter((pxCode) => {
      return (
        palProcedureCodes.find((palProcedure) => palProcedure.code === pxCode.code) ||
        nonPalProcedureCodes.find((nonPalProcedure) => nonPalProcedure.code === pxCode.code)
      );
    });

    return allPxCodes;
  };

  const confirmNonPalAddition = () => {
    setUserSelectedNonPalCode?.(true);
    setOpenNonPalConfirmationModal?.(false);
    continueGeneralAuthSubmissionFlow();
  };
  const nonCohereServiceCard = () => {
    if (!nonCohereProcedureCodes.length) {
      return;
    }

    return (
      <CohereCard noBoxShadow={isFaxAuthBuilderFlow} darkBorder={isFaxAuthBuilderFlow}>
        <Grid item xs={12} style={{ position: "relative" }}>
          <AlertContainer>
            <Button
              style={{ position: "absolute", top: spacing(1.25), right: spacing(-3) }}
              className={classes.carrotIcon}
              onClick={() => {
                setShowNonCohereFullList(!showNonCohereFullList);
              }}
            >
              <ExpandMoreIcon className={classes.expandIcon} />
            </Button>
            <Alert
              severity="warning"
              message="Requires submission through another organization"
              style={{ background: "transparent" }}
            />
          </AlertContainer>
          {!showNonCohereFullList ? (
            nonCohereProcedureCodes.map((code) =>
              code.procedureCode.map((cpt) => (
                <Chip label={cpt.code} type="warning" style={{ marginLeft: "15px" }} size="small" />
              ))
            )
          ) : (
            <PriorAuthResultCard
              procedureCodes={[]}
              type="AUTH_REQUIRED_BY_VENDOR"
              patient={patient || undefined}
              nonCohereCodes={nonCohereProcedureCodes}
              primaryActiveCoverage={activeCoverage}
              isUsedInPACheckRedesign={true}
            />
          )}
        </Grid>
      </CohereCard>
    );
  };
  const showDateRangeSelectSection =
    palProcedureCodes.length === 0 && priorAuthRequirements.userSelectedNonPalCode && !facilityBasedFeatureEnabled;
  const nonPalServiceCard = () => {
    if (!nonPalProcedureCodes.length) {
      return;
    }
    return (
      <CohereCard
        style={{
          position: "relative",
        }}
        darkBorder={isFaxAuthBuilderFlow}
        noBoxShadow={isFaxAuthBuilderFlow}
      >
        <Grid item xs={12}>
          <PriorAuthResultCard
            procedureCodes={nonPalProcedureCodes}
            type="AUTH_NOT_REQUIRED"
            patient={patient || undefined}
            primaryActiveCoverage={activeCoverage}
            userSelectedNonPalCode={priorAuthRequirements.userSelectedNonPalCode || false}
            nonPalSubmissionIsEnabled={formFieldConfigurations.nonPalCheckbox.fieldSpec !== "OMIT"}
            setOpenNonPalConfirmationModal={setOpenNonPalConfirmationModal}
            setUserSelectedNonPalCode={setUserSelectedNonPalCode}
            isUsedInPACheckRedesign={true}
          >
            <div className={classes.alertContainer}>
              <Alert
                severity="draft"
                icon={<ErrorIcon style={{ marginRight: 0 }} />}
                message="Doesn’t require submission in most cases"
                style={{ background: "transparent", paddingBottom: 0, alignItems: "center" }}
              />
            </div>
          </PriorAuthResultCard>
        </Grid>
        <InformativeModal
          open={cancelModalOpen}
          onClose={() => setCancelModalOpen(false)}
          icon={<HelpOutlineIcon style={{ color: palette.warning.dark }} />}
          headerText="Are you sure you want to cancel this authorization?"
          subHeaderText="Your progress will not be saved."
          primaryButtonText="Yes, cancel"
          primaryButtonRoute={returnToUrl}
          tertiaryButtonText="Continue with authorization"
          tertiaryButtonAction={() => setCancelModalOpen(false)}
        />
        <InformativeModal
          open={openNonPalConfirmationModal || false}
          onClose={() => setOpenNonPalConfirmationModal?.(false)}
          headerText={`${
            nonPalProcedureCodes.length > 1 ? "These codes don't " : "This code doesn’t "
          } usually require submission`}
          subHeaderElement={
            <div className={classes.subHeader}>
              Including {nonPalProcedureCodes.length > 1 ? "them" : "it"} is only necessary if a review is needed to
              ensure coverage (e.g., an organizational determination or a program exception). Otherwise, please continue
              without
              {nonPalProcedureCodes.length > 1 ? " them" : " it"}.
              <InlineButton
                startIcon={<OpenInNew />}
                onClick={() => {
                  window.open("https://coherehealth.zendesk.com/hc/en-us/articles/14198266768279");
                }}
                className={classes.learnMoreButton}
              >
                Learn more
              </InlineButton>
            </div>
          }
          primaryButtonText={`Continue without ${nonPalProcedureCodes.length > 1 ? "these codes" : "this code"}`}
          primaryButtonDisabled={nonPalProcedureCodes.length < 1}
          tertiaryButtonText={`Include ${
            nonPalProcedureCodes.length > 1 ? "these codes" : "this code"
          } (request will pend for review)`}
          primaryButtonAction={() => {
            setOpenNonPalConfirmationModal?.(false);
            setUserSelectedNonPalCode?.(false);
          }}
          tertiaryButtonAction={confirmNonPalAddition}
          icon={<Error className={classes.errorIcon} />}
          iconSize="48px"
        />
        {showDateRangeSelectSection && (
          <DateRangeSelectContainer>
            <DateRangeSelect
              formConfiguration={formFieldConfigurations}
              formContent={formContent}
              setFormContent={setFormContent}
              patient={patient}
              attemptedSubmit={attemptedSubmit}
              showEndDate={showEndDate}
              facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
              hasValidStartDate={hasValidStartDate}
            />
          </DateRangeSelectContainer>
        )}
        {priorAuthRequirements.userSelectedNonPalCode && (
          <>
            <NonPalProcedureCodeTableWrapper
              priorAuthRequirements={priorAuthRequirements}
              setPriorAuthRequirements={setPriorAuthRequirements}
              setNonPalProcedureCodes={setNonPalProcedureCodes}
              attemptedSubmit={attemptedSubmit}
              formContent={formContent}
              setFormContent={setFormContent}
              nonPalProcedureCodes={nonPalProcedureCodes}
            ></NonPalProcedureCodeTableWrapper>
            {(formContent.isInpatient || formContent?.startDate >= today()) && palProcedureCodes.length === 0 && (
              <>
                <Grid item xs={12}>
                  <Divider style={{ marginTop: spacing(1.5) }} />
                </Grid>
                <SRFormConfigFieldWrapper {...formFieldConfigurations.urgency}>
                  <Grid item xs={8}>
                    <div style={{ marginTop: spacing(1.5), marginBottom: spacing(-2) }}>
                      {authStatus === "DRAFT" && (
                        <RequestExpedited
                          isExpedited={formContent?.isExpedited}
                          setIsExpedited={(isExpedited: boolean, reason: string) => {
                            dispatchUrgencyRuleCheck(isExpedited);
                            setFormContent((current) => ({
                              ...current,
                              isExpedited: isExpedited,
                              expeditedReason: reason,
                            }));
                          }}
                          authStatus={authStatus}
                          startDate={formContent?.startDate}
                          patientId={patient?.id}
                          clinicalServiceId={formContent?.clinicalService?.id}
                          patientHealthPlanName={patientHealthPlanName || undefined}
                        />
                      )}
                    </div>
                  </Grid>
                </SRFormConfigFieldWrapper>
              </>
            )}
          </>
        )}
      </CohereCard>
    );
  };

  const containerClasses = useMuiContainerStyles();
  const isFacilityBasedAuth = formContent.isInpatient && facilityBasedFeatureEnabled;
  const hasPalProcedureCodes = palProcedureCodes.length > 0;

  const showAuthRequiredCard = isFacilityBasedAuth || hasPalProcedureCodes;

  const isAuthorizedForRequestorForm = useAuthorized("REQUESTOR_FORM");
  return (
    <Container classes={containerClasses} maxWidth={"lg"}>
      <Box paddingTop={isFaxAuthBuilderFlow ? 3 : 5}>
        {isAuthorizedForRequestorForm && showRequestorCard && requestorProps && <RequestorCard {...requestorProps} />}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PriorAuthCheckBanner
              semanticProcedureCodes={formContent.procedureCodes}
              crdProcedureCodeResults={crdProcedureCodeResults}
            />
            {showAuthRequiredCard && (
              <CohereCard noBoxShadow={isFaxAuthBuilderFlow} darkBorder={isFaxAuthBuilderFlow}>
                {patientHealthPlanName !== "Highmark" && (
                  <AlertContainer>
                    <Alert message="Requires submission through Cohere" style={{ backgroundColor: "transparent" }} />
                  </AlertContainer>
                )}
                {facilityBasedFeatureEnabled && (
                  <PatientStayContainer>
                    <PatientStayCard
                      formContent={formContent}
                      setFormContent={setFormContent}
                      healthPlanName={patientHealthPlanName}
                      attemptedSubmit={attemptedSubmit}
                      timePattern={timePattern}
                      startDateCoverage={startDateCoverage}
                      formConfiguration={formFieldConfigurations}
                      authStatus={authStatus}
                      patient={patient}
                      dispatchUrgencyRuleCheck={dispatchUrgencyRuleCheck}
                      hideExpeditedRequestCheckbox={hideExpeditedRequestCheckbox}
                      disablePatientStatusChange={true}
                      shouldNotWaitForAuth={true}
                      encounterType={priorAuthRequirements?.encounterType}
                    />
                  </PatientStayContainer>
                )}
                {hasPalProcedureCodes && (
                  <ServicesAndProceduresCard
                    formConfiguration={formFieldConfigurations}
                    formContent={formContent}
                    setFormContent={setFormContent}
                    attemptedSubmit={attemptedSubmit}
                    patient={patient}
                    authStatus={authStatus}
                    workflowId={workflowId}
                    showEndDate={showEndDate}
                    dispatchRedirectRuleCheck={dispatchRedirectRuleCheck}
                    dispatchUrgencyRuleCheck={dispatchUrgencyRuleCheck}
                    palProcedureCodes={palProcedureCodes}
                    setPalProcedureCodes={setPalProcedureCodes}
                    nonPalProcedureCodes={nonPalProcedureCodes}
                    setNonPalProcedureCodes={setNonPalProcedureCodes}
                    nonCohereProcedureCodes={nonCohereProcedureCodes}
                    setNonCohereProcedureCodes={setNonCohereProcedureCodes}
                    facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
                    isUsedInPACheckRedesign={true}
                    allowMutablePxCodesWithNonPal={allowMutablePxCodesWithNonPal}
                    hideExpeditedRequestCheckbox={hideExpeditedRequestCheckbox}
                    allowedPxCodePerPayer={allowedPxCodePerPayer}
                  />
                )}
              </CohereCard>
            )}
          </Grid>
          {nonCohereProcedureCodes?.length > 0 && (
            <Grid item xs={12}>
              {nonCohereServiceCard()}
            </Grid>
          )}
          {nonPalProcedureCodes?.length > 0 && (
            <Grid item xs={12}>
              {nonPalServiceCard()}
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
}

type StyleProps = {
  isRotated: boolean;
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AlertContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(-2),
  marginLeft: theme.spacing(-2),
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const DateRangeSelectContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PatientStayContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const useStyles = makeStyles((theme) => ({
  expandIcon: {
    color: theme.palette.primary.main,
    transition: theme.transitions.create("transform"),
    transform: (props: StyleProps) => (props.isRotated ? "rotate(180deg)" : "rotate(0deg)"),
    "&:hover, &:focus": {
      boxShadow: "none",
      backgroundColor: colorsLight.action.hoverBackground,
    },
  },
  carrotIcon: {
    "&:hover, &:focus": {
      boxShadow: "none",
      backgroundColor: "transparent",
    },
  },
  errorIcon: {
    color: theme.palette.warning.dark,
  },
  subHeader: {
    display: "flex",
    flexDirection: "column",
  },
  learnMoreButton: {
    paddingTop: "24px",
  },
  alertContainer: {
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
    marginBottom: theme.spacing(-1),
  },
}));
