import { useContext, useEffect, useState } from "react";
import { UserContext } from "UserContext";
import { useGetServiceRequestEditConfigurationByPayerAndAuthStatus } from "hooks/useGetFeatureConfigurations";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";

export default function useOpsEditDisabled(serviceRequest: Partial<ServiceRequestResponse> | undefined) {
  const { getUser } = useContext(UserContext);
  const [opsGroup, setOpsGroup] = useState("");

  useEffect(() => {
    getUser()?.then((currentUser) => {
      setOpsGroup(currentUser?.opsGroup || "");
    });
  }, [getUser]);

  const { srEditConfig } = useGetServiceRequestEditConfigurationByPayerAndAuthStatus({
    delegatedVendorName: serviceRequest?.delegatedVendor,
    healthPlanName: serviceRequest?.healthPlanName,
    authStatus: serviceRequest?.authStatus,
  });

  const editingDisabled =
    srEditConfig?.disableEditsByOpsGroups && opsGroup && srEditConfig.disableEditsByOpsGroups.includes(opsGroup);

  return editingDisabled;
}
