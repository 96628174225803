import React, { Dispatch, FunctionComponent, SetStateAction, useContext, useEffect, useState } from "react";

import {
  Requestor,
  ServiceRequestResponse,
  Attachment,
  useUpdateServiceRequest,
  Patient,
  WithdrawRequestor,
  AuthStatus,
} from "@coherehealth/core-platform-api";
import { Body2, Card, H4, InlineButton, Tooltip, statusCopy, Body1, HEALTH_HELP_NAME } from "@coherehealth/common";

import { useTheme } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import PencilIcon from "@material-ui/icons/Create";

import { useSnackbar } from "notistack";

import { headerHeight } from "util/StyleConstants";

import { PatientEdit, NoPatientDisplay } from "./PatientInfo";
import { RequestorSection } from "./RequestorSection";
import ServiceRequestFormSection from "./ServiceRequestFormSection";
import ServiceRequestFormReadOnly from "components/PatientSummary/ServiceRequestSummaryCard/ServiceRequestFormReadOnly";

import { canBeWithdrawn, useIsDelegatedToVendor } from "util/serviceRequest";
import { FaxStep } from ".";
import { CohereFaxAttachmentSection, NonCohereFaxAttachmentSection } from "./AttachmentSection";
import WithdrawModal from "components/PatientSummary/ServiceRequestSummaryCard/WithdrawModal";

import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { useAuthorized } from "authorization";
import ClinicalAssessment from "components/AuthBuilder/ClinicalAssessment";
import ClinicalAssessmentSection from "components/PatientSummary/ServiceRequestSummaryCard/ClinicalAssessmentSection";
import usePollForClinicalAssessment from "hooks/usePollForClinicalAssessment";
import { ClinicalAssessmentContext } from "components/ClinicalAssessment";
import WithdrawButton from "components/ServiceRequest/WithdrawButton";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import { ServiceRequestStatusDisplay } from "components/ServiceRequestStatusDisplay/StatusBanner/ServiceRequestStatusDisplay";

interface FaxFlowProps {
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  setCanBeSubmitted: (b: boolean) => void;
  faxId: string;
  step: FaxStep;
  setStep: React.Dispatch<React.SetStateAction<FaxStep>>;
  patient?: Patient;
  attemptedSubmitService: boolean;
  requestor: Requestor | undefined;
  setRequestor: React.Dispatch<React.SetStateAction<Requestor | undefined>>;
  serviceRequest: ServiceRequestResponse | undefined;
  setServiceRequest: React.Dispatch<React.SetStateAction<ServiceRequestResponse | undefined>>;
  setShowFooter: React.Dispatch<React.SetStateAction<boolean>>;
  attachment: Attachment | undefined;
  docType: string;
  setDocType: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  isCohereFaxForm: boolean;
  setAttachment: React.Dispatch<React.SetStateAction<Attachment | undefined>>;
  setUpdateAttachment: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FaxFlow: FunctionComponent<FaxFlowProps> = ({
  formContent,
  setFormContent,
  setCanBeSubmitted,
  faxId,
  step,
  setStep,
  patient,
  attemptedSubmitService,
  requestor,
  setRequestor,
  serviceRequest,
  setServiceRequest,
  setShowFooter,
  attachment,
  docType,
  setDocType,
  setAttachment,
  setUpdateAttachment,
  loading,
  isCohereFaxForm,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { updateClinicalAssessment } = useContext(ClinicalAssessmentContext);
  const requestorFormAuthorized = useAuthorized("REQUESTOR_FORM");
  const [collapseRequestorForm, setCollapseRequestorForm] = useState(false);
  const requestorProps = {
    requestor: requestor,
    setRequestor: setRequestor,
    requestorFormAuthorized: requestorFormAuthorized,
    setCollapseRequestorForm: setCollapseRequestorForm,
    collapseRequestorForm: collapseRequestorForm,
  };
  const isSingleService = !Boolean(serviceRequest?.carePathJourney?.id);
  const isDelegatedToHealthHelp = useIsDelegatedToVendor(serviceRequest ? serviceRequest : null, [HEALTH_HELP_NAME]);
  const hasNoAssessment =
    (isDelegatedToHealthHelp && !serviceRequest?.vendorIdentifier) || (isSingleService && serviceRequest?.palCategory);

  const {
    data: singleServiceClinicalAssessmentData,
    loading: loadingSingleServiceClinicalAssessment,
    error: singleServiceClinicalAssessmentError,
    refetch: getSingleServiceClinicalAssessment,
  } = usePollForClinicalAssessment({
    serviceRequestId: serviceRequest?.id,
    lazy: true,
  });

  useEffect(() => {
    if (singleServiceClinicalAssessmentData && !loadingSingleServiceClinicalAssessment) {
      updateClinicalAssessment({ type: "populate", clinicalAssessment: singleServiceClinicalAssessmentData });
    }
  }, [loadingSingleServiceClinicalAssessment, singleServiceClinicalAssessmentData, updateClinicalAssessment]);

  useEffect(() => {
    // Handle errors
    if (singleServiceClinicalAssessmentError) {
      enqueueSnackbar(`Error loading clinical assessment: ${singleServiceClinicalAssessmentError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, singleServiceClinicalAssessmentError]);

  const onEditAssessment = async () => {
    await getSingleServiceClinicalAssessment({
      pathParams: { id: serviceRequest?.id },
    });
    setStep("CLINICAL_ASSESSMENT");
  };

  return (
    <MainContent>
      <RequestorSection
        serviceRequest={serviceRequest}
        currentStep={step}
        attemptedSubmitService={attemptedSubmitService}
        requestor={requestor}
        setRequestor={setRequestor}
      />
      {step !== "CLINICAL_ASSESSMENT" && (patient ? <PatientEdit patientId={patient?.id!} /> : <NoPatientDisplay />)}
      {patient && (
        <>
          {step === "SAVE_AND_REVIEW" ? (
            <ServiceRequestFormSection
              formContent={formContent}
              setFormContent={setFormContent}
              attemptedSubmitService={attemptedSubmitService}
              setCanBeSubmitted={setCanBeSubmitted}
              patient={patient}
              authStatus={serviceRequest?.authStatus || "DRAFT"}
              isCohereFaxForm={isCohereFaxForm}
            />
          ) : step === "CLINICAL_ASSESSMENT" ? (
            <>
              <H4>Clinical assessment questions</H4>
              <Body1>This information is needed so we can quickly evaluate your request</Body1>
              <ClinicalAssessment
                loadingCarePathClinicalAssessment={false}
                loadingSingleServiceClinicalAssessment={loadingSingleServiceClinicalAssessment}
                hideRequestorCard
                {...requestorProps}
              />
            </>
          ) : (
            <>
              <ServiceRequestSummary
                setStep={setStep}
                serviceRequest={serviceRequest}
                setServiceRequest={setServiceRequest}
                setShowFooter={setShowFooter}
                loading={loading}
              />
            </>
          )}
          {step !== "CLINICAL_ASSESSMENT" && (
            <>
              {isCohereFaxForm ? (
                <>
                  {serviceRequest && (
                    <CohereFaxAttachmentSection
                      step={step}
                      serviceRequest={serviceRequest}
                      docType={docType}
                      setDocType={setDocType}
                      attachment={attachment}
                      setAttachment={setAttachment}
                      loading={loading}
                    />
                  )}
                </>
              ) : (
                <NonCohereFaxAttachmentSection
                  faxId={faxId}
                  docType={docType}
                  setDocType={setDocType}
                  step={step}
                  attachment={attachment}
                  setUpdateAttachment={setUpdateAttachment}
                  loading={loading}
                />
              )}
            </>
          )}
          {step === "SUBMIT_REQUEST" && !hasNoAssessment && serviceRequest && (
            <ClinicalAssessmentCard>
              <ClinicalAssessmentSection
                serviceRequest={serviceRequest}
                showReadOnlyVendorSummary={isDelegatedToHealthHelp}
                onEditAssessment={onEditAssessment}
              />
            </ClinicalAssessmentCard>
          )}
        </>
      )}
    </MainContent>
  );
};

interface SummaryProps {
  setStep: React.Dispatch<React.SetStateAction<FaxStep>>;
  serviceRequest: ServiceRequestResponse | undefined;
  setServiceRequest: React.Dispatch<React.SetStateAction<ServiceRequestResponse | undefined>>;
  setShowFooter: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
}

const ServiceRequestSummary: FunctionComponent<SummaryProps> = ({
  setStep,
  serviceRequest,
  setServiceRequest,
  setShowFooter,
  loading,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: patch, error: withdrawError } = useUpdateServiceRequest({
    id: serviceRequest?.id || "",
  });

  useEffect(() => {
    if (withdrawError) {
      enqueueSnackbar(`Error updating service request: ${withdrawError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, withdrawError]);
  const canEditWithdrawalRequestor = useAuthorized("EDIT_WITHDRAWAL_REQUESTOR");
  const onWithdraw =
    serviceRequest && canBeWithdrawn(serviceRequest)
      ? async (authStatus?: AuthStatus) => {
          const retVal = await patch({
            authStatus: authStatus ? authStatus : "WITHDRAWN",
            withdrawnReason,
            withdrawRequestor:
              serviceRequest?.withdrawRequestorOption && authStatus?.includes("WITHDRAWN")
                ? !canEditWithdrawalRequestor
                  ? "PROVIDER"
                  : withdrawRequestor
                : undefined,
          });
          setServiceRequest(retVal);
          setShowFooter(false);
        }
      : undefined;
  const theme = useTheme();

  const { healthPlanName, encounterType, requestTiming, delegatedVendor } = serviceRequest || {};
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName,
    encounterType,
    requestTiming,
    delegatedVendorName: delegatedVendor,
  });
  const [withdrawnReason, setWithdrawnReason] = useState<string | undefined>(serviceRequest?.withdrawnReason);
  const [withdrawRequestor, setWithdrawRequestor] = useState<WithdrawRequestor | undefined>(
    serviceRequest?.withdrawRequestor
  );
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);

  return (
    <ServiceRequestCard>
      {serviceRequest && (
        <>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ paddingBottom: 16 }}
            spacing={1}
          >
            {serviceRequest.authStatus !== "DRAFT" && (
              <Grid item xs={12}>
                <ServiceRequestStatusDisplay
                  onSubmission={(sr: ServiceRequestResponse) => {
                    setServiceRequest(sr);
                  }}
                  serviceRequest={serviceRequest}
                  actionConfiguration={{}}
                />
              </Grid>
            )}
            <Grid item>
              <H4>Request details</H4>
            </Grid>
            <Grid item>
              <InlineButton
                disabled={false}
                startIcon={<PencilIcon />}
                onClick={() => {
                  setStep("SAVE_AND_REVIEW");
                  setShowFooter(true);
                }}
              >
                <Body2>Edit request</Body2>
              </InlineButton>
              <Tooltip
                title={
                  !canBeWithdrawn(serviceRequest)
                    ? `You cannot withdraw this service request when it has a status of ${
                        statusCopy(serviceRequest?.delegatedVendor, serviceRequest?.healthPlanName)[
                          serviceRequest.authStatus || "PENDING"
                        ]
                      }`
                    : ""
                }
                placement="top"
                arrow
              >
                <div>
                  <WithdrawButton
                    serviceRequest={serviceRequest}
                    onClick={() => {
                      setWithdrawModalOpen(true);
                    }}
                    styles={{ paddingLeft: theme.spacing(4) }}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
          <Divider />
          <ServiceRequestFormReadOnly
            serviceRequest={serviceRequest}
            isFacilityBasedAuth={facilityBasedFeatureEnabled}
          />
          {canBeWithdrawn(serviceRequest) && (
            <WithdrawModal
              open={withdrawModalOpen}
              onClose={() => {
                setWithdrawModalOpen(false);
                setWithdrawnReason("");
              }}
              onWithdraw={onWithdraw}
              withdrawnReason={withdrawnReason}
              setWithdrawnReason={setWithdrawnReason}
              withdrawRequestor={withdrawRequestor}
              setWithdrawRequestor={setWithdrawRequestor}
              submitting={loading}
              withdrawnReasonOptionsList={serviceRequest.reviewOutcomeWithdrawOptions}
              serviceRequestId={serviceRequest.id}
              serviceRequest={serviceRequest}
            />
          )}
          <Divider />
        </>
      )}
    </ServiceRequestCard>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ServiceRequestCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ClinicalAssessmentCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const MainContent = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingTop: theme.spacing(3) + headerHeight,
  /* leave space for footer, (button top/bottom margin/padding + font height + extra spacing */
  paddingBottom: theme.spacing(8) + 20 + theme.spacing(5),
}));
