import { Checkbox, colorsLight, TextField } from "@coherehealth/common";
import { Box } from "@material-ui/core";
import { NextReviewDateSelect } from "components/ServiceRequest/ReviewSection/common/NextReviewDateSelect";
import { ChangeStatusFormState, isLetterDecision } from "../utils";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";

interface ChangeStatusAppendixFormProps {
  formState: ChangeStatusFormState;
  latestServiceRequest: ServiceRequestResponse;
  sendOptionalDecisionLetter: boolean;
  setFormState: (value: React.SetStateAction<ChangeStatusFormState>) => void;
  setSendOptionalDecisionLetters: (value: React.SetStateAction<boolean>) => void;
  showNextReviewDate: boolean;
}

export function ChangeStatusAppendixForm(props: ChangeStatusAppendixFormProps): JSX.Element {
  const {
    formState,
    latestServiceRequest,
    sendOptionalDecisionLetter,
    setFormState,
    setSendOptionalDecisionLetters,
    showNextReviewDate,
  } = props;
  return (
    <>
      <Box mt="32px">
        <TextField
          label="Claims payment note"
          fullWidth
          value={formState.claimsPaymentNote}
          onChangeValue={(newClaimsNote) => {
            setFormState((prevFormState) => ({ ...prevFormState, claimsPaymentNote: newClaimsNote }));
          }}
        />
      </Box>
      {showNextReviewDate && (
        <Box mt="32px">
          <NextReviewDateSelect
            nextReviewDate={formState.nextReviewDate}
            setNextReviewDate={(newDate) => {
              setFormState((prevFormState) => ({ ...prevFormState, nextReviewDate: newDate }));
            }}
          />
        </Box>
      )}
      {latestServiceRequest &&
        latestServiceRequest.isMemberRequestedAuth &&
        formState.authStatus &&
        isLetterDecision(formState.authStatus, formState.pendingReason, formState.tatExtension?.decision) &&
        !formState.peerToPeer?.isPostDenialEligible && (
          <div style={{ paddingTop: "20px" }}>
            <Checkbox
              onChange={() => setSendOptionalDecisionLetters?.(!sendOptionalDecisionLetter)}
              label={
                <span style={{ fontSize: "15px", color: colorsLight.font.secondary, textAlign: "left" }}>
                  Send decision notification to the provider, in addition to the member who submitted the request
                </span>
              }
            ></Checkbox>
          </div>
        )}
    </>
  );
}
