import React, {
  ComponentProps,
  Dispatch,
  HTMLAttributes,
  SetStateAction,
  useCallback,
  useState,
  useEffect,
  useContext,
} from "react";

import {
  H2,
  Modal,
  PrimaryButton,
  SingleSelectDropdown,
  TextField,
  useFeature,
  RadioGroup,
  colorsLight,
  parseDateFromISOString,
  Checkbox,
  formatDateStrWithCustomFormat,
  SingleSelectDropdownCancel,
  useEffectDeepEquality,
  useConfiguration,
} from "@coherehealth/common";
import {
  NurseReview,
  useGetNextNegationStatus,
  AuthStatus,
  ProcedureCode,
  ServiceRequestResponse,
  DenialReason,
  AuthorizationResponse,
  ServiceRequestCommon,
  ServiceRequestUpdatePayload,
  useGetPreviousServiceRequest,
  useGetFirstConsecutiveDecisionServiceRequest,
  useUpdateClinicalReviewChangeRequest,
  OutOfNetworkReview,
} from "@coherehealth/core-platform-api";
import Grid from "@material-ui/core/Grid";
import PartialProcedureCodeUnitsApproval from "components/ServiceRequestStatusDisplay/PartialApproval/PartialProcedureCodeUnitsApproval";
import { withdrawnReasonOptions, isUnplannedInpatientRequest } from "util/serviceRequest";
import { useAuthorized } from "authorization";
import { useLocation } from "react-router-dom";
import {
  ReviewReasonOutcomeOption,
  ExtendedRnReviewOutcome,
  unableToWorkCase,
  helperTextForTATExtension,
} from "../util/QueueManagementReviewUtil";
import UnsolvableCaseModal from "../../../DocumentViewer/FaxAttachment/UnworkableFax/UnsolvableCaseModal";
import { useUpdateServiceCase } from "@coherehealth/qm-api";
import config from "api/config";
import { CREATE_CPP_REVIEW_PARAM } from "util/queryParams";
import {
  claimsReasonLabel,
  FacilityBasedReviewOutcomeReasonOptions,
  ModalMessage,
  reviewOutcomeReasonOptions,
} from "components/ClinicalReview/reviewUtils/utils";
import PatientStays, { deAggregateStayDates } from "components/ServiceRequest/PatientStay/PatientStays";
import usePatientStayDateOnCRR from "components/ServiceRequest/PatientStay/usePatientStayDateOnCRR";
import { PatientStayDateRange } from "common/SharedServiceRequestFormComponents";
import { NextReviewDateSelect, handlePickersModalClick } from "../common/NextReviewDateSelect";
import { denialReasonOptions } from "components/ServiceRequest/ReviewSection/Modals/OutcomeContextCollectionModal";
import { ClinicalReviewContext } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { useSnackbar } from "notistack";
import InpatientNextReviewDateCollection from "../AuditReview/InpatientNextReviewDateCollection";
import NurseReviewSubmissionOutcomes from "./NurseReviewSubmissionOutcomes";
import { useModalStyles } from "../MDReview/mdReviewStyles";
import { getIsMedicaidUnder21 } from "../util/ReviewSectionUtil";
import { ClinicalConversationBanner } from "./ClinicalConversationBanner";
import OutOfNetworkReviewModalForm from "../OutOfNetworkReview/OutOfNetworkReviewModalForm";
import { OutOfNetworkReviewUpdate } from "components/ClinicalReview/reviewUtils/useReviews";
import { ReviewOutcomeOption } from "components/ServiceRequest/ReviewSection/util/QueueManagementReviewUtil";
import EnhancedWithdrawalReasonForm from "components/ServiceRequestStatusDisplay/EnhancedWithdrawalReasonForm/EnhancedWithdrawalReasonForm";

type DenialReasonOption = { id: DenialReason; label: string };

interface Props {
  loading: boolean;
  isOpen: boolean;
  setIsOpen(arg0: boolean): void;
  onSubmit(
    event: React.FormEvent<HTMLFormElement>,
    authorization?: Partial<AuthorizationResponse>,
    serviceRequestUpdates?: Partial<ServiceRequestCommon>,
    addTatExtension?: boolean,
    reviewerCreateContinuationAcknowledgement?: boolean
  ): void;
  displayCaseOutcome?: ExtendedRnReviewOutcome;
  setDisplayCaseOutcome: Dispatch<SetStateAction<ExtendedRnReviewOutcome>>;
  onDiscard: () => void;
  discarding: boolean;
  isSubmissionDisabled: boolean;
  serviceRequest: ServiceRequestResponse;
  initialPatientStayDateRanges?: PatientStayDateRange[];
  approvedUnits: number;
  procedureCodes: ProcedureCode[];
  review: NurseReview;
  setReview: (nurseReviewUpdate: Partial<NurseReview>) => void;
  isDischarged?: boolean;
  facilityBasedFeatureEnabled?: boolean;
  updateSinglePxCodeUnit: (unitsUpdate: string, updatedPxCode: ProcedureCode) => void;
  approvedPxCodesAreInvalid: () => boolean;
  isReadmission: boolean;
  checkEmptyRequestedLoc?: boolean;
  sendOptionalDecisionLetters?: boolean;
  setSendOptionalDecisionLetter?: Dispatch<SetStateAction<boolean>>;
  shouldSubmitEmbeddedOonReview?: boolean;
  disableFinishOonModalButton?: () => boolean;
  oonReview?: OutOfNetworkReview;
  setOonReview?: OutOfNetworkReviewUpdate;
  oonAllowedReviewOutcomes?: ReviewOutcomeOption[];
}

export default function NurseReviewSubmissionModal({
  loading,
  isOpen,
  setIsOpen,
  onSubmit,
  displayCaseOutcome,
  setDisplayCaseOutcome,
  onDiscard,
  discarding,
  isSubmissionDisabled,
  serviceRequest,
  initialPatientStayDateRanges,
  approvedUnits,
  procedureCodes,
  review: {
    reviewType,
    reviewOutcome,
    serviceStatusCode,
    claimsPaymentNote,
    authorizationNote,
    withdrawnReason,
    denialReason,
    withdrawRequestor,
    readmissionReason,
  },
  setReview,
  isDischarged,
  facilityBasedFeatureEnabled,
  updateSinglePxCodeUnit,
  approvedPxCodesAreInvalid,
  isReadmission,
  checkEmptyRequestedLoc,
  sendOptionalDecisionLetters,
  setSendOptionalDecisionLetter,
  disableFinishOonModalButton,
  oonReview,
  setOonReview,
  shouldSubmitEmbeddedOonReview,
  oonAllowedReviewOutcomes,
}: Props) {
  const {
    isMultiCoverageReviewRequired,
    latestChangeRequest,
    latestReviewLeadingToDenial,
    allowedReviewOutcomes,
    patientPrimaryCoverage,
  } = useContext(ClinicalReviewContext);
  const multiCoverageReviews = useFeature("multiCoverageReviews");

  const multiCoverageContent = multiCoverageReviews && isMultiCoverageReviewRequired;
  const { reviewOutcomeWithdrawOptions, withdrawRequestorOption, reconClaim } = serviceRequest;
  const isReconClaim = !!reconClaim;
  const loadClinicalPartialApproval = useFeature("loadClinicalPartialApproval");
  const location = useLocation();
  const readmissionIdentificationEnabled = useFeature("readmissionIdentification");
  const search = new URLSearchParams(location.search);
  const isUnplannedInpatient = isUnplannedInpatientRequest(serviceRequest);
  const caseId = search.get("caseId") || "";
  const isCppCase = search.has(CREATE_CPP_REVIEW_PARAM);
  const { data: previousServiceRequest, refetch: getPreviousServiceRequest } = useGetPreviousServiceRequest({
    id: serviceRequest?.id,
    lazy: true,
  });
  const {
    mutate: updateClinicalReviewChangeRequest,
    loading: clinicalReviewChangeRequestUpdateLoading,
    error: clinicalReviewChangeRequestUpdateError,
  } = useUpdateClinicalReviewChangeRequest({ clinicalReviewChangeRequestId: latestChangeRequest?.id ?? "" });
  const { enqueueSnackbar } = useSnackbar();
  const finishReviewInProgress = loading || clinicalReviewChangeRequestUpdateLoading;
  /**
   * getPreviousSR when facility based request only.
   */
  useEffect(() => {
    let isMounted = true;
    if (facilityBasedFeatureEnabled && isMounted) {
      getPreviousServiceRequest();
    }
    return () => {
      isMounted = false;
    };
  }, [facilityBasedFeatureEnabled, getPreviousServiceRequest]);

  const [firstDeniedDay, setFirstDeniedDay] = useState<string>();
  const [recommendedOutcome, setRecommendedOutcome] = useState<
    "RECOMMENDED_PARTIAL_APPROVAL" | "RECOMMENDED_DENIAL" | "RECOMMENDED_APPROVAL"
  >();

  const { refetch: getFirstConsecutiveDecisionServiceRequest } = useGetFirstConsecutiveDecisionServiceRequest({
    id: previousServiceRequest?.id || "",
    lazy: true,
  });

  useEffect(() => {
    let isMounted = true;
    const fetchFetchConsecutiveDecisionSR = async () => {
      const firstConsecutiveDecisionSr = await getFirstConsecutiveDecisionServiceRequest();
      if (isMounted) {
        setFirstDeniedDay(formatDateStrWithCustomFormat(firstConsecutiveDecisionSr?.decisionTimestamp, "MM/dd/yyyy"));
      }
    };
    if (previousServiceRequest?.id) {
      fetchFetchConsecutiveDecisionSR();
    }
    return () => {
      isMounted = false;
    };
  }, [getFirstConsecutiveDecisionServiceRequest, previousServiceRequest?.id]);

  const canViewAllWithdrawnReasons = useAuthorized("VIEW_ALL_WITHDRAWAL_REASONS");
  const canViewEnhancedWithdrawalModal = useAuthorized("VIEW_ENHANCED_WITHDRAWAL_MODAL");
  const [unworkableCaseModalOpen, setUnworkableModalOpen] = useState<boolean>(false);
  const [nextState, setNextState] = useState<AuthStatus>();
  const [OOSCheck, setOOSCheck] = useState<boolean>(false);
  const [nrdAcknowledged, setNrdAcknowledged] = useState<boolean>(false);

  const { mutate: updateServiceCase, loading: updateServiceCaseLoading } = useUpdateServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
  });

  const { mutate: nextStatus } = useGetNextNegationStatus({
    id: serviceRequest?.id || "",
  });

  useEffect(() => {
    if (reviewOutcome && reviewOutcome === "WITHDRAWN" && withdrawnReason) {
      nextStatus({ withdrawnReason }).then((result) => {
        setNextState(result);
      });
    }
  }, [nextStatus, reviewOutcome, withdrawnReason]);

  const [TATExtensionDecision, setTATExtensionDecision] = useState<"YES" | "NO">("NO");
  const [nextReviewDate, setNextReviewDate] = useState<Date | null>(null);
  const [initialStayDateRanges, setInitialStayDateRanges] = useState<PatientStayDateRange[]>();

  useEffect(() => {
    if (
      (displayCaseOutcome === "PENDING_MD_REVIEW" && recommendedOutcome === "RECOMMENDED_PARTIAL_APPROVAL") ||
      displayCaseOutcome === "ADMINISTRATIVE_PARTIAL_APPROVAL"
    ) {
      setInitialStayDateRanges(initialPatientStayDateRanges);
    } else {
      setInitialStayDateRanges(undefined);
    }
  }, [displayCaseOutcome, initialPatientStayDateRanges, recommendedOutcome]);

  const isEligibleForTATExtension = serviceRequest.turnAroundTimeExtensionEligible;
  const isGHPServiceRequest = serviceRequest.healthPlanName === "Geisinger";
  // changes for Decisioned stay dates
  const {
    patientStayDateRanges,
    setPatientStayDateRanges,
    patientStaysErrorStates,
    isStayDatesValid,
    isPatientStayDatesError,
  } = usePatientStayDateOnCRR({
    version: "DecisionStay",
    serviceStartDate: serviceRequest.startDate,
    initialStayDateRanges: initialStayDateRanges,
    checkEmptyRequestedLoc: checkEmptyRequestedLoc,
  });
  useEffect(() => {
    if (
      reviewOutcome &&
      reviewOutcome === "PENDING_MISSING_CLINICAL_INFO" &&
      serviceRequest?.turnAroundTimeExtension &&
      !isEligibleForTATExtension
    ) {
      setTATExtensionDecision("YES");
    }
  }, [isEligibleForTATExtension, reviewOutcome, serviceRequest?.turnAroundTimeExtension]);

  useEffectDeepEquality(() => {
    if (serviceRequest.encounterType === "INPATIENT") {
      if (initialPatientStayDateRanges?.length === 0 && isDischarged) {
        setReview({ reviewOutcome: "WITHDRAWN" });
        setDisplayCaseOutcome("WITHDRAWN");
      } else if (initialPatientStayDateRanges?.length === 0 && !isDischarged) {
        setDisplayCaseOutcome(undefined);
        setReview({ reviewOutcome: undefined });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDischarged, serviceRequest.encounterType, initialPatientStayDateRanges]);

  const isInpatientRequest = serviceRequest.encounterType === "INPATIENT";
  const showClaimsPaymentNotes =
    isGHPServiceRequest &&
    ((isInpatientRequest && reviewOutcome) ||
      (reviewOutcome === "APPROVED" && ["TF00", "6?00", "D900"].includes(serviceStatusCode || "")));

  const isClaimsPaymentNotesRequired =
    !isInpatientRequest && ["TF00", "D900"].includes(serviceStatusCode || "") && !Boolean(claimsPaymentNote);

  const showNextReviewDate =
    (facilityBasedFeatureEnabled && reviewOutcome === "PENDING_MISSING_CLINICAL_INFO") ||
    (facilityBasedFeatureEnabled && isDischarged && reviewOutcome === "PENDING_MISSING_CLINICAL_INFO") ||
    (facilityBasedFeatureEnabled &&
      !isDischarged &&
      ["APPROVED", "DENIED", "PENDING_MD_REVIEW", "PENDING_MISSING_CLINICAL_INFO"].includes(reviewOutcome || ""));
  const isNrdRequired =
    showNextReviewDate &&
    (reviewOutcome !== "APPROVED" ||
      (reviewOutcome === "APPROVED" && serviceRequest.patientStatus === "CURRENTLY_ADMITTED"));

  const isPendToMdRecommendPartialApprovalCompleted = () => {
    return (
      patientStayDateRanges.filter((psdr) => psdr.reviewStatus !== "PENDING").length === patientStayDateRanges.length &&
      (!isNrdRequired || nextReviewDate !== null)
    );
  };
  const isAdminPartialReviewCompleted = () => {
    return (
      Boolean(denialReason) &&
      patientStayDateRanges.filter((psdr) => psdr.reviewStatus !== "PENDING").length === patientStayDateRanges.length
    );
  };

  const onWithdrawnReasonChange = (reason: string) => {
    setReview({ withdrawnReason: reason });
  };

  const displayDenialReason = denialReason || "";

  const reviewOutcomeSubmenuSelectionIsInvalid =
    !multiCoverageContent &&
    serviceRequest.healthPlanName === "HealthPartners" && //TODO: config?
    !Boolean(serviceStatusCode) &&
    Boolean(allowedReviewOutcomes?.find((outcome) => outcome.id === reviewOutcome)?.submenu);

  const disableFinishReviewButton = () => {
    if (loading) {
      return true;
    } else if (!Boolean(reviewOutcome) && displayCaseOutcome !== "CANNOT_WORK") {
      return true;
    } else if (reviewOutcome === "WITHDRAWN" && !Boolean(withdrawnReason)) {
      return true;
    } else if (
      reviewOutcome === "WITHDRAWN" &&
      Boolean(withdrawnReason) &&
      nextState?.includes("WITHDRAWN") &&
      withdrawRequestorOption &&
      !withdrawRequestor
    ) {
      return true;
    } else if (
      loadClinicalPartialApproval &&
      reviewOutcome === "PENDING_MD_REVIEW" &&
      recommendedOutcome === "RECOMMENDED_PARTIAL_APPROVAL"
    ) {
      return approvedPxCodesAreInvalid() || !isPendToMdRecommendPartialApprovalCompleted();
    } else if (reviewOutcome === "OUT_OF_SCOPE" && !OOSCheck) {
      return true;
    } else if (reviewOutcome === "ADMINISTRATIVE_DENIAL" && !Boolean(denialReason)) {
      return true;
    } else if (reviewOutcome === "ADMINISTRATIVE_PARTIAL_APPROVAL" && !isAdminPartialReviewCompleted()) {
      return true;
    } else if (isClaimsPaymentNotesRequired || isPatientStayDatesError || isSubmissionDisabled) {
      return true;
    } else if (readmissionIdentificationEnabled && isReadmission && !readmissionReason) {
      return true;
    } else if ((isNrdRequired || nrdAcknowledged) && nextReviewDate === null) {
      return true;
    } else if (reviewOutcomeSubmenuSelectionIsInvalid) {
      return true;
    } else if (shouldSubmitEmbeddedOonReview && disableFinishOonModalButton) {
      return disableFinishOonModalButton();
    }

    return false;
  };

  if (clinicalReviewChangeRequestUpdateError) {
    enqueueSnackbar(
      `Error updating the Clinical Review Change Request status: ${clinicalReviewChangeRequestUpdateError.message}`,
      { variant: "error" }
    );
  }

  const handleSubmit: HTMLAttributes<HTMLFormElement>["onSubmit"] = async (e) => {
    e.preventDefault();

    if (facilityBasedFeatureEnabled && isUnplannedInpatient && !isStayDatesValid()) {
      // patient stay dates information is invalid so not let them submit the review.
      e.stopPropagation();
      return;
    }

    if (displayCaseOutcome === "CANNOT_WORK") {
      onDiscard();
      updateUnworkableCase();
      setUnworkableModalOpen(true);
    } else {
      const addTatExtension = TATExtensionDecision === "YES" && !serviceRequest?.turnAroundTimeExtension;
      const updatesToAuthorization: AuthorizationResponse = {
        nextReviewDate: nextReviewDate?.toISOString(),
      };
      const stayDateRanges =
        (displayCaseOutcome === "PENDING_MD_REVIEW" && recommendedOutcome === "RECOMMENDED_PARTIAL_APPROVAL") ||
        displayCaseOutcome === "ADMINISTRATIVE_PARTIAL_APPROVAL"
          ? patientStayDateRanges
          : initialPatientStayDateRanges;
      const stayDates = stayDateRanges ? deAggregateStayDates(stayDateRanges) : undefined;
      const updatesToServiceRequest: ServiceRequestUpdatePayload = {
        patientStayDates: stayDates,
      };
      onSubmit(
        e,
        showNextReviewDate ? updatesToAuthorization : undefined,
        updatesToServiceRequest,
        addTatExtension,
        nrdAcknowledged
      );
    }

    if (latestChangeRequest?.id && latestReviewLeadingToDenial?.id) {
      updateClinicalReviewChangeRequest({
        resolved: true,
      });
    }
  };

  const updateUnworkableCase = useCallback(async () => {
    updateServiceCase(unableToWorkCase());
  }, [updateServiceCase]);

  const withdrawnReasonSelectOptions = () => {
    return (
      reviewOutcomeWithdrawOptions ||
      withdrawnReasonOptions(canViewAllWithdrawnReasons, serviceRequest?.id, isReconClaim)
    );
  };

  const nrdComponent = !showNextReviewDate ? null : isNrdRequired ? (
    <Row style={{ paddingTop: 20 }}>
      <NextReviewDateSelect nextReviewDate={nextReviewDate} setNextReviewDate={setNextReviewDate} />
    </Row>
  ) : (
    <InpatientNextReviewDateCollection
      nrdAcknowledged={nrdAcknowledged}
      setNrdAcknowledged={setNrdAcknowledged}
      nextReviewDate={nextReviewDate}
      setNextReviewDate={setNextReviewDate}
      fromNurseReviewSubmissionModal={true}
    />
  );

  const isPendingMdReviewOutcome = reviewOutcome === "PENDING_MD_REVIEW";

  const isClinicalConversation = isPendingMdReviewOutcome && !!reviewType;

  const isGhpRequest = serviceRequest.healthPlanName === "Geisinger";

  const isMedicaidUnder21 = getIsMedicaidUnder21(serviceRequest.patient, patientPrimaryCoverage);

  const medicaidUnder21ClinicalConversations = useFeature("medicaidUnder21ClinicalConversations");

  const reviewerWithdrawalModalEnhanced = useFeature("reviewerWithdrawalModalEnhanced");

  const modalStyles = useModalStyles({ partialApprovalSelected: false });

  const handleAsClinicalConversations =
    isClinicalConversation && isGhpRequest && isMedicaidUnder21 && medicaidUnder21ClinicalConversations;
  const oonReviewConfig = useConfiguration("outOfNetworkReviewConfiguration", serviceRequest?.healthPlanName);
  const canSeeReviewerWithdrawalModalEnhanced = canViewEnhancedWithdrawalModal && reviewerWithdrawalModalEnhanced;

  const getModalWidth = () => {
    const reviewOutcomesForLargeModal: ExtendedRnReviewOutcome[] = [
      "ADMINISTRATIVE_PARTIAL_APPROVAL",
      "PENDING_MD_REVIEW",
      "OUT_OF_SCOPE",
    ];
    if (reviewOutcomesForLargeModal.includes(displayCaseOutcome)) {
      return "lg";
    }
    if (reviewOutcome === "APPROVED" && facilityBasedFeatureEnabled && isUnplannedInpatient) {
      return "lg";
    }
    if (reviewOutcome === "WITHDRAWN" && canSeeReviewerWithdrawalModalEnhanced) {
      return "lg";
    }
    return undefined;
  };

  return (
    <>
      <Modal
        open={isOpen}
        maxWidth={getModalWidth()}
        onClose={() => {
          setIsOpen(false);
        }}
        onClick={handlePickersModalClick}
      >
        <Grid
          container
          data-testid="nurse-review-submission-modal-container"
          spacing={4}
          component="form"
          onSubmit={handleSubmit}
          style={{ maxWidth: reviewOutcome === "WITHDRAWN" ? 864 : 1120 }}
        >
          <Row>
            <H2>Select review outcome</H2>
          </Row>

          {handleAsClinicalConversations && (
            <Row className={modalStyles.clinicalConvoRow}>
              <ClinicalConversationBanner />
            </Row>
          )}
          {!multiCoverageContent && (
            <>
              <Row>
                <NurseReviewSubmissionOutcomes
                  serviceRequest={serviceRequest}
                  previousServiceRequest={previousServiceRequest}
                  firstDeniedDay={firstDeniedDay}
                  caseId={caseId}
                  allowedReviewOutcomes={allowedReviewOutcomes}
                  setDisplayCaseOutcome={setDisplayCaseOutcome}
                  setReview={setReview}
                  setRecommendedOutcome={setRecommendedOutcome}
                  displayCaseOutcome={displayCaseOutcome}
                  isCppCase={isCppCase}
                  isUnplannedInpatient={isUnplannedInpatient}
                  facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
                />
              </Row>
            </>
          )}
          {loadClinicalPartialApproval && reviewOutcome === "PENDING_MD_REVIEW" && (
            <Row style={{ textAlign: "start" }}>
              <RadioGroup
                options={
                  readmissionIdentificationEnabled && serviceRequest.isReadmission
                    ? [
                        { id: "RECOMMENDED_DENIAL", label: "Recommended Denial and Readmission" },
                        { id: "RECOMMENDED_PARTIAL_APPROVAL", label: "Recommended Partial Approval and Readmission" },
                        { id: "RECOMMENDED_APPROVAL", label: "Recommended Approval and Readmission" },
                      ]
                    : [
                        { id: "RECOMMENDED_DENIAL", label: "Recommended Denial" },
                        { id: "RECOMMENDED_PARTIAL_APPROVAL", label: "Recommended Partial Approval" },
                      ]
                }
                value={recommendedOutcome || "RECOMMENDED_DENIAL"}
                onChange={(opt: "RECOMMENDED_PARTIAL_APPROVAL" | "RECOMMENDED_DENIAL" | "RECOMMENDED_APPROVAL") => {
                  setRecommendedOutcome(opt);
                }}
              />
            </Row>
          )}
          {readmissionIdentificationEnabled && isReadmission && (
            <Row style={{ textAlign: "start" }}>
              <RadioGroup
                row
                label={"Readmission reason"}
                options={[
                  { id: "RELATED", label: "Related" },
                  { id: "COMPLICATION", label: "Complication" },
                ]}
                value={readmissionReason || ""}
                onChange={(opt) => {
                  if (opt === "RELATED" || opt === "COMPLICATION") {
                    setReview({
                      readmissionReason: opt,
                    });
                  }
                }}
              />
            </Row>
          )}
          {recommendedOutcome === "RECOMMENDED_PARTIAL_APPROVAL" && procedureCodes && procedureCodes.length > 0 && (
            <Row>
              <PartialProcedureCodeUnitsApproval
                requestedProcedureCodes={procedureCodes}
                approvedUnits={approvedUnits}
                serviceLevelUnits={false}
                updateSinglePxCodeUnit={updateSinglePxCodeUnit}
                healthPlanName={serviceRequest.healthPlanName || ""}
              />
            </Row>
          )}
          {recommendedOutcome === "RECOMMENDED_PARTIAL_APPROVAL" &&
            reviewOutcome === "PENDING_MD_REVIEW" &&
            facilityBasedFeatureEnabled &&
            patientStayDateRanges &&
            setPatientStayDateRanges &&
            isUnplannedInpatient && (
              <Row style={{ textAlign: "start" }}>
                <PatientStays
                  version="DecisionStay"
                  minimumFromDate={parseDateFromISOString(serviceRequest.startDate)}
                  admissionDate={parseDateFromISOString(serviceRequest.admissionDate)}
                  healthPlanName={serviceRequest.healthPlanName || ""}
                  patientStayDateRanges={patientStayDateRanges}
                  setPatientStayDateRanges={setPatientStayDateRanges}
                  patientStaysErrorStates={patientStaysErrorStates}
                  isDischarged={isDischarged}
                  attemptedSubmit={true}
                />
              </Row>
            )}
          {reviewOutcome === "ADMINISTRATIVE_PARTIAL_APPROVAL" &&
            facilityBasedFeatureEnabled &&
            patientStayDateRanges &&
            setPatientStayDateRanges &&
            isUnplannedInpatient && (
              <Row style={{ textAlign: "start" }}>
                <PatientStays
                  version="DecisionStay"
                  minimumFromDate={parseDateFromISOString(serviceRequest.startDate)}
                  admissionDate={parseDateFromISOString(serviceRequest.admissionDate)}
                  healthPlanName={serviceRequest.healthPlanName || ""}
                  patientStayDateRanges={patientStayDateRanges}
                  setPatientStayDateRanges={setPatientStayDateRanges}
                  patientStaysErrorStates={patientStaysErrorStates}
                  isDischarged={isDischarged}
                  isRnPartialAdminApproval={true}
                  attemptedSubmit={true}
                />
              </Row>
            )}
          {reviewOutcome === "WITHDRAWN" && canSeeReviewerWithdrawalModalEnhanced && (
            <>
              <Row style={{ paddingTop: 0 }}>
                <EnhancedWithdrawalReasonForm
                  value={withdrawnReason}
                  onChange={onWithdrawnReasonChange}
                  serviceRequest={serviceRequest}
                />
              </Row>
              {withdrawnReason && (
                <Row style={{ textAlign: "start", paddingTop: 0, color: colorsLight.font.main }}>
                  <ModalMessage state={nextState} />
                </Row>
              )}
            </>
          )}
          {reviewOutcome === "WITHDRAWN" && !canSeeReviewerWithdrawalModalEnhanced && (
            <>
              <Row style={{ paddingTop: 0 }}>
                <SingleSelectDropdown
                  fullWidth
                  label="Select reason for withdrawal"
                  options={withdrawnReasonSelectOptions()}
                  value={withdrawnReason || ""}
                  onChange={onWithdrawnReasonChange}
                  maxMenuHeight={200}
                  menuWidth={470}
                />
              </Row>
              {withdrawnReason && (
                <>
                  {withdrawRequestorOption && nextState?.includes("WITHDRAWN") && (
                    <Row style={{ textAlign: "start", paddingTop: 0 }}>
                      <RadioGroup
                        label="Select who requested the withdrawal"
                        options={[
                          { id: "PATIENT", label: "Patient" },
                          { id: "PROVIDER", label: "Provider" },
                        ]}
                        value={withdrawRequestor}
                        onChange={(withdrawRequestor) => setReview({ withdrawRequestor })}
                      />
                    </Row>
                  )}
                  <Row style={{ textAlign: "start", paddingTop: 0, color: colorsLight.font.main }}>
                    <ModalMessage state={nextState} />
                  </Row>
                </>
              )}
            </>
          )}
          {reviewOutcome &&
            !isCppCase &&
            ![
              "CANNOT_WORK",
              "APPROVED",
              "ADMINISTRATIVE_DENIAL",
              "DENIED",
              "WITHDRAWN",
              "OUT_OF_NETWORK_REVIEW",
            ].includes(reviewOutcome) && (
              <Row>
                <TextField
                  label={NoteLabel[reviewOutcome || ""]}
                  fullWidth
                  hiddenLabel
                  multiline
                  value={authorizationNote}
                  onChangeValue={(authorizationNote) => setReview({ authorizationNote })}
                />
              </Row>
            )}
          {reviewOutcome === "APPROVED" && !isGHPServiceRequest && (
            <Row>
              <TextField
                label={NoteLabel[reviewOutcome || ""]}
                fullWidth
                hiddenLabel
                multiline
                onChangeValue={(authorizationNote) => setReview({ authorizationNote })}
              />
            </Row>
          )}
          {!multiCoverageContent && allowedReviewOutcomes?.find((outcome) => outcome.id === reviewOutcome)?.submenu && (
            <Row>
              <SingleSelectDropdownCancel
                label={`${allowedReviewOutcomes?.find((outcome) => outcome.id === reviewOutcome)?.label || ""} reason`}
                options={allowedReviewOutcomes?.find((outcome) => outcome.id === reviewOutcome)?.submenu}
                value={serviceStatusCode || ""}
                onChange={(opt) => {
                  setReview({
                    serviceStatusCode: opt,
                    serviceStatusLabel: allowedReviewOutcomes
                      ?.find((outcome) => outcome.id === reviewOutcome)
                      ?.submenu?.find((item) => item.id === opt)?.label,
                  });
                }}
                canCancel={!!serviceStatusCode}
                onCancel={() => setReview({ serviceStatusCode: undefined })}
              />
            </Row>
          )}
          {reviewOutcome === "APPROVED" && isGHPServiceRequest && !allowedReviewOutcomes?.length && (
            <Row>
              <SingleSelectDropdownCancel
                label={ReasonLabel[reviewOutcome || ""]}
                options={
                  facilityBasedFeatureEnabled ? FacilityBasedReviewOutcomeReasonOptions : reviewOutcomeReasonOptions
                }
                value={serviceStatusCode || ""}
                onChange={(opt) => {
                  setReview({ serviceStatusCode: opt });
                }}
                canCancel={!!serviceStatusCode}
                onCancel={() => setReview({ serviceStatusCode: undefined })}
              />
            </Row>
          )}
          {showClaimsPaymentNotes && (
            <Row>
              <TextField
                label={claimsReasonLabel(serviceStatusCode || "", isClaimsPaymentNotesRequired)}
                fullWidth
                hiddenLabel
                multiline
                value={claimsPaymentNote}
                onChangeValue={(claimsPaymentNote) => setReview({ claimsPaymentNote })}
              />
            </Row>
          )}
          {reviewOutcome === "PENDING_MISSING_CLINICAL_INFO" && isGHPServiceRequest && (
            <Row style={{ paddingTop: 20 }}>
              <RadioGroup
                row
                label="Do you want to extend the due date of this request?"
                options={[
                  { id: "YES", label: "Yes" },
                  { id: "NO", label: "No" },
                ]}
                disabled={!isEligibleForTATExtension}
                helperText={helperTextForTATExtension(TATExtensionDecision, isEligibleForTATExtension, serviceRequest)}
                value={isEligibleForTATExtension ? TATExtensionDecision : "NO"}
                onChange={(opt) => {
                  setTATExtensionDecision(opt);
                }}
              />
            </Row>
          )}
          {["ADMINISTRATIVE_DENIAL", "ADMINISTRATIVE_PARTIAL_APPROVAL"].includes(reviewOutcome || "") && (
            <Row style={{ paddingTop: 0 }}>
              <SingleSelectDropdown<DenialReasonOption>
                fullWidth
                label="Denial reason"
                options={DenialReasonOptions}
                value={displayDenialReason}
                onChange={(reason) =>
                  setReview({ denialReason: DenialReasonOptions.filter((option) => option.id === reason)[0].id })
                }
                maxMenuHeight={200}
                menuWidth={470}
              />
            </Row>
          )}
          {reviewOutcome === "DENIED" && !allowedReviewOutcomes?.length && (
            <Row style={{ paddingTop: 0 }}>
              <SingleSelectDropdown<ReviewReasonOutcomeOption>
                fullWidth
                label="Denial reason"
                options={denialReasonOptions as ReviewReasonOutcomeOption[]}
                value={serviceStatusCode || ""}
                onChange={(serviceStatusCode: string) =>
                  setReview({
                    serviceStatusCode: denialReasonOptions.filter((option) => option.id === serviceStatusCode)[0].id,
                  })
                }
                maxMenuHeight={200}
                menuWidth={470}
              />
            </Row>
          )}
          {reviewOutcome === "OUT_OF_SCOPE" && (
            <Row style={{ padding: "5px 0 0 0", textAlign: "center" }}>
              <Checkbox
                checked={OOSCheck}
                onChange={setOOSCheck}
                label={
                  <span style={{ fontSize: "15px", color: colorsLight.font.secondary }}>
                    I confirm that this case is OOS and there is more than 50% of TAT remaining
                  </span>
                }
              ></Checkbox>
            </Row>
          )}
          {serviceRequest &&
            serviceRequest.isMemberRequestedAuth &&
            isLetterDecision(reviewOutcome, TATExtensionDecision) && (
              <div style={{ paddingLeft: "15px" }}>
                <Checkbox
                  onChange={() => setSendOptionalDecisionLetter?.(!sendOptionalDecisionLetters)}
                  label={
                    <span style={{ fontSize: "15px", color: colorsLight.font.secondary, textAlign: "left" }}>
                      Send decision notification to the provider, in addition to the member who submitted the request
                    </span>
                  }
                ></Checkbox>
              </div>
            )}

          {showNextReviewDate && nrdComponent}

          {shouldSubmitEmbeddedOonReview && (
            <OutOfNetworkReviewModalForm
              reviewOutcome={oonReview?.reviewOutcome}
              allowedReviewOutcomes={oonAllowedReviewOutcomes || []}
              setReview={setOonReview}
              oonReviewConfig={oonReviewConfig}
              outcomeReason={oonReview?.outcomeReason}
            />
          )}

          <Row>
            <PrimaryButton
              data-testid="clinical-review-submission-modal-button-finish-review"
              loading={finishReviewInProgress}
              disabled={disableFinishReviewButton()}
              type="submit"
            >
              Finish review
            </PrimaryButton>
          </Row>
        </Grid>
      </Modal>
      <UnsolvableCaseModal
        open={unworkableCaseModalOpen}
        setOpen={setUnworkableModalOpen}
        buttonsLoading={updateServiceCaseLoading || discarding}
      />
    </>
  );
}

const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={12} {...props} />;

const isLetterDecision = (reviewOutcome: string | undefined, TATExtensionDecision: "YES" | "NO"): boolean => {
  switch (reviewOutcome) {
    case "APPROVED":
    case "PENDING_MD_REVIEW":
    case "WITHDRAWN":
    case "ADMINISTRATIVE_PARTIAL_APPROVAL":
    case "ADMINISTRATIVE_DENIAL":
    case "DENIED":
    case "PARTIALLY_APPROVED":
      return true;
    case "PENDING_MISSING_CLINICAL_INFO":
      return TATExtensionDecision === "YES";
    default:
      return false;
  }
};

const NoteLabel: Record<string, string> = {
  APPROVED: "Approval Notes",
  ADMINISTRATIVE_PARTIAL_APPROVAL: "Approval Notes",
  WITHDRAWN: "Explanation for Withdrawal",
  PENDING_RN_REVIEW: "Reason for Pend",
  PENDING_MD_REVIEW: "Reason for Pend",
  PENDING_MISSING_CLINICAL_INFO: "Provide instructions for the info intake needs to gather",
  PENDING_NUDGE_OUTREACH: "Provide instructions for intake to make the nudge attempt",
  PENDING_ADMIN_VOID: "Reason for admin void",
  OUT_OF_SCOPE: "Out of scope notes",
};

const DenialReasonOptions: DenialReasonOption[] = [
  { id: "EXPERIMENTAL_INVESTIGATIONAL", label: "Experimental / Investigational" },
  { id: "BENEFIT_LIMITATION", label: "Benefit Limitation" },
  { id: "CONTRACTUAL", label: "Contractual" },
];

const ReasonLabel: Record<string, string> = {
  APPROVED: "Approval Reason",
};
