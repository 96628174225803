import {
  Body1,
  Body2,
  H2,
  Modal,
  TextField,
  Checkbox,
  PrimaryButton,
  SingleSelectDropdown,
  colorsLight,
  parseDateFromISOString,
  useFeature,
  useEffectDeepEquality,
  DropdownOption,
} from "@coherehealth/common";
import { Grid, useTheme } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { ModalMessage, isPostDenialP2P } from "components/ClinicalReview/reviewUtils/utils";
import { ReviewOutcomeDropdown } from "./ModalFields/components/ReviewOutcomeDropdown";
import { RadioButtons } from "./ModalFields/components/RadioButtons";
import {
  AuthStatus,
  ServiceRequestUpdatePayload,
  useGetNextNegationStatus,
  useUpdateClinicalReviewChangeRequest,
} from "@coherehealth/core-platform-api";
import config from "api/config";
import { HTMLAttributes, useCallback, useContext, useEffect, useState } from "react";
import usePatientStayDateOnCRR from "components/ServiceRequest/PatientStay/usePatientStayDateOnCRR";
import { DisplayReviewOutcome, MDModalProps } from "./mdReviewModalTypes";
import { Row, useModalStyles } from "../mdReviewStyles";
import { ClinicalReviewContext } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { NextReviewDateSelect } from "components/ServiceRequest/ReviewSection/common/NextReviewDateSelect";
import {
  disableSaveButton,
  getRadioButtonProps,
  getReasonDisplayProps,
  getTextFieldProps,
  isPartialApprovalOutcome,
} from "./mdReviewModalUtils";
import PatientStays, { deAggregateStayDates } from "components/ServiceRequest/PatientStay/PatientStays";
import { ExtendedRnReviewOutcome, unableToWorkCase } from "../../util/QueueManagementReviewUtil";
import { useLocation } from "react-router";
import { useSnackbar } from "notistack";
import { useUpdateServiceCase } from "@coherehealth/qm-api";
import UnsolvableCaseModal from "components/DocumentViewer/FaxAttachment/UnworkableFax/UnsolvableCaseModal";
import { useAuthorized } from "authorization";
import { OOSCheckbox } from "./ModalFields/components/OOSCheckbox";
import PartialApprovalFlexibleCodes from "components/ServiceRequestStatusDisplay/PartialApproval/PartialApprovalFlexibleCodes";
import elementIsNonNull from "util/elementIsNonNull";
import { GenericDoctorReview } from "components/ClinicalReview/reviewUtils/useReviews";
import EnhancedWithdrawalReasonForm from "components/ServiceRequestStatusDisplay/EnhancedWithdrawalReasonForm/EnhancedWithdrawalReasonForm";

export const MdModal = ({
  isReadmission,
  serviceRequest,
  review,
  setReview,
  approvedUnits,
  updateSinglePxCodeUnit,
  updateApprovedUnitsByCS,
  approvedUnitsByClinicalService,
  pxCodesByClinicalService,
  newDenialsWorkflow,
  facilityBasedFeatureEnabled,
  currentlySavingDraft,
  loading,
  checkEmptyRequestedLoc,
  initialPatientStayDateRanges,
  approvedPxCodesAreInvalid,
  isDischarged,
  isOpen,
  setIsOpen,
  onDiscard,
  onSubmit,
  discarding,
  sendOptionalDecisionLetters,
  setSendOptionalDecisionLetter,
}: MDModalProps<GenericDoctorReview>) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const caseId = search.get("caseId") || "";

  const {
    id,
    authStatus,
    reconClaim,
    clinicalServices,
    units,
    healthPlanName,
    startDate,
    admissionDate,
    initialDecisionDisposition,
    requestTiming,
    reviewOutcomeWithdrawOptions: withdrawRequestorOption,
  } = serviceRequest;
  const { reviewOutcome, withdrawnReason } = review;
  const { latestChangeRequest, latestReviewLeadingToDenial, isMultiCoverageReviewRequired } =
    useContext(ClinicalReviewContext);
  const multiCoverageReviews = useFeature("multiCoverageReviews");
  const reviewerWithdrawalModalEnhanced = useFeature("reviewerWithdrawalModalEnhanced");

  const multiCoverageContent = multiCoverageReviews && isMultiCoverageReviewRequired;
  const modalLoading = loading || currentlySavingDraft;
  const isPartialApproval = isPartialApprovalOutcome(reviewOutcome);
  const isPostDenialP2PReview =
    review.reviewType === "MdReview" ? isPostDenialP2P(review, authStatus) : review.isPostDenialP2P;
  const isPreService = requestTiming === "PRE_SERVICE";
  const readmissionIdentificationEnabled = useFeature("readmissionIdentification");
  const canViewAllWithdrawnReasons = useAuthorized("VIEW_ALL_WITHDRAWAL_REASONS");
  const canViewEnhancedWithdrawalModal = useAuthorized("VIEW_ENHANCED_WITHDRAWAL_MODAL");
  const [displayCaseOutcome, setDisplayCaseOutcome] = useState<DisplayReviewOutcome>(reviewOutcome);
  const [OOSCheck, setOOSCheck] = useState<boolean>(false);
  const [nextState, setNextState] = useState<AuthStatus>();
  const [unworkableCaseModalOpen, setUnworkableModalOpen] = useState<boolean>(false);
  const [nextReviewDate, setNextReviewDate] = useState<Date | null>(null);
  const [reviewOutcomeSubmenuOptionsAvailable, setReviewOutcomeSubmenuOptionsAvailable] = useState<boolean>(false);
  const {
    patientStayDateRanges,
    setPatientStayDateRanges,
    patientStaysErrorStates,
    isStayDatesValid,
    isPatientStayDatesError,
  } = usePatientStayDateOnCRR({
    version: "DecisionStay",
    serviceStartDate: startDate,
    initialStayDateRanges:
      displayCaseOutcome === "RECOMMENDED_PARTIAL_APPROVAL" ? initialPatientStayDateRanges : undefined,
    checkEmptyRequestedLoc: checkEmptyRequestedLoc,
  });

  const showFlexibleCodes = isPartialApproval && !isPostDenialP2PReview;
  const showPatientStays =
    reviewOutcome === "RECOMMENDED_PARTIAL_APPROVAL" &&
    facilityBasedFeatureEnabled &&
    patientStayDateRanges &&
    setPatientStayDateRanges;
  const showInfoRow =
    ["RECOMMENDED_DENIAL", "DENIED"].includes(reviewOutcome || "") && reconClaim && !isPostDenialP2PReview;
  const showCheckbox = reviewOutcome === "OUT_OF_SCOPE";
  const textFieldProps = getTextFieldProps(review, serviceRequest, isPostDenialP2PReview, newDenialsWorkflow);
  const reasonDropdownProps = getReasonDisplayProps(
    review,
    serviceRequest,
    canViewAllWithdrawnReasons,
    setReview,
    isPostDenialP2PReview,
    facilityBasedFeatureEnabled
  );

  const modalStyles = useModalStyles({ partialApprovalSelected: isPartialApproval });

  const isNextReviewDateEnabled =
    reviewOutcome === "PENDING_MISSING_CLINICAL_INFO" && isPostDenialP2PReview && facilityBasedFeatureEnabled;
  const nextReviewDateMissing = !nextReviewDate && isNextReviewDateEnabled;
  const radioButtonProps = getRadioButtonProps(
    review,
    serviceRequest,
    readmissionIdentificationEnabled,
    isReadmission,
    nextState
  );
  const canSeeReviewerWithdrawalModalEnhanced =
    canViewEnhancedWithdrawalModal && reviewerWithdrawalModalEnhanced && review.reviewOutcome === "WITHDRAWN";

  const { mutate: nextStatus } = useGetNextNegationStatus({
    id: id || "",
  });
  useEffect(() => {
    if (reviewOutcome && reviewOutcome === "WITHDRAWN" && withdrawnReason) {
      nextStatus({ withdrawnReason }).then((result) => {
        setNextState(result);
      });
    }
  }, [nextStatus, reviewOutcome, withdrawnReason]);

  const submitDisabled = disableSaveButton(
    serviceRequest,
    review,
    approvedPxCodesAreInvalid,
    OOSCheck,
    readmissionIdentificationEnabled,
    isReadmission,
    nextState,
    modalLoading,
    newDenialsWorkflow,
    reviewOutcomeSubmenuOptionsAvailable
  );

  const buttonDisabled =
    newDenialsWorkflow &&
    ["RECOMMENDED_DENIAL", "RECOMMENDED_PARTIAL_APPROVAL", "PARTIALLY_APPROVED", "DENIED"].includes(reviewOutcome || "")
      ? submitDisabled || !Boolean(displayCaseOutcome) || isPatientStayDatesError
      : submitDisabled || !Boolean(displayCaseOutcome) || nextReviewDateMissing;

  const { mutate: updateServiceCase, loading: updateServiceCaseLoading } = useUpdateServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
  });
  const updateUnworkableCase = useCallback(async () => {
    updateServiceCase(unableToWorkCase());
  }, [updateServiceCase]);

  const { mutate: updateClinicalReviewChangeRequest, error: clinicalReviewChangeRequestUpdateError } =
    useUpdateClinicalReviewChangeRequest({ clinicalReviewChangeRequestId: latestChangeRequest?.id ?? "" });

  if (clinicalReviewChangeRequestUpdateError) {
    enqueueSnackbar(
      `Error updating the Clinical Review Change Request status: ${clinicalReviewChangeRequestUpdateError.message}`,
      { variant: "error", preventDuplicate: true }
    );
  }
  const handleUpdateTextField = (opt: string) => {
    if (textFieldProps?.fieldName === "authorizationNote") {
      setReview({ authorizationNote: opt });
    }
    if (textFieldProps?.fieldName === "denialLanguage") {
      setReview({ denialLanguage: opt });
    }
    if (textFieldProps?.fieldName === "claimsPaymentNote") {
      setReview({ claimsPaymentNote: opt });
    }
  };

  useEffectDeepEquality(() => {
    if (isReadmission && initialDecisionDisposition === "APPROVED") {
      setReview({ reviewOutcome: "APPROVED" });
      setDisplayCaseOutcome?.("APPROVED");
      return;
    }

    if (serviceRequest.encounterType === "INPATIENT") {
      if (initialPatientStayDateRanges?.length === 0 && isDischarged) {
        setReview({ reviewOutcome: "WITHDRAWN" });
        setDisplayCaseOutcome("WITHDRAWN");
      } else if (initialPatientStayDateRanges?.length === 0 && !isDischarged) {
        setDisplayCaseOutcome(undefined);
        setReview({ reviewOutcome: undefined });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDischarged,
    serviceRequest.encounterType,
    initialPatientStayDateRanges,
    isReadmission,
    initialDecisionDisposition,
  ]);

  useEffect(() => {
    if (multiCoverageContent) {
      //doing this here since we are not setting the field below in the UI
      //instead it is being set before the modal is shown to reviewers
      setDisplayCaseOutcome(reviewOutcome);
    }
  }, [multiCoverageContent, reviewOutcome]);

  const handleSubmit: HTMLAttributes<HTMLFormElement>["onSubmit"] = async (e) => {
    e.preventDefault();

    if (facilityBasedFeatureEnabled && !isPreService && !isStayDatesValid()) {
      e.stopPropagation();
      return;
    }

    if (displayCaseOutcome === "CANNOT_WORK") {
      onDiscard?.();
      updateUnworkableCase();
      setUnworkableModalOpen(true);
    } else {
      const stayDateRanges = patientStayDateRanges?.length > 0 ? patientStayDateRanges : initialPatientStayDateRanges;
      const stayDates = stayDateRanges ? deAggregateStayDates(stayDateRanges) : undefined;
      const updatesToServiceRequest: ServiceRequestUpdatePayload = {
        patientStayDates: stayDates,
      };
      onSubmit(e, updatesToServiceRequest, isPostDenialP2PReview);
    }
    if (latestChangeRequest?.id && latestReviewLeadingToDenial?.id) {
      updateClinicalReviewChangeRequest({
        resolved: true,
      });
    }
  };

  const getModalWidth = () => {
    const reviewOutcomesForLargeModal: ExtendedRnReviewOutcome[] = ["ADMINISTRATIVE_PARTIAL_APPROVAL", "OUT_OF_SCOPE"];
    if (reviewOutcomesForLargeModal.includes(displayCaseOutcome)) {
      return "lg";
    }
    if (canSeeReviewerWithdrawalModalEnhanced) {
      return "lg";
    }
    return undefined;
  };

  const { allowedReviewOutcomes } = useContext(ClinicalReviewContext);
  const reviewOutcomeSubmenu = allowedReviewOutcomes?.find((outcome) => outcome.id === reviewOutcome)?.submenu;
  const isReviewOutcomeSubmenuExist = reviewOutcomeSubmenu && reviewOutcomeSubmenu.length > 0;

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        className={modalStyles.root}
        hideX={modalLoading}
        maxWidth={getModalWidth()}
      >
        <Grid
          container
          spacing={5}
          component="form"
          onSubmit={handleSubmit}
          className={modalStyles.modalContent}
          style={{ maxWidth: reviewOutcome === "WITHDRAWN" ? 872 : undefined }}
        >
          <Row>
            <H2>Select review outcome</H2>
          </Row>

          {!multiCoverageContent && (
            <>
              <ReviewOutcomeDropdown
                serviceRequest={serviceRequest}
                review={review}
                setReview={setReview}
                displayCaseOutcome={displayCaseOutcome}
                setDisplayCaseOutcome={setDisplayCaseOutcome}
                setReviewOutcomeSubmenuOptionsAvailable={setReviewOutcomeSubmenuOptionsAvailable}
              />
              {showFlexibleCodes && (
                <PartialApprovalFlexibleCodes
                  pxCodesByClinicalService={pxCodesByClinicalService}
                  approvedUnitsByClinicalService={approvedUnitsByClinicalService}
                  updateApprovedUnitsByCS={updateApprovedUnitsByCS}
                  clinicalServices={clinicalServices?.filter(elementIsNonNull) || []}
                  serviceRequestUnits={units || 0}
                  approvedServiceRequestUnits={approvedUnits}
                  healthPlanName={healthPlanName || ""}
                  updateSinglePxCodeUnit={updateSinglePxCodeUnit}
                  data-testid={"modal-flexible-codes"}
                />
              )}
            </>
          )}
          {showPatientStays && (
            <Row style={{ paddingInline: theme.spacing(0) }}>
              <PatientStays
                version="DecisionStay"
                minimumFromDate={parseDateFromISOString(startDate)}
                admissionDate={parseDateFromISOString(admissionDate)}
                healthPlanName={healthPlanName || ""}
                patientStayDateRanges={patientStayDateRanges}
                setPatientStayDateRanges={setPatientStayDateRanges}
                patientStaysErrorStates={patientStaysErrorStates}
                isDischarged={isDischarged}
                attemptedSubmit={true}
                data-testid={"review-modal-patient-stays"}
              />
            </Row>
          )}
          {reasonDropdownProps && (
            <Grid
              xs={12}
              style={{
                textAlign: "start",
                padding:
                  reviewOutcome === "WITHDRAWN"
                    ? theme.spacing("0px", "20px", "0px", "20px")
                    : theme.spacing("0px", "20px", "20px", "20px"),
              }}
              item
            >
              {canSeeReviewerWithdrawalModalEnhanced && (
                <EnhancedWithdrawalReasonForm
                  value={withdrawnReason}
                  onChange={(opt: string) => reasonDropdownProps.onChange(opt)}
                  serviceRequest={serviceRequest}
                />
              )}
              {!canSeeReviewerWithdrawalModalEnhanced && !isReviewOutcomeSubmenuExist && (
                <SingleSelectDropdown
                  data-testid="single-select-outcome-reason-hard-coded"
                  fullWidth
                  label={reasonDropdownProps.label}
                  options={reasonDropdownProps.options as DropdownOption[]}
                  value={reasonDropdownProps.value}
                  onChange={(opt: string) => reasonDropdownProps.onChange(opt)}
                  maxMenuHeight={200}
                  menuWidth={470}
                />
              )}
            </Grid>
          )}
          {radioButtonProps && (
            <RadioButtons
              review={review}
              radioButtonProps={radioButtonProps}
              handleChange={(opt) => {
                if (radioButtonProps?.name === "withdrawRequestor") {
                  setReview({ withdrawRequestor: opt });
                }
                if (radioButtonProps?.name === "readmissionReason") {
                  if (opt === "RELATED" || opt === "COMPLICATION") {
                    setReview({
                      readmissionReason: opt,
                    });
                  }
                }
              }}
            />
          )}
          {withdrawnReason && review.reviewOutcome === "WITHDRAWN" && (
            <Row
              style={{
                textAlign: "start",
                paddingTop: withdrawRequestorOption ? theme.spacing(3) : theme.spacing(0),
                color: colorsLight.font.main,
              }}
            >
              <ModalMessage state={nextState} />
            </Row>
          )}
          {textFieldProps && (
            <Grid
              xs={12}
              style={{ textAlign: "start", padding: theme.spacing("0px", "20px", "0px", "20px") }}
              item
              data-testid="md-modal-text-field"
            >
              <TextField
                label={textFieldProps?.label}
                fullWidth
                hiddenLabel
                multiline
                value={textFieldProps?.value}
                onChangeValue={(opt: string) => handleUpdateTextField(opt)}
              />
            </Grid>
          )}
          {showInfoRow && (
            <Row className={modalStyles.infoRow} data-testid={"modal-info-row"}>
              <div className={modalStyles.errorIconContainer}>
                <ErrorIcon className={modalStyles.errorIcon} />
                <Body2 className={modalStyles.errorText}> No denial letter will be sent </Body2>
              </div>
              <Body1 className={modalStyles.errorBody}>
                This authorization is associated with a Records Review for a claim. No letter will be sent to the
                provider or member so no denial language is needed.
              </Body1>
            </Row>
          )}

          {isNextReviewDateEnabled && (
            <Row>
              <NextReviewDateSelect nextReviewDate={nextReviewDate} setNextReviewDate={setNextReviewDate} />
            </Row>
          )}
          {showCheckbox && <OOSCheckbox OOSCheck={OOSCheck} setOOSCheck={setOOSCheck} />}
          {serviceRequest &&
            serviceRequest.isMemberRequestedAuth &&
            isPostDenialP2PReview &&
            review.reviewOutcome === "APPROVED" && (
              <div style={{ paddingLeft: "20px" }}>
                <Checkbox
                  onChange={() => setSendOptionalDecisionLetter?.(!sendOptionalDecisionLetters)}
                  label={
                    <span style={{ fontSize: "15px", color: colorsLight.font.secondary, textAlign: "left" }}>
                      Send decision notification to the provider, in addition to the member who submitted the request
                    </span>
                  }
                ></Checkbox>
              </div>
            )}
          <Row>
            <PrimaryButton
              data-testid="Review modal submission button"
              loading={loading}
              disabled={buttonDisabled}
              disableRipple
              type="submit"
              className={modalStyles.submitButton}
            >
              Finish review
            </PrimaryButton>
          </Row>
        </Grid>
      </Modal>
      <UnsolvableCaseModal
        open={unworkableCaseModalOpen}
        setOpen={setUnworkableModalOpen}
        buttonsLoading={updateServiceCaseLoading || discarding}
      />
    </>
  );
};
