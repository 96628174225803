import { AuthorizationResponse, ReviewType } from "@coherehealth/core-platform-api";
import { Collapse, Divider, Grid, makeStyles } from "@material-ui/core";
import { Body1, H5, InlineButton, parseDateFromISOString, Sanitized } from "@coherehealth/common";
import { useAuthorized } from "authorization";
import { ReviewDetailsCollapse } from "../MDReview/MDReview";
import { useEffect, useState } from "react";
import ReviewHeader from "../ReviewHeader/ReviewHeader";
import { DividerContainer } from "common/ClinicalReviewCard";

interface GenericReviewHistoryProps {
  authorization?: AuthorizationResponse;
}

const GenericReviewHistory = ({ authorization }: GenericReviewHistoryProps) => {
  const canEditOutOfNetworkReview = useAuthorized("EDIT_OUT_OF_NETWORK_REVIEW");

  const [reviews, setReviews] = useState<ReviewType[]>([]);
  const [expandedReviews, setExpandedReviews] = useState<string[]>([]);
  const [showReviews, setShowReviews] = useState(false);

  const styles = useStyles();

  useEffect(() => {
    const allReviewsOnAuth = authorization?.reviews;
    const nonDraftReviewsOnAuth = allReviewsOnAuth?.filter((review) => review.reviewStatus !== "DRAFT");
    const reviewsOnAuthFiltered = nonDraftReviewsOnAuth?.filter(
      (review) => canEditOutOfNetworkReview || review.reviewType !== "OutOfNetworkReview"
    );
    const reviewsSorted = [...(reviewsOnAuthFiltered ?? [])].sort(reviewComparator);

    setReviews(reviewsSorted ?? []);
  }, [authorization, canEditOutOfNetworkReview]);

  useEffect(() => {
    if (reviews.length > 0 && reviews.length <= 5) {
      setShowReviews(true);
    }
  }, [reviews]);

  const toggleExpanded = (reviewId: string) => {
    if (expandedReviews.includes(reviewId)) {
      setExpandedReviews((currentExpanded) => currentExpanded.filter((id) => id !== reviewId));
    } else {
      setExpandedReviews((currentExpanded) => [...currentExpanded, reviewId]);
    }
  };

  if (!reviews.length) {
    return null;
  }

  return (
    <Grid>
      <Grid container className={styles.rootContainer}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={styles.sectionHeaderContainer}
        >
          <H5>{`${reviews.length} previous review${reviews.length > 1 ? "s" : ""}`}</H5>
          <InlineButton onClick={() => setShowReviews(!showReviews)} className={styles.showSectionButton}>
            {showReviews ? "Hide reviews" : "Show reviews"}
          </InlineButton>
        </Grid>
        <Collapse in={showReviews} timeout="auto" className={styles.sectionCollapse}>
          <Grid container direction="column" alignItems="baseline" className={styles.reviewsContainer}>
            {reviews.map((review) => {
              const headerProps = getHeaderProps(review);
              const reviewText = getReviewText(review);
              const expanded = expandedReviews.includes(review.id);

              return (
                <Grid key={review.id} className={styles.review}>
                  <ReviewHeader
                    expanded={expanded}
                    toggleExpanded={() => toggleExpanded(review.id)}
                    buttonPadding="0"
                    {...headerProps}
                  />

                  {reviewText && (
                    <Grid>
                      <ReviewDetailsCollapse
                        in={expanded}
                        timeout={100}
                        unmountOnExit
                        className={styles.reviewCollapse}
                      >
                        <Body1 component="div">
                          <Sanitized
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              textShadow: "0 0 0.5px rgba(0, 0, 0, 0.5)",
                              fontWeight: 400,
                              display: "-webkit-inline-box",
                              WebkitBoxOrient: "vertical",
                              lineClamp: !expanded ? 3 : undefined,
                              WebkitLineClamp: !expanded ? 3 : undefined,
                            }}
                            className={styles.sanitized}
                            __html={reviewText}
                          />
                        </Body1>
                      </ReviewDetailsCollapse>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Collapse>
      </Grid>
      <DividerContainer>
        <Divider />
      </DividerContainer>
    </Grid>
  );
};

const getHeaderProps = (review: ReviewType) => {
  const defaultProps = {
    reviewDateCreated: review.dateCreated,
    reviewLastUpdated: review.lastUpdated,
    reviewStatus: review.reviewStatus,
    reviewType: review.reviewType,
    reviewCreatedByName: review.createdByName,
    reviewCompletedByName: review.completedByName,
    reviewDateCompleted: review.dateCompleted,
  };

  if (
    review.reviewType === "NurseReview" ||
    review.reviewType === "MdReview" ||
    review.reviewType === "PeerToPeerReview"
  ) {
    return { ...defaultProps, coverageLevelDetails: review.coverageLevelDetails };
  }

  return defaultProps;
};

const getReviewText = (review: ReviewType): string | undefined => {
  switch (review.reviewType) {
    case "NurseReview":
      return review.decisionReasoning;
    case "MdReview":
    case "PeerToPeerReview":
      return review.determinationNote;
    case "OutOfNetworkReview":
      return review.oonExceptionReasoning;
  }
};

const reviewComparator = (a: ReviewType, b: ReviewType) => {
  return (
    parseDateFromISOString(b.dateCompleted ?? b.dateCreated).getTime() -
    parseDateFromISOString(a.dateCompleted ?? b.dateCreated).getTime()
  );
};

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    width: "100%",
    margin: "10px 16px",
  },
  sectionHeaderContainer: {
    marginRight: "10px",
  },
  showSectionButton: {
    marginRight: "12px",
    paddingRight: "12px",
  },
  sectionCollapse: {
    marginRight: "10px",
    width: "100%",
  },
  reviewsContainer: {
    paddingRight: "12px",
    marginTop: "10px",
  },
  review: {
    width: "100%",
    marginBottom: "5px",
  },
  reviewCollapse: {
    margin: "0",
  },
  sanitized: {
    "& *": {
      margin: "0px",
    },
    "& br": {
      marginBottom: "19px",
      content: '""',
      display: "block",
    },
  },
}));

export default GenericReviewHistory;
