import React, { FunctionComponent } from "react";
import { FlexGridItem } from "common/SharedServiceRequestFormComponents";
import OutOfNetworkBanner from "components/ServiceRequest/ServiceRequestForm/components/OutOfNetworkBanner";
import { makeStyles } from "@material-ui/core/styles";
import { Body2, colorsLight } from "@coherehealth/common";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CircularProgress } from "@material-ui/core";

interface Props {
  isOrderingProviderOON: boolean;
  oonLoading?: boolean;
  inNetwork?: boolean;
  healthPlanName: string;
  oonExceptionConfig: "REQUIRED" | "OPTIONAL" | "OMIT";
}

const OONOrderingProviderStatusDisplay: FunctionComponent<Props> = ({
  oonLoading,
  inNetwork,
  isOrderingProviderOON,
  healthPlanName,
  oonExceptionConfig,
}) => {
  const classes = customStyles();

  if (!oonLoading) {
    if (isOrderingProviderOON) {
      return (
        <FlexGridItem container>
          <OutOfNetworkBanner
            orderingProviderOON={isOrderingProviderOON}
            performingProviderOON={false}
            performingProviderPracticeOON={false}
            facilityOON={false}
            careParticipantOON={false}
            healthPlanName={healthPlanName}
            oonExceptionConfig={oonExceptionConfig}
          ></OutOfNetworkBanner>
        </FlexGridItem>
      );
    } else if (inNetwork) {
      return (
        <FlexGridItem>
          <div className={classes.oonStatusContainer}>
            <div className={classes.iconContainer}>
              <CheckCircleIcon className={classes.successIcon} />
            </div>
            <div className={classes.verticallyCenteredContainer}>
              <div className={classes.verticallyCenteredItem}>
                <Body2 className={classes.oonStatusText}>Network check complete</Body2>
              </div>
            </div>
          </div>
        </FlexGridItem>
      );
    } else {
      return <></>;
    }
  } else {
    return (
      <FlexGridItem>
        <div className={classes.oonStatusContainer}>
          <div className={classes.runningIconContainer}>
            <CircularProgress size={24} />
          </div>
          <div className={classes.verticallyCenteredContainer}>
            <div className={classes.verticallyCenteredItem}>
              <Body2 className={classes.oonRunningNetworkCheck}>Running network check</Body2>
            </div>
          </div>
        </div>
      </FlexGridItem>
    );
  }
};

export default OONOrderingProviderStatusDisplay;

const customStyles = makeStyles((theme) => ({
  oonStatusContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(0.375),
    marginTop: theme.spacing(-0.25),
  },

  oonStatusText: {
    fontFamily: "Gilroy-SemiBold",
    color: theme.palette.success.dark,
  },

  oonRunningNetworkCheck: {
    fontFamily: "Gilroy-SemiBold",
    color: colorsLight.font.secondary,
  },

  iconContainer: {
    justifyContent: "center",
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  runningIconContainer: {
    marginRight: "8px",
  },
  verticallyCenteredContainer: {
    display: "table",
    height: theme.spacing(3),
    overflow: "hidden",
  },

  verticallyCenteredItem: {
    display: "table-cell",
    verticalAlign: "middle",
  },

  successIcon: {
    color: theme.palette.success.main,
    fontSize: theme.spacing(2.75),
    marginLeft: theme.spacing(-0.125),
  },
}));
