import React from "react";
import Button, { ButtonProps, ButtonTypeMap } from "@material-ui/core/Button";
import MuiCircularProgress from "@material-ui/core/CircularProgress";
import { ExtendButtonBase } from "@material-ui/core/ButtonBase";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, withStyles, Theme } from "@material-ui/core/styles";
import { colorsLight } from "../../themes";

const styles = (theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return {
    root: {
      "&$disabled": {
        color: isDarkTheme ? "#313C4E" : colorsLight.gray.dark,
      },
    },
    disabled: {},
    textPrimary: {
      paddingLeft: 0,
      paddingRight: 0,
      boxShadow: "none",
      backgroundColor: "transparent",
      "&:hover, &:focus": {
        boxShadow: "none",
        backgroundColor: "transparent",
        color: theme.palette.primary.light,
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: "transparent",
        color: theme.palette.primary.dark,
      },
    },
    textSecondary: {
      paddingLeft: 0,
      paddingRight: 0,
      boxShadow: "none",
      backgroundColor: "transparent",
      "&:hover, &:focus": {
        boxShadow: "none",
        backgroundColor: "transparent",
        color: theme.palette.secondary.light,
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: "transparent",
        color: theme.palette.secondary.dark,
      },
    },
    startIcon: {
      // To keep the left-alignment of the icon flush with the container
      marginLeft: 0,
    },
  };
};

export interface ExtraProps {
  loading?: boolean;
  warning?: boolean;
}
const InlineButton = React.forwardRef((props: ButtonProps & ExtraProps, ref: any) => {
  // pull out external props that we hardcode, only pass through otherProps
  const { classes, children, color, variant, loading, warning, ...otherProps } = props;
  return (
    <Button
      ref={ref}
      classes={classes}
      disableRipple
      variant="text"
      color={warning ? "secondary" : "primary"}
      data-public
      {...otherProps}
    >
      {Boolean(loading) && <CircularProgress color="inherit" size={32} />}
      <div style={loading ? { visibility: "hidden" } : {}}>{children}</div>
    </Button>
  );
});
InlineButton.displayName = "InlineButton";

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CircularProgress = styled(MuiCircularProgress)({
  position: "absolute",
});

export default withStyles(styles)(InlineButton) as ExtendButtonBase<ButtonTypeMap<ExtraProps>>;
