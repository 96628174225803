import {
  AuthStatus,
  ReviewOutcomeWithdrawOption,
  ServiceRequestResponse,
  ServiceRequestSearchResponse,
} from "@coherehealth/core-platform-api";
import { ChangeStatusFormState, Row } from "../utils";
import { SingleSelectDropdown, RadioGroup, colorsLight, useFeature } from "@coherehealth/common";
import { ModalMessage } from "components/ClinicalReview/reviewUtils/utils";
import { useAuthorized } from "authorization";
import EnhancedWithdrawalReasonForm from "../EnhancedWithdrawalReasonForm/EnhancedWithdrawalReasonForm";

interface NegationStatusFormProps {
  setFormState: (value: React.SetStateAction<ChangeStatusFormState>) => void;
  formState: ChangeStatusFormState;
  nextNegationStatus?: AuthStatus;
  persistedServiceRequest: ServiceRequestResponse | null;
  selectedServiceRequest: ServiceRequestResponse | ServiceRequestSearchResponse;
  withdrawnReasonSelectOptions: () => ReviewOutcomeWithdrawOption[];
}

export default function NegationStatusForm(props: NegationStatusFormProps): JSX.Element {
  const {
    formState,
    nextNegationStatus,
    persistedServiceRequest,
    selectedServiceRequest,
    setFormState,
    withdrawnReasonSelectOptions,
  } = props;

  const reviewerWithdrawalModalEnhanced = useFeature("reviewerWithdrawalModalEnhanced");
  const canViewEnhancedWithdrawalModal = useAuthorized("VIEW_ENHANCED_WITHDRAWAL_MODAL");

  const onWithdrawnReasonChange = (reason: string) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      withdrawVoid: {
        ...prevFormState.withdrawVoid,
        reason,
      },
    }));
  };

  return reviewerWithdrawalModalEnhanced && canViewEnhancedWithdrawalModal ? (
    <EnhancedWithdrawalReasonForm
      value={formState.withdrawVoid?.reason}
      onChange={onWithdrawnReasonChange}
      serviceRequest={selectedServiceRequest ?? persistedServiceRequest}
    />
  ) : (
    <>
      <div style={{ marginBottom: "8px" }}>
        <SingleSelectDropdown
          fullWidth
          label="Select reason for withdrawal"
          options={withdrawnReasonSelectOptions()}
          value={formState.withdrawVoid?.reason || ""}
          onChange={onWithdrawnReasonChange}
          maxMenuHeight={200}
          data-testid="withdrawal-reason-select-dropdown"
        />
      </div>
      {(selectedServiceRequest.withdrawRequestorOption || persistedServiceRequest?.withdrawRequestorOption) &&
        nextNegationStatus === "WITHDRAWN" && (
          <Row style={{ textAlign: "start", paddingTop: 0 }}>
            <RadioGroup
              label="Select who requested the withdrawal"
              options={[
                { id: "PATIENT", label: "Patient" },
                { id: "PROVIDER", label: "Provider" },
              ]}
              value={formState.withdrawVoid?.requester}
              onChange={(requester) => {
                setFormState((prevFormState) => ({
                  ...prevFormState,
                  withdrawVoid: {
                    ...prevFormState.withdrawVoid,
                    requester,
                  },
                }));
              }}
              data-testid="withdrawal-requestor-radio-group"
            />
          </Row>
        )}
      <Row style={{ textAlign: "start", paddingTop: 0, color: colorsLight.font.main }}>
        <ModalMessage state={nextNegationStatus} />
      </Row>
    </>
  );
}
