import { Dispatch, SetStateAction, useCallback, useContext, useEffect } from "react";
import { Container, Grid, useTheme, makeStyles } from "@material-ui/core";
import { useMuiContainerStyles } from "@coherehealth/common";
import InfoAlert from "common/InfoAlert";
import { AuthStatus, Patient, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import PreApprovedCareReadOnly from "./PreApprovedCareReadOnly";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { useServiceRequestConfigSpec } from "components/ServiceRequest/ConfigurableServiceRequestForm";
import PreApprovedCareProvidersCard from "./PreApprovedCareProvidersCard";
import {
  useFacilityOutOfNetworkCheck,
  useOrderingProviderOutOfNetworkCheck,
  usePerformingProviderOutOfNetworkCheck,
  usePerformingProviderPracticeOutOfNetworkCheck,
} from "../common";
import listReplace from "util/listReplace";
import { ScrubTinsContext } from "components/ScrubTinsContext";
import { AuthBuilderRequestorProps } from "..";
import RequestorCard from "components/Requestor/RequestorCard";

interface Props extends AuthBuilderRequestorProps {
  serviceRequests: ServiceRequestResponse[];
  formContent: ServiceRequestFormContent;
  attemptedSubmit: boolean;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  authStatus: AuthStatus;
  patient?: Patient;
  workflowId?: string;
  delegatedVendorName?: string;
  patientHealthPlanName: string;
  setProviderDetailsFormsCanBeSubmitted: Dispatch<SetStateAction<boolean[]>>;
  setFacilityOonCheckLoading: Dispatch<SetStateAction<boolean>>;
  setProviderOonCheckLoading: Dispatch<SetStateAction<boolean>>;
  setPerformingProviderPracticeOonCheckLoading: Dispatch<SetStateAction<boolean>>;
  setOrderingProviderOonCheckLoading: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  containerStyle: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
}));

export default function PreApprovedCareContainer({
  serviceRequests,
  formContent,
  attemptedSubmit,
  setFormContent,
  authStatus,
  patient,
  workflowId,
  delegatedVendorName,
  patientHealthPlanName,
  setProviderDetailsFormsCanBeSubmitted,
  setFacilityOonCheckLoading,
  setProviderOonCheckLoading,
  setOrderingProviderOonCheckLoading,
  setPerformingProviderPracticeOonCheckLoading,
  requestorFormAuthorized,
  ...requestorProps
}: Props) {
  const { spacing } = useTheme();
  const containerClasses = useMuiContainerStyles();
  const { scrubTinsEnabled: hideTinField } = useContext(ScrubTinsContext);
  const classes = useStyles();

  const { formFieldConfigurations, outOfNetworkCheckConfiguration } = useServiceRequestConfigSpec({
    ...formContent,
    patientId: patient?.id || "",
    healthPlanName: patientHealthPlanName,
  });

  const setCanBeSubmitted = useCallback(
    (canBeSubmitted: boolean) => setProviderDetailsFormsCanBeSubmitted((prev) => listReplace(prev, 0, canBeSubmitted)),
    [setProviderDetailsFormsCanBeSubmitted]
  );

  const { providerOonCheckData, providerOonCheckLoading, performedProviderOONCheck } =
    usePerformingProviderOutOfNetworkCheck({
      formFieldConfigurations,
      outOfNetworkCheckConfiguration,
      content: formContent || {},
      patient,
      workflowId,
      delegatedVendorName,
      setFormContent,
      hideTinField,
    });

  const {
    performingProviderPracticeOonCheckData,
    performingProviderPracticeOonCheckLoading,
    performedProviderPracticeOONCheck,
  } = usePerformingProviderPracticeOutOfNetworkCheck({
    formFieldConfigurations,
    outOfNetworkCheckConfiguration,
    content: formContent || {},
    patient,
    workflowId,
    delegatedVendorName,
    setFormContent,
    hideTinField,
  });

  const { facilityOonCheckData, facilityOonCheckLoading, performedFacilityOONCheck } = useFacilityOutOfNetworkCheck({
    formFieldConfigurations,
    outOfNetworkCheckConfiguration,
    content: formContent || {},
    patient,
    workflowId,
    delegatedVendorName,
    setFormContent,
    hideTinField,
  });

  const { orderingProviderOonCheckData, orderingProviderOonCheckLoading, performedOrderingProviderOONCheck } =
    useOrderingProviderOutOfNetworkCheck({
      formFieldConfigurations,
      outOfNetworkCheckConfiguration,
      content: formContent || {},
      patient,
      workflowId,
      delegatedVendorName,
      setFormContent,
      hideTinField,
    });

  useEffect(() => {
    setFacilityOonCheckLoading(facilityOonCheckLoading);
    setProviderOonCheckLoading(providerOonCheckLoading);
    setOrderingProviderOonCheckLoading(orderingProviderOonCheckLoading);
    setPerformingProviderPracticeOonCheckLoading(performingProviderPracticeOonCheckLoading);
  }, [
    facilityOonCheckLoading,
    providerOonCheckLoading,
    orderingProviderOonCheckLoading,
    setFacilityOonCheckLoading,
    setProviderOonCheckLoading,
    setPerformingProviderPracticeOonCheckLoading,
    setOrderingProviderOonCheckLoading,
    performingProviderPracticeOonCheckLoading,
  ]);

  return (
    <Container classes={containerClasses} maxWidth="lg">
      {requestorFormAuthorized && (
        <Grid style={{ paddingTop: spacing(5) }}>
          <RequestorCard {...requestorProps} />
        </Grid>
      )}
      {serviceRequests?.map((serviceRequest) => (
        <Grid key={serviceRequest.id} className={classes.containerStyle}>
          <InfoAlert message="Please submit your provider and facility details below to obtain an authorization number" />
          <PreApprovedCareProvidersCard
            formContent={formContent}
            setFormContent={setFormContent}
            formConfiguration={formFieldConfigurations}
            authStatus={authStatus}
            patient={patient}
            setCanBeSubmitted={setCanBeSubmitted}
            attemptedSubmit={attemptedSubmit}
            workflowId={workflowId}
            oonCheckLoading={
              providerOonCheckLoading || facilityOonCheckLoading || performingProviderPracticeOonCheckLoading
            }
            providerOonCheckData={providerOonCheckData}
            performingProviderPracticeOonCheckData={performingProviderPracticeOonCheckData}
            facilityOonCheckData={facilityOonCheckData}
            orderingProviderOonCheckData={orderingProviderOonCheckData}
            oonCheckFieldsFilled={
              performedProviderOONCheck ||
              performedFacilityOONCheck ||
              performedOrderingProviderOONCheck ||
              performedProviderPracticeOONCheck
            }
            patientHealthPlanName={patientHealthPlanName}
          />
          <PreApprovedCareReadOnly serviceRequest={serviceRequest} />
        </Grid>
      ))}
    </Container>
  );
}
