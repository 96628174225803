import React from "react";
import { AGADIA_NAME, H6, InlineButton, Tooltip, useFeature } from "@coherehealth/common";
import EditIcon from "@material-ui/icons/Edit";
import { Grid, Divider, useTheme } from "@material-ui/core";
import AttachmentSummary from "../../ServiceRequest/ReadonlyDetail/AttachmentSummary";
import {
  Attachment,
  AuthorizationResponse,
  ServiceRequestResponse,
  useGetAttachmentClassifications,
} from "@coherehealth/core-platform-api";
import { useAuthorized } from "authorization";
import { removeEmptyAttachments, getAttachmentString } from "util/attachmentUtil";
import AttachmentSectionHeader from "./AttachmentSectionHeader";
import { isFinallyDeterminedUtility, isNegationAuthStatus } from "util/serviceRequest";
import { useGetServiceRequestEditConfigurationByPayerAndAuthStatus } from "hooks/useGetFeatureConfigurations";
import useOpsEditDisabled from "hooks/useOpsEditDisabled";

interface Props {
  authorization?: AuthorizationResponse;
  // If this is an Authorization, serviceRequest is the latest Service Request
  serviceRequest: ServiceRequestResponse;
  setViewState: (state: "READ_ONLY" | "EDIT") => void;
  singleAttachmentViewerRedesign?: boolean;
}

export default function AttachmentSectionReadOnly({
  authorization,
  serviceRequest,
  setViewState,
  singleAttachmentViewerRedesign,
}: Props) {
  const srEditsPayerConfigurable = useFeature("srEditsPayerConfigurable");
  const { srEditConfig } = useGetServiceRequestEditConfigurationByPayerAndAuthStatus({
    healthPlanName: serviceRequest?.healthPlanName,
    authStatus: serviceRequest.authStatus,
  });
  const canShowClassificationResults = useAuthorized("VIEW_ATTACHMENT_CLASSIFICATION_SCORE");
  const isSubmittedOscarAuth = serviceRequest?.healthPlanName === "Oscar" && serviceRequest?.authStatus !== "DRAFT";
  // TODO: Clean up this hard coded Oscar-specific value
  const hasEditAttachmentsPermission = useAuthorized("EDIT_ATTACHMENTS");
  const isDecisioned = isFinallyDeterminedUtility(serviceRequest.authStatus);
  const isNegatedStatus = isNegationAuthStatus(serviceRequest.authStatus);
  const isPartAuthWithTerminalStatus =
    isNegatedStatus && authorization?.serviceRequestsOnAuth?.every((sr) => isNegationAuthStatus(sr.authStatus));
  const canAddAfterTerminalStatus = useAuthorized("ADD_ATTACHMENT_AFTER_TERMINAL_STATUS");

  const editCheck = srEditsPayerConfigurable
    ? srEditConfig?.disableAttachmentEdits && !canAddAfterTerminalStatus
    : ((isDecisioned || isNegatedStatus) && !canAddAfterTerminalStatus) || isPartAuthWithTerminalStatus;
  const isDecisionedAttachmentsDisabled = useOpsEditDisabled(serviceRequest) || editCheck;
  const decisionedTooltipText = isDecisionedAttachmentsDisabled
    ? srEditConfig?.disableAttachmentEditsMessage || ""
    : "";
  const theme = useTheme();

  // Gather attachments from all ServiceRequests in the Authorization
  const authorizationAttachments: Attachment[] = [];
  if (authorization && authorization.serviceRequestsOnAuth) {
    authorization.serviceRequestsOnAuth.forEach((sr) => {
      sr.attachments?.forEach((attachment) => {
        authorizationAttachments.push(attachment);
      });
    });
  }
  if (!authorization && serviceRequest) {
    serviceRequest.attachments?.forEach((attachment) => {
      authorizationAttachments.push(attachment);
    });
  }

  const attachmentList = removeEmptyAttachments(authorizationAttachments) || [];

  return (
    <Grid container item xs={12} justifyContent="space-between" spacing={3} style={{ margin: 0 }}>
      <Grid item xs={6} style={{ padding: 0 }}>
        <AttachmentSectionHeader attachmentsList={attachmentList} serviceRequest={serviceRequest} />
      </Grid>
      {hasEditAttachmentsPermission && !isSubmittedOscarAuth && (
        // Hide edit button if user does not have edit role or if oscar auth
        <Grid item style={{ padding: 0 }}>
          <Tooltip title={decisionedTooltipText} placement="top" arrow>
            <span>
              <InlineButton
                startIcon={<EditIcon />}
                onClick={() => setViewState("EDIT")}
                disabled={
                  // disabled will show the edit button in a disabled state, this is dependent on the srEditConfig and authStatus
                  isDecisionedAttachmentsDisabled ||
                  // TODO: clean up this hard-coded Agadia-specific logic (use the payer config instead. See ServiceRequestEditConfiguration for details)
                  (serviceRequest?.delegatedVendor === AGADIA_NAME && serviceRequest?.authStatus !== "DRAFT") ||
                  serviceRequest.isRestricted
                }
              >
                Edit
              </InlineButton>
            </span>
          </Tooltip>
        </Grid>
      )}
      <Grid container item xs={12} spacing={2} style={{ padding: theme.spacing(1, 0), margin: 0 }}>
        <>
          {Boolean(canShowClassificationResults && attachmentList.length > 0) ? (
            <AttachmentsWithClassificationResults
              serviceRequest={serviceRequest}
              attachments={attachmentList}
              singleAttachmentViewerRedesign={singleAttachmentViewerRedesign}
            />
          ) : (
            <AttachmentsWithoutClassificationResults
              serviceRequest={serviceRequest}
              attachments={attachmentList}
              singleAttachmentViewerRedesign={singleAttachmentViewerRedesign}
            />
          )}
        </>
      </Grid>
    </Grid>
  );
}

function AttachmentsWithClassificationResults({
  serviceRequest,
  attachments,
  singleAttachmentViewerRedesign,
}: {
  serviceRequest: ServiceRequestResponse;
  attachments: Attachment[];
  singleAttachmentViewerRedesign?: boolean;
}) {
  // Don't bother warning the user if this doesn't successfully load, it's just advisory
  const { data: classificationResults } = useGetAttachmentClassifications({
    queryParams: { ids: serviceRequest.attachments?.map((a) => a.id) || [] },
    queryParamStringifyOptions: { indices: false },
  });
  const theme = useTheme();
  return (
    <>
      {attachments.length > 0 ? (
        attachments
          .slice()
          .sort((a, b) => new Date(b.dateCreated || "").valueOf() - new Date(a.dateCreated || "").valueOf())
          .map((attachment, idx) => (
            <React.Fragment key={attachment.id}>
              <AttachmentSummary
                attachment={attachment}
                classificationResults={classificationResults || undefined}
                stage="PATIENT_SUMMARY"
                singleAttachmentViewerRedesign={singleAttachmentViewerRedesign}
              />
              {idx < (attachments.length || 0) - 1 && (
                <Grid item xs={12} style={{ padding: theme.spacing(1, 0) }}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
      ) : (
        <Grid item xs={12} style={{ padding: 0 }}>
          <H6 data-public>No {getAttachmentString(serviceRequest)}</H6>
        </Grid>
      )}
    </>
  );
}

function AttachmentsWithoutClassificationResults({
  serviceRequest,
  attachments,
  singleAttachmentViewerRedesign,
}: {
  serviceRequest: ServiceRequestResponse;
  attachments: Attachment[];
  singleAttachmentViewerRedesign?: boolean;
}) {
  const theme = useTheme();
  return (
    <>
      {attachments.length > 0 ? (
        <>
          {attachments.map((attachment, idx) => (
            <React.Fragment key={attachment.id}>
              <AttachmentSummary
                attachment={attachment}
                stage="PATIENT_SUMMARY"
                singleAttachmentViewerRedesign={singleAttachmentViewerRedesign}
              />
              {idx < (attachments.length || 0) - 1 && (
                <Grid item xs={12} style={{ padding: theme.spacing(1, 0) }}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </>
      ) : (
        <Grid item xs={12} style={{ padding: 0 }}>
          <H6 data-public>No {getAttachmentString(serviceRequest)}</H6>
        </Grid>
      )}
    </>
  );
}
