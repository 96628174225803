import { SelectOptionsHook, SelectOptionsHookQueryParams } from "@coherehealth/common";
import { useGetProviderFacilitySpecialties } from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

interface Props extends SelectOptionsHookQueryParams {
  query?: string | undefined;
  additionalQueryParameters?: { healthPlanName?: string | undefined };
}

export const useGetUniqueSpecialties: SelectOptionsHook<string> = ({ query, additionalQueryParameters }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, error } = useGetProviderFacilitySpecialties({
    queryParams: {
      healthPlanName: additionalQueryParameters ? additionalQueryParameters?.healthPlanName : undefined,
    },
  });
  const [filteredSpecialties, setFilteredSpecialties] = useState<string[]>([]);

  // Handle errors
  useEffect(() => {
    if (error) {
      enqueueSnackbar("Error fetching provider's or facility's specialty", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [enqueueSnackbar, error]);

  // Filter specialties based on query
  useEffect(() => {
    if (query) {
      const filteredData = data?.filter((specialty) => specialty.toLowerCase().includes(query.toLowerCase()));
      setFilteredSpecialties(filteredData || []);
    } else {
      setFilteredSpecialties(data || []);
    }
  }, [data, query]);

  return {
    options: filteredSpecialties,
    optionsLoading: loading,
  };
};
