import React, { useState } from "react";
import { TextField, Checkbox } from "@coherehealth/common";
import { FaxNoticeMissingInformation, FaxNoticeOutOfScopeInformation } from "../common";
import { makeStyles } from "@material-ui/core";

interface MissingInfoProps {
  missingInformation: FaxNoticeMissingInformation | undefined;
  setMissingInformation: (info: FaxNoticeMissingInformation) => void;
}

const useStyles = makeStyles({
  checkbox: {
    height: 32,
  },
});

export function MissingInfoForm({ missingInformation, setMissingInformation }: MissingInfoProps) {
  const [showAdditionalNotes, setShowAdditionalNotes] = useState(false);
  const classes = useStyles();

  return (
    <>
      <Checkbox
        className={classes.checkbox}
        label="Patient information missing"
        onChange={(val) => {
          setMissingInformation({ ...missingInformation, patientInfoMissing: val });
        }}
        data-public
      />
      <Checkbox
        className={classes.checkbox}
        label="Practice auth contact missing"
        onChange={(val) => {
          setMissingInformation({ ...missingInformation, practiceAuthContactMissing: val });
        }}
        data-public
      />
      <Checkbox
        className={classes.checkbox}
        label="Diagnosis information missing"
        onChange={(val) => {
          setMissingInformation({ ...missingInformation, diagnosisInfoMissing: val });
        }}
        data-public
      />
      <Checkbox
        className={classes.checkbox}
        label="Service(s) requested information missing"
        onChange={(val) => {
          setMissingInformation({ ...missingInformation, servicesRequestedInfoMissing: val });
        }}
        data-public
      />
      <Checkbox
        className={classes.checkbox}
        label="Ordering physician information missing"
        onChange={(val) => {
          setMissingInformation({ ...missingInformation, orderingPhysicianInfoMissing: val });
        }}
        data-public
      />
      <Checkbox
        className={classes.checkbox}
        label="Facility information missing"
        onChange={(val) => {
          setMissingInformation({ ...missingInformation, facilityInfoMissing: val });
        }}
        data-public
      />
      <Checkbox
        style={{ paddingBottom: 16 }}
        label="Other"
        onChange={(val) => {
          setShowAdditionalNotes(val);
          if (!val) {
            setMissingInformation({ ...missingInformation, additionalNotes: undefined });
          }
        }}
        data-public
      />
      {showAdditionalNotes && (
        <TextField
          multiline
          rows={6}
          fullWidth
          style={{ paddingBottom: 16 }}
          label="add additional notes for provider here"
          onChangeValue={(notes) => {
            //for some reason, pdf-react crashes the app when an embedded field is empty string
            if (notes === "") {
              setMissingInformation({ ...missingInformation, additionalNotes: undefined });
            } else {
              setMissingInformation({ ...missingInformation, additionalNotes: notes });
            }
          }}
        />
      )}
    </>
  );
}

interface OutOfScopeProps {
  outOfScopeInformation: FaxNoticeOutOfScopeInformation | undefined;
  setOutOfScopeInformation: (info: FaxNoticeOutOfScopeInformation) => void;
}

export function OutOfScopeForm({ outOfScopeInformation, setOutOfScopeInformation }: OutOfScopeProps) {
  const [showAdditionalNotes, setShowAdditionalNotes] = useState(false);
  const classes = useStyles();

  return (
    <div style={{ flexDirection: "column", display: "flex" }}>
      <Checkbox
        className={classes.checkbox}
        label="Patient is not eligible"
        onChange={(val) => {
          setOutOfScopeInformation({ ...outOfScopeInformation, ineligiblePatient: val });
        }}
        data-public
      />
      <Checkbox
        className={classes.checkbox}
        label="Provider is not eligible"
        onChange={(val) => {
          setOutOfScopeInformation({ ...outOfScopeInformation, ineligibleProvider: val });
        }}
        data-public
      />
      <Checkbox
        className={classes.checkbox}
        label="Prior Authorization must be submitted by PCP"
        onChange={(val) => {
          setOutOfScopeInformation({ ...outOfScopeInformation, mustBePCPSubmitted: val });
        }}
        data-public
      />
      <Checkbox
        style={{ paddingBottom: 16 }}
        label="Other"
        onChange={(val) => {
          setShowAdditionalNotes(val);
          if (!val) {
            setOutOfScopeInformation({ ...outOfScopeInformation, additionalNotes: undefined });
          }
        }}
        data-public
      />
      {showAdditionalNotes && (
        <TextField
          multiline
          fullWidth
          label="add additional notes for provider here"
          rows={6}
          onChangeValue={(notes) => {
            //for some reason, pdf-react crashes the app when an embedded field is empty string
            if (notes === "") {
              setOutOfScopeInformation({ ...outOfScopeInformation, additionalNotes: undefined });
            } else {
              setOutOfScopeInformation({ ...outOfScopeInformation, additionalNotes: notes });
            }
          }}
          style={{ paddingBottom: 16 }}
        />
      )}
    </div>
  );
}
