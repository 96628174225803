import React, { Dispatch, SetStateAction, useEffect, useState, useContext } from "react";
import { H6, RadioGroup, useFeature } from "@coherehealth/common";
import format from "date-fns/format";
import {
  ServiceRequestSearchRequestBody,
  ServiceRequestCounts,
  useGetHealthPlan,
} from "@coherehealth/core-platform-api";
import { Divider, makeStyles } from "@material-ui/core";
import { RadioOptionsWithExpandedButton } from "components/ServiceRequest/ReviewSection/MDReview/MDReviewModal/ModalFields/components/RadioOptionsWithExpandedButton";
import { UserContext } from "UserContext";

import { useGetUserById } from "@coherehealth/core-platform-api";
import { ssoToHealthPlanDisplayNameMap } from "../../common/CoBrandedLogo";

interface Props {
  radioId: string;
  queryParams: ServiceRequestSearchRequestBody;
  setQueryParams: Dispatch<SetStateAction<ServiceRequestSearchRequestBody>>;
  serviceRequestCounts: ServiceRequestCounts;
  selectedHealthPlan?: string;
  setSelectedHealthPlan?: React.Dispatch<React.SetStateAction<string>>;
  coBrandingPayerLogosFF?: boolean;
}
interface ActiveHealthPlan {
  id: string;
  label: string;
  displayNames?: string[];
}

interface HealthPlanOption {
  id: string;
  label: string;
  parentHealthPlanName?: string;
}

const useStyles = makeStyles((theme) => ({
  radioGroupHealthPlanContainer: {
    marginBottom: theme.spacing(3),
  },
  radioGroupAuthStatusContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const COUNT_MAX = 10000;

const baseStatusOptions: Record<
  string,
  Partial<{
    startDate: string;
    sort: string;
    authStatus: string;
    withdrawnReason: string;
  }>
> = {
  All: {
    sort: "lastUpdated:desc",
  },
  Upcoming: {
    startDate: `ge:${format(new Date(Date.now()), "yyyy-MM-dd")}`,
    sort: "startDate:asc",
  },
  "Pending Review": {
    authStatus: "in:PENDING,INTAKE,RECOMMENDED_DENIAL,PENDING_EXTERNAL_DETERMINATION",
    sort: "startDate:asc",
  },
  Approved: {
    authStatus: "eq:APPROVED",
    sort: "startDate:asc",
  },
  Denied: {
    authStatus: "in:DENIED",
    sort: "startDate:asc",
  },
  "Partially Approved": {
    authStatus: "in:PARTIALLY_APPROVED",
    sort: "startDate:asc",
  },
  Draft: {
    authStatus: "eq:DRAFT",
    sort: "startDate:asc",
  },
  Withdrawn: {
    authStatus: "eq:WITHDRAWN",
    withdrawnReason: "ne:INTEGRATION_FAILURE",
    sort: "startDate:asc",
  },
  Completed: {
    authStatus: "eq:APPROVED",
    startDate: `lt:${format(new Date(Date.now()), "yyyy-MM-dd")}`,
    sort: "startDate:desc",
  },
};

export default function ServiceRequestFilter({
  radioId,
  queryParams,
  setQueryParams,
  serviceRequestCounts,
  selectedHealthPlan,
  setSelectedHealthPlan,
  coBrandingPayerLogosFF,
}: Props) {
  const { data: healthPlans } = useGetHealthPlan({});
  const partialApprovalEnabled = useFeature("partialApproval");
  const [selectedStatus, setSelectedStatus] = useState("All");
  let showAllOptions = false;
  const classes = useStyles();
  const [ssoValue, setSSOValue] = useState("");
  const [activeHealthPlans, setActiveHealthPlans] = useState<ActiveHealthPlan[]>();
  const [healthPlanFilterOptions, setHealthPlanFilterOptions] = useState<HealthPlanOption[]>();
  const [healthPlanSearchOptions, setHealthPlanSearchOptions] =
    useState<Record<string, { healthPlanName: string; healthPlanDisplayName?: string | undefined }>>();
  const { getUser } = useContext(UserContext);
  const { refetch: fetchUserDetails } = useGetUserById({
    id: "",
    lazy: true,
  });

  useEffect(() => {
    const location = window.location;
    const urlParams = new URLSearchParams(location.search);
    const ssoValue = urlParams.get("ssoCode");
    setSSOValue(ssoValue || "");
  }, []);

  useEffect(() => {
    if (coBrandingPayerLogosFF) {
      if (healthPlans) {
        //construct currentActiveHealthPlans as base
        let currentActiveHealthPlans: ActiveHealthPlan[] = [
          { id: "allHealthPlan", label: "All", displayNames: [] }, // includes all health plans
          ...healthPlans
            .filter((plan) => plan.status?.toLowerCase() === "active")
            .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
            .map((plan) => ({
              id: plan.name || "",
              label: plan.name || "",
              displayNames: plan.displayNames,
            })),
        ];
        getUser()
          ?.then((currentUser) => {
            if (
              currentUser?.sub &&
              currentUser.groups &&
              currentUser.groups.filter((x) => x !== "Everyone").length > 0
            ) {
              // Only bother fetching user details if the user belongs to groups; otherwise we won't find one anyway
              fetchUserDetails({ pathParams: { id: currentUser.sub } })
                .then((userWithOrg) => {
                  if (userWithOrg) {
                    if (userWithOrg.organizationHealthPlans) {
                      //filter the currentActiveHealthPlans by the health plans associated with the user's organization so the user only sees relevant filter options
                      currentActiveHealthPlans = currentActiveHealthPlans.filter(
                        (plan) =>
                          userWithOrg?.organizationHealthPlans &&
                          userWithOrg?.organizationHealthPlans.includes(plan?.label || "")
                      );
                      //remove displayNames which are not included in the users org
                      currentActiveHealthPlans.forEach((healthPlan) => {
                        healthPlan.displayNames = healthPlan.displayNames?.filter((displayName) =>
                          userWithOrg?.organizationHealthPlans?.includes(displayName)
                        );
                      });
                    }
                  }
                  setActiveHealthPlans(currentActiveHealthPlans);
                })
                .catch((error) => {
                  console.warn("useGetUserById() error: ", error);
                });
            } else {
              setActiveHealthPlans(currentActiveHealthPlans);
            }
          })
          .catch((error) => {
            console.warn("getUser() error: ", error);
          });
      }
    }
  }, [healthPlans, coBrandingPayerLogosFF, fetchUserDetails, getUser]);

  useEffect(() => {
    if (activeHealthPlans && !healthPlanFilterOptions) {
      // deep copy activeHealthPlans to avoid mutation
      const healthPlansCopy: ActiveHealthPlan[] = JSON.parse(JSON.stringify(activeHealthPlans));
      //create option for each healthPlan.displayName entry
      const currentHealthPlanFilterOptions = healthPlansCopy.reduce(
        (acc: HealthPlanOption[], curr: ActiveHealthPlan) => {
          if (curr.displayNames && curr.displayNames.length > 0) {
            curr.displayNames.forEach((displayName) => {
              //skip entry for BCBS South Carolina since Payer does not want to see that name within UI. BlueCrossBlueShield of South Carolina will be used instead (which will also be within the healthPlan's displayNames)
              if (displayName === "BCBS South Carolina") {
                return;
              }
              const option = { id: displayName, label: displayName, parentHealthPlanName: curr.id };
              acc = [...acc, option];
            });
          }
          return acc;
        },
        Array.of({ id: "allHealthPlan", label: "All", displayNames: [] })
      );

      //check if there is an ssoCode to make sure corresponding healthPlanDisplayName is part of currentHealthPlanFilterOptions
      if (ssoValue) {
        const healthPlanDisplayName = ssoToHealthPlanDisplayNameMap[ssoValue];
        if (healthPlanDisplayName) {
          const ssoResult = currentHealthPlanFilterOptions.find((hp) => hp.label === healthPlanDisplayName);
          //if filter does not have the healthPlanDisplayValue for this ssoCode, then add it
          if (!ssoResult) {
            currentHealthPlanFilterOptions.push({
              id: `${healthPlanDisplayName}`,
              label: healthPlanDisplayName,
            });
          }
          //set selected filter value to ssoCode displayName
          setSelectedHealthPlan?.(healthPlanDisplayName);
        }
      }
      setHealthPlanFilterOptions(currentHealthPlanFilterOptions);
    }
  }, [activeHealthPlans, ssoValue, setSelectedHealthPlan, healthPlanFilterOptions]);

  useEffect(() => {
    if (healthPlanFilterOptions && !healthPlanSearchOptions) {
      const options = healthPlanFilterOptions.reduce((acc, curr) => {
        if (curr.id === "allHealthPlan") {
          acc[curr.id] = { healthPlanName: `in:${healthPlanFilterOptions.map((plan) => plan.label)}` };
        } else {
          acc[curr.id] = {
            healthPlanName: `eq:${curr.parentHealthPlanName ?? curr.id}`,
            healthPlanDisplayName: `eq:${curr.label}`,
          };
        }
        return acc;
      }, {} as Record<string, { healthPlanName: string; healthPlanDisplayName?: string }>);

      //if ssoCode value exists, set search parameter based off values linked to ssoCode
      if (ssoValue) {
        const healthPlanDisplayName = ssoToHealthPlanDisplayNameMap[ssoValue];
        if (healthPlanDisplayName) {
          //grab the parent health plan whose displayNames contain the displayName associated with the ssoCode
          const parentHealthPlan = activeHealthPlans?.find((healthPlan) =>
            healthPlan?.displayNames?.includes(healthPlanDisplayName)
          );
          let queryParam: ServiceRequestSearchRequestBody = {};
          queryParam = {
            ...queryParams,
            healthPlanName: `eq:${parentHealthPlan?.id ?? healthPlanDisplayName}`,
            healthPlanDisplayName: `eq:${healthPlanDisplayName}`,
          };
          //set the query params which power the dashboard search
          setQueryParams(queryParam);
        }
      }
      setHealthPlanSearchOptions(options);
    }
  }, [healthPlanFilterOptions, activeHealthPlans, queryParams, setQueryParams, ssoValue, healthPlanSearchOptions]);

  useEffect(() => {
    const query = sessionStorage?.getItem("query");
    const parsedQuery = query ? JSON.parse(query) : queryParams;
    const queryParamAuthStatus = parsedQuery.authStatus?.substring(3);
    //check if there is a healthPlanDisplayName url parameter
    const queryParamHealthPlanDisplayName = parsedQuery.healthPlanDisplayName?.substring(3);
    //if so then set the selected dashboard filter item to queryParamHealthPlanDisplayName
    if (queryParamHealthPlanDisplayName) {
      setSelectedHealthPlan?.(queryParamHealthPlanDisplayName);
    }
    for (const [key, value] of Object.entries(baseStatusOptions)) {
      if (value.authStatus) {
        const status = value.authStatus.substring(3);
        if (status === queryParamAuthStatus) {
          if (queryParams.startDate) {
            setSelectedStatus("Completed");
          } else {
            setSelectedStatus(key);
          }
          break;
        }
      }
    }
  }, [queryParams, queryParams.startDate, setSelectedHealthPlan]);

  const handleHealthPlanChange = (val: string) => {
    let queryParam: ServiceRequestSearchRequestBody = {};
    if (val === "allHealthPlan") {
      setSelectedHealthPlan?.("allHealthPlan");
      queryParam = {
        ...queryParams,
        healthPlanName: undefined,
        healthPlanDisplayName: undefined,
      };
    } else {
      setSelectedHealthPlan?.(val);
      queryParam = {
        ...queryParams,
        healthPlanName: healthPlanSearchOptions ? healthPlanSearchOptions[val]?.healthPlanName : undefined,
        healthPlanDisplayName: healthPlanSearchOptions
          ? healthPlanSearchOptions[val]?.healthPlanDisplayName
          : undefined,
      };
    }
    setQueryParams(queryParam);
  };

  const handleStatusChange = (val: string) => {
    const offsetOrNull = { offset: 0 };
    const { order, sort, authStatus, startDate, withdrawnReason, ...restOfQueryParams } = queryParams;
    setSelectedStatus(val);
    const qps = {
      ...restOfQueryParams,
      ...baseStatusOptions[val],
      ...offsetOrNull,
    };
    setQueryParams(qps);
    sessionStorage.setItem("query", JSON.stringify(qps));
  };

  const renderSRAuthRadioOption = () => {
    const filteredOptions = Object.keys(baseStatusOptions)
      .filter((label) => partialApprovalEnabled || label !== "Partially Approved")
      .map((label) => {
        const count = optionLabelCount(label, serviceRequestCounts) || 0;
        const maxedCount = Math.min(count, COUNT_MAX);
        const countLabel = `${maxedCount}${maxedCount >= COUNT_MAX ? "+" : ""}`;
        return {
          id: label,
          label: `${label} (${countLabel})`,
        };
      });

    return (
      <RadioGroup
        data-public
        id={radioId}
        options={filteredOptions}
        value={selectedStatus}
        onChange={(val) => handleStatusChange(val)}
      />
    );
  };

  const renderSRAuthRadioOptionWithHealthplan = () => {
    return (
      <>
        <>
          <div className={classes.radioGroupHealthPlanContainer}>
            <RadioOptionsWithExpandedButton
              options={healthPlanFilterOptions ?? []}
              selectedOption={selectedHealthPlan}
              onOptionChange={handleHealthPlanChange}
              heading="Health plan"
              showAllInitially={showAllOptions}
              numberOfItemsToShow={4}
            />
          </div>
        </>
        <Divider />
        <div className={classes.radioGroupAuthStatusContainer}>
          <H6 component="label" htmlFor={radioId} data-public>
            Status
          </H6>
          {renderSRAuthRadioOption()}
        </div>
      </>
    );
  };

  return (
    <div>
      {coBrandingPayerLogosFF && healthPlanFilterOptions?.length && healthPlanFilterOptions.length > 1
        ? renderSRAuthRadioOptionWithHealthplan()
        : renderSRAuthRadioOption()}
    </div>
  );
}

function optionLabelCount(opt: string, serviceRequestCounts: ServiceRequestCounts) {
  switch (opt) {
    case "All":
      return serviceRequestCounts.all;
    case "Upcoming":
      return serviceRequestCounts.upcoming;
    case "Pending Review":
      return serviceRequestCounts.pendingReview;
    case "Approved":
      return serviceRequestCounts.approved;
    case "Denied":
      return serviceRequestCounts.denied;
    case "Partially Approved":
      return serviceRequestCounts.partiallyApproved;
    case "Draft":
      return serviceRequestCounts.draft;
    case "Withdrawn":
      return serviceRequestCounts.withdrawn;
    case "Completed":
      return serviceRequestCounts.completed;
    default:
      return 0;
  }
}
