import { SingleSelectForProviderFacilitySelect } from "@coherehealth/common";
import { useGetUniqueSpecialties } from "./specialtySearch";

interface Props {
  setSelectedValue: (option: string | null) => void;
  selectedValue: string | null;
  emptyLabel?: string;
  label?: string;
  error?: boolean;
  clearable?: boolean;
  markSelectedOptions?: boolean;
  healthPlanName?: string;
}

export default function SpecialtySelect({
  setSelectedValue,
  clearable,
  emptyLabel,
  selectedValue,
  label,
  markSelectedOptions,
  healthPlanName,
}: Props) {
  return (
    <SingleSelectForProviderFacilitySelect<string>
      label={label || "Specialty"}
      emptyLabel={emptyLabel || "Specialty"}
      noOptionsText={"No results found"}
      clearable={clearable}
      markSelectedOptions={markSelectedOptions}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
      useOptions={useGetUniqueSpecialties}
      useOptionsAdditionalParams={{ healthPlanName: healthPlanName }}
      data-testid="provider-group-specialty"
      dataPublic={true}
      isLazyLoaded={false}
    />
  );
}
